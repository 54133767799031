import React from "react";
import { connect } from "react-redux";
import MessageModal from './MessageModal';
import LoginModal from './LoginModal';
import ImageZoomModal from './ImageZoomModal';


import SearchCashBookCashModal from "./SearchCashBookCashModal";

import SearchSaleBookModal from "./SearchSaleBookModal";
import SearchPurchaseBookModal from "./SearchPurchaseBookModal";
import SearchAllFinanceModal from "./SearchAllFinanceModal";
import SearchSingleAccountFinanceModal from "./SearchSingleAccountFinanceModal";

import TermsAndConditionsModal from './TermsAndConditionsModal'
import CookiesPolicyModal from './CookiesPolicyModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'

import SelectMonthModal from "./SelectMonthModal";

import UserDetailModal from "./UserDetailModal";
// import UnauthModal from './UnauthModal'

const modalLookup = {
  MessageModal,
   LoginModal,
   ImageZoomModal,
  
  SearchCashBookCashModal,
  
  SearchSaleBookModal,
  SearchPurchaseBookModal,
  SearchAllFinanceModal,
  SearchSingleAccountFinanceModal,

  CookiesPolicyModal,
  PrivacyPolicyModal,
  TermsAndConditionsModal,
  SelectMonthModal,

  UserDetailModal,
};

const mapState = state => ({
  currentModal: state.modals
});

const ModalManager = ({ currentModal }) => {
  let renderedModal;

  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];

    renderedModal = <ModalComponent {...modalProps} />;
  }
  return <span>{renderedModal}</span>;
};

export default connect(mapState)(ModalManager);
