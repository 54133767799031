import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { fetchUsers, } from "../../actions/authAction";
import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";

import {
  Form,
  Button,
 // Header,
} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import NumberInput from "../form/NumberInput";
import TextArea from "../form/TextArea";
//import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";


import {
 // composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {
  renderMonth,
} from "../functions/Functions";
import { getMonth } from 'date-fns'

//
const validate = combineValidators({
  //bookType:isRequired('Book Type') ,
  entryType:isRequired('Entry Type') ,
  accountName:isRequired('Account Name') ,
  amount:isRequired('Amount') ,
  entryDate:isRequired('Date') ,
});

class OpeningEntryCreate extends Component {
  
  componentDidMount() {
    
        
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

  }
  
  componentWillUnmount(){
    this.props.resetFinance()
  }
  
  onSubmit = async (values) => {

  //************
    try {
     
      const res = await axios.post(`/api/OpeningEntryCreate`, values);

      if(res.data.msg ==='errorAlready'){
        toastr.error('Entry Already Exist')
      this.props.handleCreateModalCloseSubmit()
      this.props.history.push(`/finance/AllAccounts`);
      }

      if(res.data.msg ==='success'){
        toastr.success('Created Successfully')
      this.props.handleCreateModalCloseSubmit()
      this.props.history.push(`/finance/AllAccounts`);

    }
      }catch (error) {
        
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********

}
  backButtonHandler =()=>{
    this.props.history.goBack();
    this.props.resetFinance()
  }

  render() { 
    
    
    const { handleSubmit, pristine, reset, submitting, } = this.props;

    
    const {amount,} =
      this.props.state.form &&
      this.props.state.form.OpeningEntryCreate !== undefined &&
      this.props.state.form.OpeningEntryCreate.values !== undefined &&
      this.props.state.form.OpeningEntryCreate.values;


    

//****************************

    
      return (
        <div className='marginBottom' >
        <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Create Opening Entry For {renderMonth(getMonth(new Date()))}
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card border">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={this.props.handleCreateModalClose}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />
            <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
            <br/>
            
            
            <label >Account Name</label><span className='color-mandatory'> * </span>

            <Field
              name="accountName"
              placeholder="Account Name"
              type="text"
              //options={renderAccountName()}
              //search={true}
              disabled
              //props={{ disabled: true }}
              component={TextInput}
            />
            <label >
              Amount in INR{" "}
              <span>
                (<b>
                  {' '}{writtenNumber(amount, {
                    lang: "enIndian"
                  }).toUpperCase()}
                  {" "}
                </b>)
              </span>
            </label><span className='color-mandatory'> * </span>

            <Field
              type="number"
              component={NumberInput}
              placeholder="Amount in INR"
              name="amount"
                disabled
            />
<label >Narration/Description</label>
            <Field
            disabled
              name="description"
              type="text"
              component={TextArea}
              rows={3}
              placeholder="Narration/Description"
            />

            <label >Date</label><span className='color-mandatory'> * </span>

            <Field
             disabled={true}
              name="entryDate"
              type="text"
              component={DateInput}
              dateFormat="dd-MM-yyyy"
              placeholder="Date"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

              <br />

              <Button
                color="olive"
                type="submit"
               // disabled={pristine || submitting} 
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
      );
    }

    
}

const mapStateToProps = state => {

  return {
    state,
    userA: state.auth.userA,
    usersA: state.auth.usersA,
    finance: state.finances.finance,
    initialValues: state.finances.finance

  };
};

export default connect(
  mapStateToProps,
  {...actions,fetchUsers}
)(
  reduxForm({
    form: "OpeningEntryCreate", // a unique identifier for this form
    //destroyOnUnmount: false
     validate
  })(withRouter(OpeningEntryCreate))
);
