import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import writtenNumber from "written-number";
//import format from "date-fns/format";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import * as actions from "../../actions/financeAction";
import { resetForm ,setMonth} from "../../actions/commonAction";import { fetchMyGroupUsers, } from "../../actions/authAction";
import {renderMonth,capitalizeFirst,renderWhatsappButton,
  renderPreviousMonth,
} from "../functions/Functions";
import { format,getMonth,getYear } from 'date-fns'
import {
  Label,
  Icon,
  //Header,
  Container,
  Segment,
   Button,
   Divider,
   Modal,
} from "semantic-ui-react";
import OpeningEntryCreate from "./OpeningEntryCreate";
import AllFinanceSA from './AllFinanceSA'


class AllAccounts extends Component {
  state = {
    renderType: "Receipt",
    createModalOpen: false,
    search: "",
    header:true,
    searchField:false,
    monthNum:getMonth(new Date()),
    year:getYear(new Date()),
    allFinanceSAModalOpen: false,
   
  };
  componentDidMount() {
    // this.props.setMonth({month : renderMonth(getMonth(new Date()))})
    
    if(this.props.userA!== null && this.props.userA.SQACreated ==='NO'){
      this.props.openPopup('AlertCreateSQAPopup')
       }
    if (!this.props.month){
      this.props.setMonth({month : renderMonth(getMonth(new Date()))})
        }
    if (!this.props.state.auth.usersG.length){
      this.props.fetchMyGroupUsers()
        }
        const month = this.props.month || renderMonth(this.state.monthNum)
            this.props.fetchSummarySA(month);
  }
  // **
  componentWillUnmount(){
    this.props.setMonth({month : renderMonth(getMonth(new Date()))})
  }
//@@@
  handleSelectMonth = () => {
    this.props.openModal("SelectMonthModal");
  };
  //**********************************
 handleCreateModalOpen = (val) =>{
  this.props.setInitialValues(val)
  this.setState({ createModalOpen:true })
 } 
 handleCreateModalClose = () =>{
    this.setState({ createModalOpen:false })
    // this.props.resetFinance()
  }  //
 handleCreateModalCloseSubmit = () =>{
   // this.handleRefresh()
    //this.props.resetFinance()
    this.setState({ createModalOpen:false })
 }
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleAllFinanceSAModalOpen = (accountId,month) => this.setState({ allFinanceSAModalOpen:true, accountId,month});
handleAllFinanceSAModalClose = () => {
  this.setState({ allFinanceSAModalOpen:false, })
};
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

//***********************************
renderPayment = () => {
  
  this.setState({renderType: "Payment",})
};
renderReceipt = () => {
  
  this.setState({renderType: "Receipt",})
};
//**********************

renderButtonGroup =()=>{
  
  return (
    <div className = 'display-start'>
    <span className="icon3-button">
    <Button
    style={{margin:'0px'}}
    disabled={this.state.renderType === 'Payment'? true:false}
       // floated="right"
        color="blue"
        type="button"
        onClick={this.renderPayment}
      >
         Payable
      </Button>
    </span>
    <span className="icon3-button" >
    <Button
    style={{margin:'0px'}}
    disabled={this.state.renderType === 'Receipt'? true:false}
        //floated="left"
        color="orange"
        type="button"
        onClick={this.renderReceipt}
      >
         Receivable
      </Button>
      
    </span>
    <span className="icon3-button" >
    <Button
    style={{margin:'0px'}}
        //floated="left"
        color="black"
        type="button"
        onClick={this.handleSelectMonth}
      >
         Select Month
      </Button>
      
    </span>
                    
  </div>

  )
  
}
//**************************************
  
renderList=(item)=> {
    const { accountName,totalPayment,totalReceipt, _id } = item;
    const BalanceAllTotal = (totalReceipt || 0) - (totalPayment || 0)
  

    const colorRibbon = (BalanceAllTotal > 0) ? 'blue': 'black';
    const payReceive = (BalanceAllTotal > 0) ? 'pay': 'receive';
    
    const {userType,} = this.props.userA !== undefined &&  this.props.userA !== null && this.props.userA
    
    const renderEntryType =() =>{
      if(userType=== 'Individual'){
        return ('Payment')
      }
      if(userType=== 'Shop Owner'){
        return ('Sale')
      }

    }
    const valuesForOpeningEntry = { 
      entryType: renderEntryType(),
     month:renderMonth(getMonth(new Date())),
      description : 'Balance Brought Down from Previous Month',
      accountName : accountName,
      accountId : _id,
      amount : Math.abs(BalanceAllTotal),
      entryDate : new Date( getYear(new Date()),getMonth(new Date()),1 ), 
     
    }
    
    
    //##############
const renderOpeningBalanceEntryCreatorButton = (iniVal)=>{

  if((renderPreviousMonth(getMonth(new Date())) === this.props.month)&&(payReceive === 'receive')   ){
    return(
      <span
      onClick={()=>this.handleCreateModalOpen(iniVal)}
      className="icon-button"
      >
      <img className= 'yimg ' src='/editIcon3.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
    )
  }
}

//#####################################
const renderUserDetailButton = () =>
      (   <span
        className="icon-button"
        onClick={()=>this.props.openModal('UserDetailModal',{id:_id})}
        >
        <img className= 'yimg ' src='/detailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

      );
      // ^^^^^^^^^^
const renderPayableReceivableTotal =()=>{
if( BalanceAllTotal <= 0 ) {
  return(
    <span style={{color:'red'}}><b> Receivable</b></span>
    )
} 
if( BalanceAllTotal > 0 ) {
  return(
    <span style={{color:'blue'}}><b> Payable</b></span>
    )
} 
}
const renderPayableReceivableRibbon =()=>{
  if( BalanceAllTotal <= 0 ) {
    return(
      <span ><b> Receivable</b></span>
      )
  } 
  if( BalanceAllTotal > 0 ) {
    return(
      <span ><b> Payable</b></span>
      )
  } 
  }

  const accountDetail = this.props.usersG !== undefined && this.props.usersG !== null &&  this.props.usersG.filter(val => {

    return val._id === _id.toString();
  })[0];

  
//******************
const valWhatsapp = {
  uwhatsapp:accountDetail!== undefined && accountDetail.uwhatsapp,
  amount:Math.abs(BalanceAllTotal),
  mes1:`Total Amount Receivable as on ${format(new Date(Date.now()), "MMM d yyyy")} is`
}

// *******
    return (
      <div key={_id} className='margin-top'>
        
      {/**/}
      <div className='cashbook-H'>

      <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {accountDetail!== undefined && accountDetail.userType==='Shop Owner'? accountDetail!== undefined && accountDetail.shopName : accountDetail!== undefined && accountDetail.uname}{' '}({accountDetail!== undefined && accountDetail.address}{' '}{accountDetail!== undefined && accountDetail.projectName})
     </div>
     </div>

     


     </div>
     {/**/}
<Segment attached >
      <Label color={colorRibbon} ribbon>
{renderPayableReceivableRibbon()}
      </Label>
      
      
      <div className='disflex-paginator'>
      {renderUserDetailButton()}
      <span
        className="icon-button"
        onClick={()=>this.handleAllFinanceSAModalOpen(_id,this.props.accountMonth)}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
      
      {renderOpeningBalanceEntryCreatorButton(valuesForOpeningEntry)}
     
      {BalanceAllTotal <= 0 && renderWhatsappButton(valWhatsapp)}

        </div>
      <br />


      <Divider fitted />

                        {/*  */}
                  <div >
      { ( 
        <div className='row'>
        <div className='side'>
          <b><span className='color-CornflowerBlue'>OUT</span> (Payment<span className='color-dodgerblue'> | </span>Sale)</b>
        </div>
        <div className='main'>
          <b><Icon className="rupee" ></Icon>{' '}{totalPayment || 0}{' '}(   {capitalizeFirst(writtenNumber(totalPayment|| 0, {
      lang: "enIndian"
    }))}  )
          {" "}</b>
        </div>

        </div>
      )}
      </div>

{/*  */}

      <div >
      { (
        <div className='row'>
        <div className='side'>
          <b><span className='color-CornflowerBlue'>IN</span> (Receipt<span className='color-dodgerblue'> | </span>Purchase)</b>
        </div>
        <div className='main'>
          <b><Icon className="rupee" ></Icon>{' '}{totalReceipt || 0}{' '}(   {capitalizeFirst(writtenNumber(totalReceipt || 0, {
      lang: "enIndian"
    }))}  )
          {" "}</b>
        </div>

        </div>
      )}
      </div>
            {/*    */}
            <div  className="font-18B">
              <span >
              

  <b><span>Total Amount{' '}{renderPayableReceivableTotal()}</span> :  <Icon className="rupee" ></Icon>{' '}{Math.abs(BalanceAllTotal)}
  {' '}<span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAllTotal), {
                  lang: "enIndian"
                }))} )</span></b>

      </span>
              </div>


    </Segment>
    </div>


    );
  }

  //@@@@@@@@
  showSearchField = () => {
    this.setState({header:false,searchField:true,});
  };
  showHeader = () => {
    this.setState({header:true,searchField:false,search: ""});
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  //@@@@@@@

//****************** */
  render() { 
 
    const arrC= this.props.concatArray !==undefined && this.props.concatArray.length >0 && this.props.concatArray
    
  
    
    const array = this.props.usersG !== null && this.props.usersG !==undefined && this.props.usersG.map(val => {
      return {id:val._id,accountName: val.uname}
    });
    
 
    const arrFilter = array!==undefined &&  array.map(item =>{
     
      const arrFil = arrC!==undefined &&  arrC.length >0 ? arrC.filter(ele =>ele._id === item.id) :[]
      
return {...arrFil[0], ...arrFil[1],accountName:item.accountName}
    }
      )

    
//@@@
const { search } = this.state;
const arrF = arrFilter!==undefined &&  arrFilter.filter(item => {
  return item.accountName.toLowerCase().indexOf(search.toLowerCase()) !== -1;
});

//@@@
const filterFun = (ele)=>{
  const payment = ele.totalPayment ||0;
  const receipt = ele.totalReceipt ||0;
  const value = payment - receipt
  
  return value > 0
  }
  //@@@
        
        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const accountNameA = a.accountName.toUpperCase();
          const accountNameB = b.accountName.toUpperCase();
        
          let comparison = 0;
          if (accountNameA > accountNameB) {
            comparison = 1;
          } else if (accountNameA < accountNameB) {
            comparison = -1;
          }
          return comparison;
        }
        const arrReceivable =arrF !==undefined &&   arrF.length >0 ? arrF.filter  (filterFun).sort(compare) :[]
        const arrPayable = arrF !==undefined &&  arrF.length >0 ? arrF.filter(ele =>{return ((ele.totalPayment ||0) - (ele.totalReceipt)||0) < 0}).sort(compare) :[]
        
      const amountReceivable =arrReceivable!==undefined &&  arrReceivable.reduce((acc, val) => acc + (val.totalPayment || 0) - (val.totalReceipt || 0), 0);
      const amountPayable =arrPayable!==undefined &&  arrPayable.reduce((acc, val) => acc + (val.totalReceipt || 0) - (val.totalPayment || 0), 0);

      const renderArray =()=>{
  if(this.state.renderType=== "Payment"){
    return arrPayable

  }
  if(this.state.renderType=== "Receipt"){
    return arrReceivable

  }
}

//%%%
const renderTotalReceivablePayableAmount =()=>{
  if(this.state.renderType=== "Payment"){
    return (
      <div className="font-18B" >
              <span >
  <b><span>Total Amount{' '}<span style={{color:'blue'}}><b> Payable</b></span></span> :  <Icon className="rupee" ></Icon>{' '}{Math.abs(amountPayable)}
  {' '}<span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(amountPayable), {
                  lang: "enIndian"
                }))} )</span></b>

      </span>
              </div>
    ) 

  }
  if(this.state.renderType=== "Receipt"){
    return (
      <div className="font-18B">
              <span >
  <b><span>Total Amount{' '}<span style={{color:'red'}}><b> Receivable</b></span></span> :  <Icon className="rupee" ></Icon>{' '}{Math.abs(amountReceivable)}
  {' '}<span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(amountReceivable), {
                  lang: "enIndian"
                }))} )</span></b>

      </span>
              </div>
    )  

  }
}
     //******************
  const renderHeaderOrSearch =()=>{
    if(this.state.header===true){
      return(
        <React.Fragment>
        <div className='cashbook-H'>

        <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
        <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        All Accounts Finance Summary {this.props.accountMonth}
        </div>
        </div>

        <div
        style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
        onClick={this.showSearchField}
        >
            <span className='icon-button px-05 border1' > <Icon name='search' /></span >
          </div>


        </div>
        <Segment attached >
       <div >
              {this.renderButtonGroup()}<span>{renderTotalReceivablePayableAmount()}</span>
             

            </div>
            </Segment>
        </React.Fragment>
      )
    }
    if(this.state.searchField===true){
      return(
        <React.Fragment>
  <Segment attached>
  <div className='disflex'>
  {/**/}
  <div
  style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
  onClick={this.showHeader}
  >
  <button

  onClick={this.showHeader}

      className='back-btn mr-10'
    >
      <Icon name="arrow left" />

    </button>
    </div>

  {/**/}
  <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >

  <input
    className="input"
    icon='search' placeholder='Enter Key Word'
    type="text"
    name="search"
    value={this.state.search}
    onChange={this.onChange}
  />

  </div>




  </div>

  </Segment>
        </React.Fragment>
      )
    }
  }
      //******************
 

    
    return (
      <div>
                {/* OpeningEntryCreate Modal*/}

                <Modal
        open={this.state.createModalOpen}
        onClose={this.handleCreateModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        // size='small'
        >

        <Modal.Content>
        <OpeningEntryCreate
        handleCreateModalCloseSubmit={this.handleCreateModalCloseSubmit}
        handleCreateModalClose= {this.handleCreateModalClose}
        />

        </Modal.Content>

        </Modal>
{/* AllFinanceSA Modal*/}
<Modal
        open={this.state.allFinanceSAModalOpen}
        onClose={this.handleAllFinanceSAModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        style={{margin:'0px',padding:'0px'}}
        >

        <Modal.Content>
        <div className="mb-10"
style={{color:'white'}}
>
     
      <button

onClick={this.handleAllFinanceSAModalClose}

className='back-btn color-white'
>
<Icon name="arrow left" />

</button>
    </div>
        <AllFinanceSA 
        accountId = {this.state.accountId}
        month = {this.state.month} 
        handleAllFinanceSAModalClose = {this.handleAllFinanceSAModalClose}
       
        />

        </Modal.Content>
        </Modal>

         {/* AllFinanceSA Modal end*/}
        {/*  */}
      <Container text >
             <div className='margin-auto' >
               <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

               {/*  */}
            


            </div>
            </div>
            </Container>
      <Container text >
      
        {/**/}
        
            {renderHeaderOrSearch()}
        
        {renderArray().map(this.renderList)}
        
      </Container>
      <br/> <br/>
      </div>
    );
  }
  
  }



const mapStateToProps = (state) => {

  return {
    state,
    finance: state.finances.finance,
    accountMonth:state.finances.accountMonth, 
    month: (state.commons.month!== null && state.commons.month) ,
    concatArray: state.finances.concatArray || [],
    usersG: state.auth.usersG,
    userA: state.auth.userA,
  };
};

export default connect(
  mapStateToProps,
  {...actions,fetchMyGroupUsers,openModal,openPopup, resetForm, setMonth}
)(AllAccounts);
