import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';

import { closeModal } from "../../actions/modalAction";
import {
  Button,
  
} from 'semantic-ui-react';

class Message extends Component {
  

 handleOnClick=()=>{
 
  //this.props.history.goBack()  not working
  this.props.closeModal()
 }
  render() {
  
    return  (
      
          <div style={{maxWidth:'700px',margin:'auto'}}>

          <div className='masthead format-message'> 
          {this.props.message}
          </div>
          <Button
              color="teal"

              type="button"
              onClick={this.handleOnClick}
              
            >
              Ok, got it
            </Button>
      </div>
     
    );
  }
}




const mapStateToProps = state => {
 
  return {
    message: state.commons.message,
  };
};
export default connect(
  mapStateToProps,
  { closeModal }
)(withRouter  (Message));
