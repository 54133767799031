import React, { Component } from "react";
import { connect } from "react-redux";
import { Container  } from "semantic-ui-react";
import { Switch, Route,Link  } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

//import PurchaseBookCreate from "./PurchaseBookCreate";
import PurchaseBookEdit from "./PurchaseBookEdit";

import PurchaseBook from "./PurchaseBook";

import Layout from "../layout/Layout";


class PurchaseBookDashboard extends Component {
  state = {
    openop: false,
  };
  componentDidMount() {
   
    window.scroll(0,0)
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenop = () => {this.setState({ openop: true }); window.scroll(0,0)}
    handleCloseop = () => this.setState({ openop: false })
    //********************** */
  render() {
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



          <Layout onLogoutClick={this.onLogoutClick}>
          <div className='nodisplay' >
          <Container >
            <div className='margin-auto' >
              <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

              {/*  */}
           <div className='display-center'>
           {/* <div className="btnnav" >
           <Link to="/purchase/PurchaseBookCreate" >
            Create
          </Link>
           </div> */}
           <div className="btnnav" >
           <Link to="/purchase/PurchaseBook" >
           PurchaseBook
          </Link>
           </div>




         </div>
           {/*  */}


           </div>
           </div>
           </Container>
           </div>
           {/*         */}
           <div className='nodisplay-tab' >
           <Container text >
             <div className='margin-auto' >
               <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

               {/*  */}
            <div className='display-center'>
            {/* <div className="btnnav" >
            <Link to="/purchase/PurchaseBookCreate" >
             Create
           </Link>
            </div> */}
            <div className="btnnav" >
            <Link to="/purchase/PurchaseBook" >
            PurchaseBook
           </Link>
            </div>





          </div>
            {/*  */}


            </div>
            </div>
            </Container>
            </div>
            {/*         */}

          <Switch>
          {/* <Route
            exact
            path="/purchase/PurchaseBookCreate"
            component={PurchaseBookCreate}
          /> */}

          <Route
            exact
            path="/purchase/PurchaseBookEdit/:id"
            component={PurchaseBookEdit}
          />
          <Route exact path="/purchase/PurchaseBook" component={PurchaseBook} />

          </Switch>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div className="mt-10">
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateTorops = state => ({
auth: state.auth
});


export default connect(
  mapStateTorops,
{showBackButton, getUserForAuth, logoutUser }
)(PurchaseBookDashboard);
