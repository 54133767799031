import {
  createValidator,
} from 'revalidate';

export const isValidEmail = createValidator(
  message => value => {
    if (value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      return message
    }
  },
  'Invalid email address'
)
//check phone must be of 10 digit and not start with 0
export const isValidPhone = createValidator(
  message => value => {
    if (value && !/\(?\b[1-9]{1}\)?[0-9]{9}\b/.test(value)) {
      return message
    }
  },
  'Invalid Phone Number'
)
export const isValidPh = createValidator(
  message => value => {
    if (value && !/(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})/.test(value)) {
      return message
    }
  },
  'Invalid Phone Number'
)

export const isValidPassword = createValidator(
  message => value => {
    if (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/.test(value)) {
      return message
    }
  },
  'Invalid Password'
)