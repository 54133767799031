
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { getUserForAuth,clearStorage,showSidebar,hideSidebar,fetchMyGroupUsers } from "../../actions/authAction";
import { logoutUser} from "../functions/Functions";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";

import {
  //Image, 
  Dropdown ,
  Button,
  Icon,
  } from "semantic-ui-react";



class NavbarAuth extends Component {
  state = {
    modalOpen: false,
  };
  componentDidMount() {
    if (!this.props.auth.userA){
      this.props.getUserForAuth();
    }
    if (this.props.auth.usersG.length <1){
      this.props.fetchMyGroupUsers()
        }
    
  }
  
  //******************
handleOpen = () => this.setState({ modalOpen: true })
handleClose = () => this.setState({ modalOpen: false })
//*********************** */
  onLogoutClick = () => {
    this.props.history.push("/");
    this.props.logoutUser();
    
  };
  //*******************************************
  handleSidebarToggle=()=>{
  this.props.showSidebar();
  window.scroll(0,0)
  }

  //*************** */
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  
 // *****************************************

  render() {
   
    //const { user } = this.props.auth;
    const {uname,userId} = this.props.userA !== undefined && this.props.userA !== null && this.props.userA
        //***************** */
        const renderCreateButton =()=>{
          if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Individual'){
             return (
              <Button
              circular
              icon='plus'
              as={Link}
      to={`/finance/CashBookCreate`}
              //onClick={to="/cashBook/CashBookCreate"}
              style={{backgroundColor: 'aqua',marginRight:'15px'}}
            />
      
            )
             }
             if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
              return (
               <Button
               circular
               icon='plus'
               as={Link}
       to={`/finance/SaleBookCreate`}
               //onClick={to="/cashBook/CashBookCreate"}
               style={{backgroundColor: 'aqua',marginRight:'15px'}}
             />
       
             )
              }
          
        }
      
    

    //  const renderRefreshButton =()=>{
    //    return  (
    //      <Button
    //      circular
    //      icon='refresh'
    //      onClick={() => window.location.reload(false)}
    //      style={{backgroundColor: 'dodgerblue', color:'white'}}
    //    />
    //    ) }
   //**************** */
        const renderBackButton =()=>{
        return this.props.visibleBB=== true ? (
        <button

        onClick={this.backButtonHandler}

        className='back-btn'
        >
        <Icon name="arrow left" />

        </button>
        ) : (
        <div></div>
        )}
      ///$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ Activity/Profile Popup

// const renderLoginLogoutButton=()=>{
//   return isAuthenticated ? (

//       <Button onClick={this.onLogoutClick}
//       color='red'
//       size="mini"
//       ><Icon name="power" />Sign Out</Button>
//     ) : (
//       <Button onClick={this.handleOpenL}
//       size="mini"
//       color='teal'
//       ><Icon name="power" />Sign In</Button>
//     )}

            const renderShowHideSidebarButton =()=>{
            return this.props.visible=== true ? (
            <button className='btn'  onClick={this.props.hideSidebar}

            ><Icon name="close" /></button>
            ) : (
            <button  className='btn' onClick={this.handleSidebarToggle }

            ><Icon name="sidebar" /></button>
            )}
         
          //########################################

          const authLinks = (
            <div>
              
      
      
      <div>{/* nav mobile start*/}
      <nav id="navbar-top" className='border-bottom-nav' style={{backgroundColor:'white',flexDirection:'row',height:'55px'}}>
      
      
      
      <div style={{display:'flex'}}>
      
      <div style={{display:'flex',alignItems:'center'}}><img className= ' ' src='/logo_ekhata_2.png' alt=" "  style={{width:"75px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      {/* <Image size='mini' src='/logo_pp2p.png' style={{ marginRight: '.5em' }} /> */}
      
      </div>
      
      {/* <div>FindRealEstate</div> */}
      
      </div>
      
      
      <ul className="mt-10">
      
      <li>{renderBackButton()}</li>
      <li>{renderShowHideSidebarButton()}</li>
      <li>{renderCreateButton()}</li>
      {/* <li>{renderRefreshButton()}</li> */}
      <li>
         <Link to={'/'} >
      
      <div style={{fontSize:'2.0rem',fontWeight:'bold',marginRight:'10px',padding:'5px'}}><Icon name='home' /></div>
      </Link>
      
      </li>
      
      
      <li style={{fontSize:'1.5rem',fontWeight:'bold',marginRight:'10px',padding:'5px',color:'blue'}}>
      
      <Dropdown
      pointing="top right"
      //text={user.name}
      icon='user outline'
      >
        <Dropdown.Menu>
        <Dropdown.Item
          style={{fontWeight:'bold',cursor:'auto'}}
        ><div className="font-18B color-blue">{uname}{' '}</div>
        <div className="font-18B color-teal mt-10">User Id:{' '}{userId}</div>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={this.onLogoutClick}
        > 
        <span className="font-18B color-red">
          <Icon name="power"/>
           Sign Out
           </span>
          </Dropdown.Item>

          <Dropdown.Item
            as={Link}
            to="/auth/passchange"
          >
          <span className="font-18B color-dodgerblue">
          Change Password
           </span>
          </Dropdown.Item>

          {( this.props.userA !== null && this.props.userA.SQACreated === 'NO'  ) 
    &&(<Dropdown.Item
      as={Link}
      to="/auth/SecurityQACreate"
      >
      <span className="font-18B color-teal">
      Create Security Q & A
       </span>
      </Dropdown.Item>
    
    )}
    {( this.props.userA !== null && this.props.userA.SQACreated === 'YES'  )
       &&(<Dropdown.Item
      as={Link}
      to="/auth/ViewSecurityQA"
      >
      <span className="font-18B color-teal">
      View Security Q & A
       </span>
      </Dropdown.Item>
    
    )}
        </Dropdown.Menu>
      
      </Dropdown>
      
      {/*<button className='btn' style={{fontSize:'1.2rem',fontWeight:'bold'}}  onClick={this.handleOpenPop}
      
      ><Icon name="user outline" /></button>
      
       <div style={{fontSize:'1.2rem',fontWeight:'bold'}}><Icon name='user outline' />Profile|Activity</div>
      */}
      
      
      </li>
      
      
      
      </ul>
      
            </nav>
      
      </div>{/* nav mobile end*/}
            </div>
          );
    


    return (
      <div
      // Disable right click
  onContextMenu={e => e.preventDefault()}
      >
        {authLinks }
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => { 
   //console.log('nav_state',state)
  return({
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
})
};

export default connect(
  mapStateToProps,
  { logoutUser,getUserForAuth,clearStorage, openModal,openPopup,
    showSidebar,hideSidebar,fetchMyGroupUsers  }
)(withRouter(NavbarAuth));
