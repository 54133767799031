import React from 'react';
import { Link} from 'react-router-dom';
const LinkItem = (props) => {

  return (
    <div className='nodisplay-at-tab mt-10'>
<div className="border-outline background-link1 buttonLink minwidth-300">

<Link to={props.url} className="" > 
<span className="">

<span className="color-whitesmoke">{props.title}</span>
</span>
{/* <svg className="gradient-icon" viewBox="auto" height="50" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="url(#link)"></path>

<defs>
<linearGradient id="link">
<stop offset="5%" stopColor="#38A2D7" />
<stop offset="95%" stopColor="#561139" />
</linearGradient>

</defs>
</svg> */}

</Link>

</div>
    </div>
  );
};

export default LinkItem;
