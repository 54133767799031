import React, { Component } from "react";
import { connect } from "react-redux";

import { Switch, Route, } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import AllAccounts from "./AllAccounts";
import CashBookCashCreate from "./CashBookCashCreate";
import SaleBookCreate from "./SaleBookCreate";
import Layout from "../layout/Layout";


class FinanceDashboard extends Component {
  state = {
    openop: false,
  };
  componentDidMount() {
    window.scroll(0,0)
    
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenop = () => {this.setState({ openop: true }); window.scroll(0,0)}
    handleCloseop = () => this.setState({ openop: false })
    //********************** */  
  render() {
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



          <Layout onLogoutClick={this.onLogoutClick}>

          <Switch>
          <Route
            exact
            path="/finance/CashBookCreate"
            component={CashBookCashCreate}
          />
            <Route
            exact
            path="/finance/SaleBookCreate"
            component={SaleBookCreate}
          />

            <Route
            exact
            path="/finance/AllAccounts"
            component={AllAccounts}
          />

          </Switch>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div className="mt-10">
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateTorops = state => ({
auth: state.auth
});


export default connect(
  mapStateTorops,
{showBackButton, getUserForAuth, logoutUser }
)(FinanceDashboard);
