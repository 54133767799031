import React from 'react';
//import axios from 'axios';
import {
  Image,
  // Segment,
  // Header,
  // Confirm,
  // Button,
  Icon
} from 'semantic-ui-react';
//import { toastr } from "react-redux-toastr";


class ViewImageContraOK extends React.Component {
  state = {
      rotation: 0,
      dimensions: {},
  }; 
//*********************

rotate=()=>{
  let newRotation = this.state.rotation + 90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState({
    rotation: newRotation,
  })
}

rotateleft=()=>{
  let newRotation = this.state.rotation - 90;
  if(newRotation <= -360){
    newRotation = 360;
  }
  this.setState({
    rotation: newRotation,
  })
}


//**********************
onImgLoad=({target:img})=> {
        this.setState({dimensions:{height:img.offsetHeight,
                                   width:img.offsetWidth}});
    }
//**********************
        renderList=(item)=> {
          const {width, height} = this.state.dimensions;
          const margin = (width-height)*.5
          const margin_top = ((this.state.rotation===-90)||(this.state.rotation===90)||(this.state.rotation===-270)||(this.state.rotation===270))? {marginTop:`${margin}px`,marginBottom:`${margin}px`,}:{}
          
              return (
                <div key={item}>
            {/**/}
            <div className='disflex-paginator' style={margin_top}>

            <br/>

            <Image
            onLoad={this.onImgLoad}
            cache='reload'
            alt="image_"
            style={{transform: `rotate(${this.state.rotation}deg)`}}
            src={`${item}`}
            />

            </div>

            {/**/}
            <div className='disflex-paginator'>
            
            <span className='icon-button border1'>{`${item.substr(59, 7)}`}</span>
            
            <span className='icon-button border1'
            onClick={this.props.handleAddViewImageModalClose}
            > <Icon name='close' />
            </span >


            <div className='nodisplay'>
            <span
            onClick={this.rotateleft}
            className="icon-button"
            >
            <img className= 'yimg ' src='/rotateLeftIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
            <span
            onClick={this.rotate}
            className="icon-button"
            >
            <img className= 'yimg ' src='/rotateRightIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>

            </div>

            </div>
            <br />



            {/**/}
                </div>
                )
              }

//**************
  render() {
//const { rotation } =  this.state;
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >

<div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
All Images
</div>
</div>

<div
style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.props.handleAddViewImageModalClose} >
<span className='icon-button border1' >Close</span >
</div>


</div>

{/**/}


  <br/>


            {this.props.imagePathContra.sort().map(this.renderList)}



{/**/}
              <br />


</div>
      </div>
    );
  }
}

export default ViewImageContraOK;
