import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import {fetchMyDetail} from "../../actions/authAction";
//import { city, state, typeOfProp, typeOfDealings } from "./Data";
import format from "date-fns/format";


import {
  Container,
  //Image,
  Segment,
  Divider,
  Button,

} from "semantic-ui-react";





class MyDetail extends Component {
  componentDidMount() {
    this.props.fetchMyDetail();
  }

  render() {



    if (!this.props.userMy) {
      return (
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
    const {
      uname,
      uimage,
      uemail,
      uphone1,
      uphone2,
      uwhatsapp,
      shopName,
      address,
      projectName,
      locality,
      city,
      state,
      pincode,
      userSubscriptionStartOn,
      userSubscriptionExpireOn,

      _id
    } = this.props.userMy;

    const renderButton = () =>
       (
          <Button.Group  floated="right">



          <span
          className="icon-button"
          >
          <Link to={`/user/addimage`} > 
          <img className= 'yimg ' src='/imageAddIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </Link>

          </span>

            <span
            className="icon-button"
            >
            <Link to={`/user/UserEdit/${_id}`} >
            <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </Link>

            </span>


          </Button.Group>
      ) 
    return (
      <Container text>
      {/**/}
      <div className='margin-auto-H' >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {/*  */}
        {uname}
     {/*  */}
     </div>
     </div>
     {/**/}

        <Segment attached>
        <div>
          <div>
            {renderButton()}
          </div>
          <br />
{/*  */}
<div className="disflex-paginator">
          <img 
              className="border-radius-10"
              //onClick={() => this.handleOpenModal(uimage)}
              src={`${uimage}`} alt="profile image" 
              style={{width:"100px", height:"100px",}} />
          
          </div>
{/* */}

          <br />
          <Divider fitted />
          <div>
          <div >
          {address && (
            <div className='row'>
              <div className='side'>
              Address
              </div>
              <div className='main'>
               <div style={{color:'blue'}}><b>{shopName}</b></div>
               <b>{address}{' '}{projectName}{' '} {locality} {' '}{city}{' '}{state}{' '}{pincode}</b>
              </div>

            </div>
          )}
        </div>

          
        <div >
        {uphone1 && (
        <div className='row'>
          <div className='side'>
          <b>Phone1</b>
          </div>
          <div className='main'>
          <b>{uphone1}</b>
          
          </div>

        </div>
        )}
        </div>

        <div >
        {uphone2 && (
        <div className='row'>
          <div className='side'>
          <b>Phone2</b>
          </div>
          <div className='main'>
          <b>{uphone2}</b>
          
          </div>

        </div>
        )}
        </div>

        <div >
        {uemail && (
          <div className='row'>
            <div className='side'>
              <b>Email</b>
            </div>
            <div className='main'>
              <b>{uemail}</b>
            </div>

          </div>
        )}
      </div>
      <div >
      {uwhatsapp && (
        <div className='row'>
          <div className='side'>
            <b>Whatsapp Id</b>
          </div>
          <div className='main'>
            <b>{uwhatsapp}</b>
          </div>

        </div>
      )}
    </div>



         <div >
                  {userSubscriptionStartOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Start On</b>
                  </div>
                  <div className='main'>
                  <b>{format(new Date(userSubscriptionStartOn), "MMM d yyyy")}</b>{' '}

                  </div>

                  </div>
                  )}
                  </div>

                  <div >
                  {userSubscriptionExpireOn && (
                  <div className='row'>
                  <div className='side'>
                  <b>Subscription Expire On</b>
                  </div>
                  <div className='main'>
                  <b>{format(new Date(userSubscriptionExpireOn), "MMM d yyyy")}</b>{' '}

                  </div>

                  </div>
                  )}
                  </div>




          </div>

          </div>
        </Segment>
      </Container>
    );
  }
}

function mapStateToProps(state) {
 
  return { userMy: state.auth.userMy,userA: state.auth.userA };
}

export default connect(
  mapStateToProps,
  {fetchMyDetail}
)(withRouter(MyDetail));
