import React from 'react';
import writtenNumber from "written-number";
import { startOfToday,endOfToday,formatDuration,intervalToDuration, } from 'date-fns'
import { getMonth, } from 'date-fns'
import { setCurrentUser } from "../../actions/authAction";
import jwt_decode from "jwt-decode";
import setAuthToken from "../../utils/setAuthToken";
import store from "../../store";
import { createBrowserHistory } from "history";
import { FETCH_USER,} from "../../actions/types";
import { toastr } from "react-redux-toastr";
const history = createBrowserHistory()
//****************** ALSO WORKING
// export const days = value => {
//   let duration = intervalToDuration({
//       start: new Date(value),
//       end: new Date(),
//   })
//   const units = ['years', 'months', 'weeks', 'days']
//   const nonzero = Object.entries(duration).filter(([ value]) => value || 0 > 0).map(([unit]) => unit)

// return formatDuration(duration, {
//   format: units.filter(i => new Set(nonzero).has(i)).slice(0, 3),
//   delimiter: ', '
// })

// };
//@@@ BUT BETTER
export const days = value => {
  let duration = intervalToDuration({
      start: new Date(value),
      end: new Date(),
  })
  
return formatDuration(duration,{ format: ['years', 'months', 'weeks', 'days', 'hours', 'minutes'] , delimiter: ', '})

};
//@@@
//^^^^^^^^^^^^^^^
export const period =(value)=>{

  if( ( Date.parse(startOfToday()) < Date.parse(value) ) && ( Date.parse(endOfToday()) > Date.parse(value) ) )
  {
    return(
      <span>Today</span>
    )
  }else if((Date.parse(startOfToday()) < Date.parse(value))){

      if(days(value)){
        return(
          <span>in {days(value)}</span>
        )
      }else{
        return(
          <span>Tommorrow</span>
        )
      }

    }else if((Date.parse(startOfToday()) > Date.parse(value))) {
      if(days(value)){
        return(
            <span>{days(value)} ago</span>
        )
      }else{
        return(
          <span>Yesterday</span>
        )
      }
    }

}
//*************
//&&&
// export const renderSearchFunction =(itemDur,obj)=>{
//   const {start,end,borrowedItemGroup, limit, offset,values} = obj
//   if(itemDur==="All"){
//     
//   this.props.searchBorrowedItems(values, limit, offset);
//   }else{
//     
//     this.props.fetchTodayBorrowedItems(start,end,borrowedItemGroup, limit, offset);
//   }
// }
//@@@  
export const renderStartEnd = (itemDuration)=>{
  const dateS   = startOfToday();
  if(itemDuration==="All"){
    return {start:{}, end:{}}
  }
  if(itemDuration==="Next Seven day's"){
    const start   = dateS;
    const end   =new Date().setDate(endOfToday().getDate()+7);
   return {start, end}
  }
  if(itemDuration==="Tommorrow's"){
    const start = (Date.parse(dateS)+86400000);
    const end   =(Date.parse(dateS)+86400000*2);
    return {start, end}
  }
  if(itemDuration==="Yesterday's"){
    const start = (Date.parse(dateS)-86400000);
    const end   =(Date.parse(dateS)-5000);
    return {start, end}
  }
  if(itemDuration==="Today's"){

    const start   = dateS;
    const end   = endOfToday();
    return {start, end}
  }


}
//@@@
export const color =(value)=>{
  if((Date.parse(startOfToday()) > Date.parse(value))){
    return 'red'
  }
  if( ( Date.parse(startOfToday()) < Date.parse(value) ) && ( Date.parse(endOfToday()) > Date.parse(value) ) )
  {
    return 'orange'
  }
  if((Date.parse(startOfToday()) < Date.parse(value))){
    return 'yellow'
  }

  }


  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^

  export const colorAlert =(value)=>{
  if(value=== 'Critical'){
    return 'red'
  }
  if(value=== 'High'){
    return 'orange'
  }
  if(value=== 'Normal'){
    return 'yellow'
  }
  if(value=== 'Low'){
    return 'green'
  }
}
//%%%%
export const capitalizeFirst = (input) => {
  
  if(input){
  var words = input.split(' ');
  var CapitalizedWords = [];
  words.length>0 && words.forEach(element => {
    CapitalizedWords.push(element[0].toUpperCase() + element.slice(1, element.length));
  });
  return CapitalizedWords.join(' ');
}
}

  
export const logoutUser = () => async dispatch => {
  try {      
  // Remove token from localStorage
  localStorage.removeItem("jwtToken"); 
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({ type: FETCH_USER, payload: {userA:null,user:{}} });
  toastr.success('Logout Successfully') 
  window.location.reload(false)  //this will reset store to initial values
  // history.push("/");
  //window.location.href = '/'; 
} catch (error) {
  toastr.error("Oops", "Something went wrong.Please Try Again");
}
};

export const handleSetRemoveAuth =()=>{
if (localStorage.jwtToken){
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get user info and exp
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));

  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  store.dispatch(setCurrentUser({}));
  store.dispatch({ type: FETCH_USER, payload: {userA:null,user:{}} });
  history.push("/");

  }
}else{
   history.push("/");
}
}

//@@@@@

export const renderMonth =(value)=>{
  if(parseInt(value)===0){return 'January'  }
  if(parseInt(value)===1){return 'February'  }
  if(parseInt(value)===2){return 'March'  }
  if(parseInt(value)===3){return 'April'  }
  if(parseInt(value)===4){return 'May'  }
  if(parseInt(value)===5){return 'June'  }
  if(parseInt(value)===6){return 'July'  }
  if(parseInt(value)===7){return 'August'  }
  if(parseInt(value)===8){return 'September'  }
  if(parseInt(value)===9){return 'October'  }
  if(parseInt(value)===10){return 'November'  }
  if(parseInt(value)===11){return 'December'  }
}
export const renderPreviousMonth =(value)=>{
  if(parseInt(value)===1){return 'January'  }
  if(parseInt(value)===2){return 'February'  }
  if(parseInt(value)===3){return 'March'  }
  if(parseInt(value)===4){return 'April'  }
  if(parseInt(value)===5){return 'May'  }
  if(parseInt(value)===6){return 'June'  }
  if(parseInt(value)===7){return 'July'  }
  if(parseInt(value)===8){return 'August'  }
  if(parseInt(value)===9){return 'September'  }
  if(parseInt(value)===10){return 'October'  }
  if(parseInt(value)===11){return 'November'  }
  if(parseInt(value)===0){return 'December'  }
}

const monthOptionO = [
  renderPreviousMonth(getMonth(new Date())),
  renderMonth(getMonth(new Date()))
  ]

export const monthOption = () => {
  return monthOptionO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

  
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
const handleWhatsappOpen = (val) =>{
  // const nameF =()=>{
  //   if(name){
  //     return name.concat(' ','Ji')
  //   }else{
  //     return "Sir / Madam"
  //   }
  // }
  const phone = val.uwhatsapp
  const countryCode= '+91'
  const message = `${val.mes1} \u20B9 ${val.amount} (${capitalizeFirst(writtenNumber(val.amount, {
    lang: "enIndian"
  }))})`
  
//${this.state.message}`
// Regex expression to remove all characters which are NOT alphanumeric 
let number = phone.replace(/[^\w\s]/gi, "").replace(/ /g, "");

// Appending the phone number to the URL
  let url = `whatsapp://send?phone=${countryCode+number}`; // for mobile

 // let url = `https://web.whatsapp.com/send?phone=${countryCode+number}`; // for desktop
  
// Appending the message to the URL by encoding it
  url += `&text=${encodeURI(message)}&app_absent=0`;

// Open our newly created URL in a new tab to send the message
  window.open(url);
} 

//^^^^^^^^^^
export const renderWhatsappButton = (val) =>
(
  <span 
  className="icon-button display-on-mobile-only"
  onClick={()=>handleWhatsappOpen(val)} 
  >

  <img className= 'yimg ' src='/whatsappIcon.jpg' alt="whatsappIcon" style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />

  </span>
)
//^^^^^^^^^^
//***********************************
