import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";

import {
  Form,
  Button,
} from "semantic-ui-react";
import TextArea from "../form/TextArea";
import {
 // composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';



//
const validate = combineValidators({
 
  entryType:isRequired('Entry Type') ,
  accountId:isRequired('Account Name') ,
  amount:isRequired('Amount') ,
  entryDate:isRequired('Date') ,
});

class PurchaseBookEdit extends Component {
  state = {
    
 };
  componentDidMount() {
    const { id,month } = this.props;
        
    this.props.fetchFinance(id,month);
    
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

  }
  componentWillUnmount(){
    this.props.resetFinance()
  }

  onSubmit = async (values) => {

  //************
  try {
    const { id } = this.props;
    const values_F = {  ...values , month:this.props.month }
       
    const res = await axios.put(`/api/FinanceEdit/${id}`, values_F);

      if(res.data.msg ==='success'){
        toastr.success('Updated Successfully')
      this.props.handleEditModalCloseSubmit()
    }
      }catch (error) {
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********
}
  backButtonHandler =()=>{
    this.props.history.goBack();
    this.props.resetFinance()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
 
const renderFormField =()=>{
  return (
    <React.Fragment>
  
    <label >Narration/Description</label>
    <Field
      name="description"
      type="text"
      component={TextArea}
      rows={3}
      placeholder="Narration/Description"
    />
  
    </React.Fragment>
  )
}


//***************************
    
      return (
        <div className='marginBottom' >
        <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Edit PurchaseBook
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card border">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={this.props.handleEditModalClose}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />
            <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
            <br/>

              { renderFormField()}

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
      );
    }

    
}

const mapStateToProps = state => {

  return { 
    state,
    userA: state.auth.userA,
    finance: state.finances.finance,
    initialValues: state.finances.finance

  };
};

export default connect(
  mapStateToProps,
  {...actions,}
)(
  reduxForm({
    form: "PurchaseBookEdit", // a unique identifier for this form
    //destroyOnUnmount: false
     validate
  })(withRouter(PurchaseBookEdit))
);
