import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import { getMonth,getYear } from 'date-fns'
import { fetchMyGroupUsers, } from "../../actions/authAction";

import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";

import {
  Form, 
  Button,
  //Header,
} from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import NumberInput from "../form/NumberInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
import {renderMonth,
  //renderPreviousMonth,
} from "../functions/Functions";

import {
  //composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';

//
const validate = combineValidators({
  entryType:isRequired('Entry Type') ,
  accountId:isRequired('Account Name') ,
  amount:isRequired('Amount') ,
  entryDate:isRequired('Date') ,
});

class CashBookCashCreate extends Component {
  state = {
    
     monthNum:getMonth(new Date()),
     year:getYear(new Date()),
     monthWord:''
  };
  componentDidMount() {
    if (this.props.state.auth.usersG.length<1){
      this.props.fetchMyGroupUsers()
        }
        
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

  }

  backButtonHandler =()=>{
    this.props.history.goBack()
  }
  onSubmit = values => {
    const findItemBoth = this.props.state.auth.usersG.find(item => item._id === values.accountId)
    
    const values_F = {  ...values , entryType:'Payment' ,accountName:findItemBoth.uname , month:renderMonth(this.state.monthNum) };
    this.props.createFinance(values_F, this.props.history);
  };
  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    //const {finYear} = this.props.userA !== undefined &&  this.props.userA !== null && this.props.userA
 
    const {amount} =
      this.props.state.form &&
      this.props.state.form.CashBookCashCreate !== undefined &&
      this.props.state.form.CashBookCashCreate.values !== undefined &&
      this.props.state.form.CashBookCashCreate.values;


      
      const userType = this.props.userA !== null && this.props.userA.userType
    const accountId = () => {
      return this.props.usersG.map(value => ({
        key: value._id,
        text: `${userType==='Shop Owner'? value.uname: value.shopName} ( ${value.address} ${userType==='Shop Owner'? '#'.concat('',value.userId):''})` ,
        value: value._id,style:{fontSize:'18px'}
      }));
    };

      // locationOnMap = () => {
      //   return [{_id:'',projectName:'',locationOnMap:'N/A'},...this.props.state.projectCs.projectCsForData].map(value => ({
      //     key: value.locationOnMap,
      //     text: `${value.locationOnMap} ( ${value.projectName} | ${value._id} )` ,
      //     value: value.locationOnMap,
      //   }));
      // };
        //***************
    
      return (
        <div  className='marginBottom'>
        <div style={{maxWidth:'700px',margin:'auto',paddingLeft:'15px',paddingRight:'15px'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Create CashBook Entry
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card border">
          <div className=" label" >
            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >
              
        <Button
            color="teal"
            type="button"
            onClick={this.backButtonHandler}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
            <br /><br />
            <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
            <br/>
            


              <label >Account Name</label><span className='color-mandatory'> * </span>

              <Field
              disabled={this.props.disabled || false}
                name="accountId"
                placeholder="Account Name"
                type="text"
                options={accountId()}
                search={true}
                component={SelectInput}
              />
              <label >
                Amount in INR{" "}
                <span>
                  (<b>
                    {' '}{writtenNumber(amount, {
                      lang: "enIndian"
                    }).toUpperCase()}
                    {" "}
                  </b>)
                </span>
              </label><span className='color-mandatory'> * </span>

              <Field
                type="number"
                component={NumberInput}
                placeholder="Amount in INR"
                name="amount"
              />
              <label >Narration/Description</label>
              <Field
                name="description"
                type="text"
                component={TextArea}
                rows={3}
                placeholder="Narration/Description"
              />

              <label >Date</label><span className='color-mandatory'> * </span>

              <Field
                name="entryDate"
                type="text"
                component={DateInput}
                dateFormat="dd-MM-yyyy"
                placeholder="Date"
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                minDate={new Date( getYear(new Date()),getMonth(new Date()) ) }
                maxDate={new Date( getYear(new Date()),getMonth(new Date())+1,0 ) }
              />
              

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
              <Button
            color="teal"
            type="button"
            onClick={this.backButtonHandler}

          >
            Cancel
          </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>
            </div >
      );
    }

    
}



const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    initialValues: {  entryDate:new Date()}

  };
};
export default connect(
  mapStateToProps,

  {...actions,fetchMyGroupUsers}
)(
  reduxForm({
    form: "CashBookCashCreate" ,// a unique identifier for this form
     validate
  })(withRouter(CashBookCashCreate))
);
