import { POPUP_CLOSE, POPUP_OPEN } from '../actions/types';
import { createReducer } from './reducerUtil';

const initialState = null;

export const openPopup = (state, payload) => {
  const {popupType, popupProps} = payload;
  return {popupType, popupProps}
}

export const closePopup = () => {
  return null
}

export default createReducer(initialState, {
  [POPUP_OPEN]: openPopup,
  [POPUP_CLOSE]: closePopup
})