import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import writtenNumber from "written-number";
import axios from "axios";
import { toastr } from "react-redux-toastr";

import { fetchMyGroupUsers, } from "../../actions/authAction";
import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";

import {
  Form, 
  Button,
 // Header,
} from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import NumberInput from "../form/NumberInput";
import TextArea from "../form/TextArea";
import SelectInput from "../form/SelectInput";
import DateInput from "../form/DateInput";
import {
 // composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';

import { getMonth,getYear } from 'date-fns'

//
const validate = combineValidators({
  //bookType:isRequired('Book Type') ,
  entryType:isRequired('Entry Type') ,
  accountId:isRequired('Account Name') ,
  amount:isRequired('Amount') ,
  entryDate:isRequired('Date') ,
});

class SaleBookEdit extends Component {
  state = {
    
 };
  componentDidMount() {
    const { id,month } = this.props;
   
    if (!this.props.state.auth.usersG.length){
      this.props.fetchMyGroupUsers()
        }

    this.props.fetchFinance(id,month);
    
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

  }
  componentWillUnmount(){
    this.props.resetFinance()
  }

  onSubmit = async (values) => {
  //************
  try {
    const { id } = this.props;
    const findItemBoth = this.props.state.auth.usersG.find(item => item._id === values.accountId)
  

const {checkedStatus,accountId,entryDate,amount,} = this.props.state.finances.finance


const checkedStatusHandler=()=>{
if((accountId!==values.accountId)||(entryDate!==values.entryDate)||(amount!==values.amount)){
  return 'Not Checked'
}else{
  return checkedStatus
}
}

    const values_F = {  ...values , accountName:findItemBoth.uname ,checkedStatus:checkedStatusHandler(), month:this.props.month }
                    
    const res = await axios.put(`/api/FinanceEdit/${id}`, values_F);

      if(res.data.msg ==='success'){
        toastr.success('Updated Successfully')
      this.props.handleEditModalCloseSubmit()
    }
      }catch (error) {
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********
}
  backButtonHandler =()=>{
    this.props.history.goBack();
    this.props.resetFinance()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    
    
const checkedStatus =this.props.finance !== null && this.props.finance.checkedStatus
const entryType =this.props.finance !== null && this.props.finance.entryType


    const {amount,} =
      this.props.state.form &&
      this.props.state.form.SaleBookEdit !== undefined &&
      this.props.state.form.SaleBookEdit.values !== undefined &&
      this.props.state.form.SaleBookEdit.values;

      const userType = this.props.userA !== null && this.props.userA.userType
    const accountId = () => {
      return this.props.usersG.map(value => ({
        key: value._id,
        text: `${userType==='Shop Owner'? value.uname: value.shopName} ( ${value.address} ${userType==='Shop Owner'? '#'.concat('',value.userId):''})` ,
        value: value._id,style:{fontSize:'18px'}
      }));
    };
//************************ */
const noChange = () => {
  return ['No Change'].map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const renderNoChangeField =() => {
  if(checkedStatus === 'Checked Not Ok'){
    return (
      <React.Fragment>
      <label >No Change In Entry</label>
  <Field
    name="noChangeInEntry"
    placeholder="No Change In Entry"
    type="text"
    options={noChange()}
    component={SelectInput}
  />
  </React.Fragment>
    )
  }
}
//*********************************
const formFieldBoth =(
  <React.Fragment>

  <label >Narration/Description</label>
  <Field
    name="description"
    type="text"
    component={TextArea}
    rows={3}
    placeholder="Narration/Description"
  />


  </React.Fragment>
)
//^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^
const formFieldBothAll=(
  <React.Fragment>

  <label >Account Name</label><span className='color-mandatory'> * </span>

  <Field
    name="accountId"
    placeholder="Account Name"
    type="text"
    options={accountId()}
    search={true}
    component={SelectInput}
  />

  
  <label >
    Amount in INR{" "}
    <span>
      (<b>
        {' '}{writtenNumber(amount, {
          lang: "enIndian"
        }).toUpperCase()}
        {" "}
      </b>)
    </span>
  </label><span className='color-mandatory'> * </span>

  <Field
    type="number"
    component={NumberInput}
    placeholder="Amount in INR"
    name="amount"
  />

<label >Narration/Description</label>
  <Field
    name="description"
    type="text"
    component={TextArea}
    rows={3}
    placeholder="Narration/Description"
  />

  <label >Date</label><span className='color-mandatory'> * </span>

  <Field
    name="entryDate"
    type="text"
    component={DateInput}
    dateFormat="dd-MM-yyyy"
    placeholder="Date"
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    minDate={new Date( getYear(new Date()),getMonth(new Date()) ) }
    maxDate={new Date( getYear(new Date()),getMonth(new Date())+1,0 ) }
    />

{renderNoChangeField()}

  </React.Fragment>
)

//^^^^^^^^^^^^^^^^
const renderFormField =()=>{

  //+++++++++++++++
 
  if((entryType === 'Sale')&&(checkedStatus === 'Checked Ok')){
    return formFieldBoth
  }
  if(((entryType === 'Sale')&&((checkedStatus === 'Not Checked')||(checkedStatus === 'Checked Not Ok')||(checkedStatus === 'Unlocked')))){
    return formFieldBothAll
  }
  
  
}


//***************************
    
      return (
        <div className='marginBottom' >
        <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Edit SaleBook
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card border">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={this.props.handleEditModalClose}

          >
            Cancel
          </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <br /><br />
            <div><span className='color-mandatory'>* </span>Mandatory Fields</div>
            <br/>

              { renderFormField()}

              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
      );
    }

    
}

const mapStateToProps = state => {

  return {
    state,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    finance: state.finances.finance,
    initialValues: state.finances.finance

  };
};

export default connect(
  mapStateToProps,
  {...actions,fetchMyGroupUsers}
)(
  reduxForm({
    form: "SaleBookEdit", // a unique identifier for this form
    //destroyOnUnmount: false
     validate
  })(withRouter(SaleBookEdit))
);
