//import React, { Component } from 'react';
import React  from 'react';
import { Modal, } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closePopup } from '../../actions/popupAction';

const actions = { closePopup };


 const CopyPhoneFixedPopup = (props)=> {

  return (
    <Modal 
    // dimmer='blurring'
    //size="tiny"
    dimmer={props.dimmer} // "inverted"/"blurring"
    open={true} 
    onClose={props.closePopup}
    closeOnEscape={false}
    closeOnDimmerClick={false} 
    style = {{
      top:'auto',  // for positioning of modal at center of view port
    maxWidth:'300px'
    }}
    >
     
      <Modal.Content className='border-2'
      >


        <div
        className='font-18B mb-20'
        style={{color:props.color}}
        >
        {props.message}
        </div>

        <div className='display-flex j-c-flex-end'>
        {props.action1 &&  (<span 
       className='icon-button1 font-16B border-skyblue-2 px-05 '
        onClick={props.closePopup}
        > 
        {props.action1}
        </span >)}
     {props.action2 &&  (<span 
       className='icon-button1 font-16B border-skyblue-2 px-05 '
        onClick={props.closePopup}
        > 
        {props.action2}
        </span >)}
 
        </div>
      </Modal.Content>
    </Modal>
  );
    
    
  }

// const mapState = state => ({
//   message1: state.popups.popupProps.message
// });
export default connect(
  null,
  actions
)(CopyPhoneFixedPopup); 
