import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { openPopup } from "../../actions/popupAction";
import {  Form, Button,  } from "semantic-ui-react";
//import CheckboxInput_2 from "../form/CheckboxInput_2"
//import SecurityQACheckForm from "./SecurityQACheckForm";
import PassReset from "./PassReset";

 
  class SecurityQACreate extends Component {
      state = {
        showP:false,
        userId:'',
        SQACheck:'NO',
        userIdForm :'YES',
        passResetForm :'NO',
        Q1: '',
        Q2: '',
        Q3: '',
        A1: '',
        A2: '',
        A3: '',
      };
    // componentDidMount() {
      
    // }
    
    clearPassword=() => {
      this.setState({userId:''});
    }
    clearSA=() => {
      this.setState({A1:'',A2:'',A3:''});
    }
    
    onSubmit = async () => {
      const values = {userId:this.state.userId}
      //************
        try {
        if(this.state.userId){
          const res = await axios.post(`/api/FetchSQ`, values);
  
          if(res.data.msg ==='success'){
            this.setState({SQACheck:'YES',
            userIdForm :'NO', ...res.data.securityQ})

        }
        if(res.data.msg ==='errorUser'){
          this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Either your Id is Incorrect or there is No User with this Id`});
          }
      if(res.data.msg === 'errorSQA'){
        this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Your Have not Created Security Questions / Answers.Please Contact Support`});
        }
    if(res.data.msg === 'errorAttempt'){
      this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Your Security Q & A Access is Locked.Please Contact Support`});
      }
    }else{
      
      if(!this.state.userId){
        this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`User Id is Required`});
      }
      
          }
          }catch (error) {
            
            if(error && error.response && error.response.status==401){
              this.props.openModal('LoginModal')
              toastr.error(`${JSON.stringify(error.response.data) }`);
            }else if(error){
              toastr.error("Oops", "Something went wrong.Please Try Again");
            }
        }
      //**********
    }
    // @@@
    onSubmitSA = async () => {
      const values = {
        userId:this.state.userId,
        A1:this.state.A1,
        A2:this.state.A2,
        A3:this.state.A3,
      }
      //************
        try { 
          if(this.state.A1 &&
            this.state.A2 &&
            this.state.A3){
          const res = await axios.post(`/api/CheckSA`, values);
  
          if(res.data.msg ==='success'){
            this.setState({SQACheck:'NO',
            passResetForm :'YES',})
            this.clearSA()

        }
        if(res.data.msg ==='errorSA'){
          this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`You Have Submited Wrong Answers.Please Try Again.You are left with ${res.data.attemptLeft} more attempts`});
            }
      if(res.data.msg === 'errorAttempt'){
        this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Your Security Q & A Access is Locked.Please Contact Support`});
        }
      }else{
        
        this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`All Answers are Required`});
      
          }
          }catch (error) {
            
            if(error && error.response && error.response.status==401){
              this.props.openModal('LoginModal')
              toastr.error(`${JSON.stringify(error.response.data) }`);
            }else if(error){
              toastr.error("Oops", "Something went wrong.Please Try Again");
            }
        }
      //**********
    }
    // @@@
    
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  //**********
  
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^
  UserIdForm = () => {
    
    return (
      <div style={{marginTop:'75px',padding:'10px'}}>
  <div className="card-form border">
            <div className=" label" style={{padding:'20px'}} >

           
            <div className="font-20B color-teal">
Enter and Submit your User Id to Access Security Questions / Answers which you need to answer to reset your password.
</div> 
<div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>
<br/>
<Form onSubmit={this.onSubmit}>
  <Form.Field >
  <label >User Id<span className='color-mandatory'> * </span></label>
        <input
        className="input"
        type="text"
        placeholder="User Id"
        name="userId"
          value={this.state.userId}
          onChange={this.onChange}

        />
        
      </Form.Field>
      <Button 
      //inverted 
       color="olive" type="submit">
         Submit
      </Button>
      <Button
              
              color="orange"
              type="button"
              onClick={this.clearPassword}
            >
              Clear Values
            </Button>
            <Button
              
              color="blue"
              type="button"
              onClick={()=>this.props.history.push('/')}
            >
              Cancel
            </Button>
      
  </Form>
      </div>
         </div>
  </div>
    )
  }

  // ****
//^^^^^^^^^^^
SQACheckForm = () => {
    
  return (
    <div style={{marginTop:'75px',padding:'10px'}}>
<div className="card-form border">
          <div className=" label" style={{padding:'20px'}} >

         
          <div className="font-20B color-teal">
Submit your Answers to reset your password.
</div> 
<div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
        </div>
<br/>
<Form onSubmit={this.onSubmitSA}>
<Form.Field >
<span className="color-teal font-16B" >Q1:{this.state.Q1}</span>
<label >A1:<span className='color-mandatory'> * </span></label>
      <input
      className="input"
      type="text"
      placeholder="your answer of first question"
      name="A1"
        value={this.state.A1}
        onChange={this.onChange}

      />
      
    </Form.Field>

    <Form.Field >
<span className="color-teal font-16B" >Q2:{this.state.Q2}</span>
<label >A2:<span className='color-mandatory'> * </span></label>
      <input
      className="input"
      type="text"
      placeholder="your answer of second question"
      name="A2"
        value={this.state.A2}
        onChange={this.onChange}

      />
      
    </Form.Field>

    <Form.Field >
<span className="color-teal font-16B" >Q3:{this.state.Q3}</span>
<label >A3:<span className='color-mandatory'> * </span></label>
      <input
      className="input"
      type="text"
      placeholder="your answer of third question"
      name="A3"
        value={this.state.A3}
        onChange={this.onChange}

      />
      
    </Form.Field>
    <Button 
    //inverted 
     color="teal" type="submit">
       Submit
    </Button>
    <Button
            
            color="orange"
            type="button"
            onClick={this.clearSA}
          >
            Clear Values
          </Button>
          <Button
            
            color="blue"
            type="button"
            onClick={this.backButtonHandler}
          >
            Cancel
          </Button>
    
</Form>
    </div>
       </div>
</div>
  )
}

  //*************** */
  render(){
    
    

      //*************************** */
  return (

    <div style={{padding:'10px'}}>
<div style={{maxWidth:'700px',margin:'auto'}}>

{ (this.state.userIdForm ==='YES') && this.UserIdForm ()}

{this.state.SQACheck === 'YES' && this.SQACheckForm()}

{this.state.passResetForm === 'YES' &&

<PassReset
userId ={this.state.userId} 
/>
}

      </div>
            </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {openPopup}
)(withRouter(SecurityQACreate));
