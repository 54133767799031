import { POPUP_CLOSE, POPUP_OPEN } from './types';

export const openPopup = (popupType, popupProps) => {
  return {
    type: POPUP_OPEN,
    payload: {
      popupType,
      popupProps
    }
  }
}

export const closePopup = () => {
  return {
    type: POPUP_CLOSE
  }
}