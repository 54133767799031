import React, { Component } from 'react';
import { Icon,
  Image
 } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';


class ImageZoom extends Component {
  render() {
    return (
      <>
        
       <Image
          
          style={{maxWidth:"400px", maxHeight:"400px",borderRadius:'3px'}}
          centered 
            //size="small"
            src={`${this.props.imageURL}`}
            //src={`${imageURL_User}/${this.props.image}`}
            
          />
          <div className='disflex-paginator'>
          <span className='icon-button border1 '
onClick={this.props.closeModal}
style={{color:'white'}}
> <Icon name='close' />
</span >
          </div>
      </>    
    );
  }
}

const mapState = state => ({
  state,
  imageURL: state.modals.modalProps !== undefined &&state.modals.modalProps.imageURL
});
export default connect(
  mapState,
  {closeModal}
)(ImageZoom);
