import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { toastr } from "react-redux-toastr";

import { closeModal, openModal } from "./modalAction";

import {
 // GET_ERRORS,
  SET_CURRENT_USER,
  SEARCH_USERS,
  FETCH_USERS,
  FETCH_USER,
  FETCH_USERDETAIL,
  FETCH_MYDETAIL,
  SHOW_SIDEBAR,
  HIDE_SIDEBAR,
  SHOW_BACKBUTTON ,
  HIDE_BACKBUTTON ,
  //
  MESSAGE,
} from "./types";


//Update password
export const updatePassword = (userData, history ) => async (dispatch) => {
  try {
    const res = await axios.post('/api/users/updatePassword', userData);


const message = res.data.msg
    history.goBack()
   toastr.success(message)
  } catch (error) {

    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//fetch My Group users
export const fetchMyGroupUsers = () => async dispatch => {
  try {
    const res = await axios.post("/api/MyGroupUsers");

    dispatch({ type: SEARCH_USERS, payload: res.data });
  //  dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};


// Login - Get User Token
export const loginUser = (userData, history) => async dispatch => {
  try {
    const res = await axios.post("/api/users/login", userData);
    
  //***************************
  if (res.data.msg ==='errorInactive') {
    toastr.error('Your are not Active User. Please contact Support');
  }
  if (res.data.msg ==='errorPass') {
  toastr.error(`Password Incorrect.You are left with ${res.data.attemptLeft} more attempts`);
  }
  if (res.data.msg ==='errorNoUser') {
    toastr.error('Either your Id is Incorrect or there is No User with this Id');
  }
  if(res.data.msg === 'errorAttempt'){
    toastr.error(`Your Login Access is Locked.Please Contact Support`);
    }

if(res.data.msg ==='success'){
  
      const { token } = res.data;


      // Set token to ls
      localStorage.setItem("jwtToken", token);

      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
      dispatch(closeModal());
      dispatch({ type: FETCH_USER, payload: res.data.userA });
//fetch my group users
      dispatch({ type: SEARCH_USERS, payload: res.data });
      toastr.success('Login Successfully')
      history.push(`/`);
      
}


  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//##############################
//Form PassReset
export const formPassReset = (userData, history) => async (dispatch) => {
  try {
    const res = await axios.post('/api/ReqForOTP', userData);

    if(res.data.msg === 'error'){
      toastr.error("Please check you have entered  Wrong Details Or there is No User with these Details")
    }

      if(res.data.msg ==='success'){
        history.push(`/passreset`);   
        toastr.success('OTP is send at your Registered Email.')
      }
  } catch (error) {
   
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//****************************
//RESET PASSWORD
export const resetPassLogin = (userData, history) => async dispatch => {
  try {
    const res = await axios.put(`/api/resetPassword`, userData);

    if(res.data.msg === 'error'){
      toastr.error("OTP have Expired Or Invalid. Please Get Another.")
    }
      if(res.data.msg ==='success'){
        history.push(`/`); 
        dispatch(openModal('LoginModal'));
        toastr.success('Password Changed.You can Login with New Password ')
      }
    
    

  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//


// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded
  };
};

// Log user out
export const logoutUser = () => async dispatch => {
  try {
    // const res = await axios.post("/api/users/logout");

    //  if(res.data.msg ==='success'){

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({ type: FETCH_USER, payload: {userA:{},user:{}} });
  window.location.href = '/';

 // toastr.success('Logout Successfully')

    // }
} catch (error) {
 
  if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
}
};
//*************************************************
export const clearStorage = () =>dispatch => {

  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  window.location.href = '/';

};
//*************************************************
export const clearStorage2 = () =>dispatch => {


  dispatch(openModal('LoginModal'));


};
//####################################################################
//search  Users

export const searchUsers = ( values ) => async dispatch => {

  try {
    const res = await axios.post("/api/UserSearch", {
      values
    });

    dispatch({ type: SEARCH_USERS, payload: res.data });
    //dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//****************************
//add / remove user to My Group
export const addRemoveToMyGroup = (id,month,monthPre) => async dispatch => {

try {
  const res = await axios.post("/api/AddRemoveToMyGroup",{
    id:id,month:month,monthPre } );
    if(res.data.msg ==='Entry Exist'){
      toastr.error('There Are Entry Related To This User You Can Not Remove This from My Group')
    }
  if(res.data.msg === 'removed'){
    dispatch({ type: FETCH_USER, payload: res.data.userA });
    dispatch(fetchMyGroupUsers())
    toastr.error("User Removed from My Group.")
  }else {
    if(res.data.msg ==='added'){
      dispatch({ type: FETCH_USER, payload: res.data.userA });
      dispatch(fetchMyGroupUsers())
      toastr.success('User Added to My Group')
    }
  }


} catch (error) {

  if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
}
};


//********************************** */

//fetch all Users
export const fetchUsers = ( offset) => async dispatch => {
  try {
    const res = await axios.post("/api/UserList", {
      // limit: limit,
      offset: offset
    });

    dispatch({ type: FETCH_USERS, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//fetch single User
export const fetchUser = id => async dispatch => {
  try {
    const res = await axios.get(`/api/UserDetail/${id}`);

    dispatch({ type: FETCH_USERDETAIL, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//*************************************************
//fetch My Detail
export const fetchMyDetail = () => async dispatch => {
  try {
    const res = await axios.get(`/api/MyDetail`);

    dispatch({ type: FETCH_MYDETAIL, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
//*************************************************
export const getUserForAuth = () => async dispatch => {
  try {
    const res = await axios.get(`/api/GetUserForAuth`);

    dispatch({ type: FETCH_USER, payload: res.data });
  } catch (error) {
    console.log(error)

  }
};
//********************************************
////update User by admin user
export const updateUser = (id, values, history) => async (dispatch) => {
  try {
    const res = await axios.put(`/api/UserEdit/${id}`, values);

    if (res.data.msg==='success') {

      toastr.success('User Updated Successfully');

    }
    history.push(`/user/MyDetail`);
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};


// create user
export const createUser = (values, history) => async dispatch => {
  try {

    const res = await axios.post("/api/user/create", { values });

    if(res.data.msg==='errorUserAlready'){

      toastr.error('User Id Already Exists Use Another ');
    }
    if(res.data.msg==='success'){
      dispatch({ type: MESSAGE, payload: {message:'Thanks for Registering with Us. Your Account will be active and displayed very soon. A email / message in this regard will be send to you at your registered email Id / phone. Have a good time.'} });
    history.push('/');
    dispatch(openModal('MessageModal'));

    }


  } catch (error) {

    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//********************************** */
export const showSidebar = () => async dispatch => {
  try {

    dispatch({ type: SHOW_SIDEBAR, payload: true });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

export const hideSidebar = () => async dispatch => {
  try {

    dispatch({ type: HIDE_SIDEBAR, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//********************************** */

export const showBackButton = () => async dispatch => {
  try {

    dispatch({ type: SHOW_BACKBUTTON, payload: true });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

export const hideBackButton = () => async dispatch => {
  try {

    dispatch({ type: HIDE_BACKBUTTON, payload: false });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//******************** */

//reset user
export const resetUser = () => async ( dispatch) => {
  try {
dispatch({ type: FETCH_USERDETAIL, payload: null });

  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};
