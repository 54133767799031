import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import ImageZoom from './ImageZoom';
const actions = { closeModal };

class ImageZoomModal extends Component {
  render() {
    return (
      <Modal  
      open={true} 
      onClose={this.props.closeModal}
      // closeOnEscape={false}
      // closeOnDimmerClick={false}
      style = {{
        
        background:'transparent',
        //opacity: 0.7,
        margin:'0px',
        padding: '0px',
        height: 'auto',
        width: 'auto',
        top:'auto',
      }}
      
      >
       
       <Modal.Content style = {{
        borderRadius: 3,
        fontSize:"16px",
        fontWeight:"bold",
        background:'transparent',
        // backgroundColor:'black',
        //opacity: 0.7,
        margin:'0px',
        padding: '0px',
        // height: 'auto',
        // width: 'auto',
        // height:'50px',
        // width:'50px'
        //zIndex:5
      }}>
       <ImageZoom/>
        </Modal.Content>
      </Modal>
    );
  }
}


export default connect(
  null,
  actions
)(ImageZoomModal);
