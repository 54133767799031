import React, {Component} from 'react';
//import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { hideBackButton, } from "../../actions/authAction";
import { withRouter } from 'react-router-dom';
import { openPopup } from "../../actions/popupAction";

import Layout from "../layout/Layout";
// import { Icon,} from 'semantic-ui-react';
import LinkItem from './LinkItem'
import LinkItem_1 from './LinkItem_1'
import LinkItemMobile from './LinkItemMobile'
import LinkItemMobile_1 from './LinkItemMobile_1'

class HomeAuth extends Component {
  
  componentDidMount() {
    if(this.props.history.location.pathname ==='/'){
      this.props.hideBackButton()
    }

    if(this.props.userA!== null && this.props.userA.SQACreated ==='NO'){
      this.props.openPopup('AlertCreateSQAPopup')
    }
   
  }
  
  
 
//*************** */
         
render() {

  //const {userSubscriptionType,userType} =  this.props.auth.userA !== null && this.props.auth.userA
        
  const cashBookRouteHandler = ()=>{
    return(
      <>
      <LinkItem_1
      url={"/cashBook/CashBook"}
      title={'CashBook'}
      />
</>
        )
    
  }
  const saleBookRouteHandler = ()=>{
    if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
      return(
        <>
        <LinkItem
        url={"/sale/SaleBook"}
        title={'SaleBook'}
        />

</>

          )
    
  }
}
  const purchaseBookRouteHandler = ()=>{
    if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Individual'){
      return(
        <>
        <LinkItem
        url={"/purchase/PurchaseBook"}
        title={'PurchaseBook'}
        />

</>
          )
    
  }
}
  const allFinanceRouteHandler = ()=>{
    if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
      return(
        <>
        <LinkItem
        url={"/allfin/AllFinance"}
        title={'All Finance'}
        />

</>
          )
    }
  }

  const allAccountsRouteHandler = ()=>{
    return(
      <>
      <LinkItem_1
      url={"/finance/AllAccounts"}
      title={'All Accounts'}
      />

</>
        )
  }

  const userRouteHandler = ()=>{
   
    if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
      return(
        <>
        <LinkItem
        url={"/user/UserSearchList"}
        title={'Search Buyer'}
        />

        <LinkItem_1
        url={"/user/MyGroup"}
        title={'My Group'}
        />

        <LinkItem
        url={"/user/MyDetail"}
        title={'My Detail'}
        />
</>
          )
    
    }

    if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Individual'){
      return(
        <>
        
        <LinkItem
        url={"/user/MyGroup"}
        title={'My Group'}
        />

        <LinkItem_1
        url={"/user/MyDetail"}
        title={'My Detail'}
        />
</>

          )
      
      }
  }
  

// @@@@@@ mobile link
const cashBookRouteHandlerMobile = ()=>{
  return(
    <>
    
    <LinkItemMobile
    url={"/cashBook/CashBook"}
    title={'CashBook'}
    />
</>
      )
  
}
const saleBookRouteHandlerMobile = ()=>{
  if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
    return(
      <>     

<LinkItemMobile_1
url={"/sale/SaleBook"}
title={'SaleBook'}
/>

</>

        )
  
}
}
const purchaseBookRouteHandlerMobile = ()=>{
  if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Individual'){
    return(
      <>
      
<LinkItemMobile_1
      url={"/purchase/PurchaseBook"}
      title={'PurchaseBook'}
      />

</>
        )
  
}
}
const allFinanceRouteHandlerMobile = ()=>{
  if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
    return(
      <>
      
<LinkItemMobile_1
      url={"/allfin/AllFinance"}
      title={'All Finance'}
      />

</>
        )
  }
}

const allAccountsRouteHandlerMobile = ()=>{
  return(
    <>
    
<LinkItemMobile
    url={"/finance/AllAccounts"}
    title={'All Accounts'}
    />
</>
      )
}

const userRouteHandlerMobile = ()=>{
 
  if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
    return(
      <>

<LinkItemMobile
      url={"/user/UserSearchList"}
      title={'Search Buyer'}
      />

      <LinkItemMobile_1
      url={"/user/MyGroup"}
      title={'My Group'}
      />

      <LinkItemMobile
      url={"/user/MyDetail"}
      title={'My Detail'}
      />
</>
        )
  
  }

  if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Individual'){
    return(
      <>

<LinkItemMobile_1
      url={"/user/MyGroup"}
      title={'My Group'}
      />

      <LinkItemMobile
      url={"/user/MyDetail"}
      title={'My Detail'}
      />
</>

        )
    
    }
}

  
      

const authHome = (
  <div className="background-link-home  body " style={{marginTop:'0px'}}>
  <div  >
  <Layout onLogoutClick={this.onLogoutClick}>
  <div >
<div className='py-3 disflex-center'>{/* start card*/}
  
{/*  */}

<div>
                                
 
{/*  */}
<div className='disflex-start ' >
{cashBookRouteHandler()}
    {saleBookRouteHandler()}
    {purchaseBookRouteHandler()}
    {allFinanceRouteHandler()}
    {allAccountsRouteHandler()}
    </div>
    <div 
    className='disflex-start ml-100'
       >
   
   {userRouteHandler()}
    </div>
    
    <div>
    {cashBookRouteHandlerMobile()}
    {saleBookRouteHandlerMobile()}
    {purchaseBookRouteHandlerMobile()}
    {allFinanceRouteHandlerMobile()}
    {allAccountsRouteHandlerMobile()}
   <div className='mt-50'> {userRouteHandlerMobile()} </div>

    </div>

    </div>
</div>
</div>  {/* end card/segment*/}
  </Layout >
  </div>
  </div>


)



  return(
    <div
    // Disable right click
  onContextMenu={e => e.preventDefault()}
    >
      {authHome}
    </div>
  );
  
}
}
const mapStateToProps = state => {

return({
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
});
}

export default connect(
  mapStateToProps,
  {hideBackButton,openPopup }
)(withRouter(HomeAuth));
//export default Landing;
