import React from 'react';
import { connect } from "react-redux";
import * as actions from "../../actions/financeAction";
import AddImageContra from "../image/AddImageContra";
import ViewImageContra from "../image/ViewImageContra";
import ViewPdfContra from "../image/ViewPdfContra";
import ViewImageContraOK from "../image/ViewImageContraOK";
import AddImagePdfContra from "../image/AddImagePdfContra";

class AddViewImageContra extends React.Component {
  state = {
    addImage:false,  
    showImage:true,
    addImagePdf:false,
  };
  async componentDidMount  () {
    await this.props.fetchFinance(this.props.idEnt);

    if(
      (this.props.imagePathContra !== undefined && this.props.imagePathContra.length <1)
      && (this.props.entryFor==='Self') 
      
       
){
  
  this.setState({addImagePdf:true,addImage:false,showImage:false})
}
//************************** 
if(
  (this.props.imagePathContra !== undefined && this.props.imagePathContra.length <1)
  && (this.props.entryFor==='Both') &&
  (
     (this.props.entryType=== 'Payment (Cr)')||
     (this.props.entryType=== 'Sale (Cr)')||
    (this.props.entryType==='Sale Return (Dr)')
    )
){
  if(this.props.checkedStatus ==='Checked Ok'){
  return
  }else{
    this.setState({addImagePdf:true,addImage:false,showImage:false})
  }

}
}
//@
componentWillUnmount(){
  this.props.resetFinance()
}

//*
  showAddImage = ()=> this.setState({addImage:true,showImage:false,addImagePdf:false,})
  showShowImage = ()=> this.setState({showImage:true,addImage:false,addImagePdf:false,})
//*
  renderAddImagePdf = ()=>{
    if(this.state.addImagePdf){
  return (
    <AddImagePdfContra
    id={this.props.id}
    entryFor={this.props.entryFor}
    entryType={this.props.entryType}
    idOfContraEntry={this.props.idOfContraEntry}
    imagePathContra={this.props.imagePathContra}
    handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
    handleRefresh = {this.props.handleRefresh}
    showShowImage={this.showShowImage}
    />
  )
}
}
//********************
//*
  renderAddImage = ()=>{
    if(this.state.addImage){
  return (
    <AddImageContra
    id={this.props.id}
    entryFor={this.props.entryFor}
    entryType={this.props.entryType}
    idOfContraEntry={this.props.idOfContraEntry}
    imagePathContra={this.props.imagePathContra}
    handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
    handleRefresh = {this.props.handleRefresh}
    showShowImage={this.showShowImage}
    />
  )
}
}
//********************
renderShowImage =()=>{
  if(this.state.showImage){ 
    if(this.props.state.finances.finance !== undefined && 
      this.props.state.finances.finance !== null && 
      this.props.state.finances.finance.imagePathContra !== undefined &&
      this.props.state.finances.finance.imagePathContra.length > 0 &&  
      this.props.state.finances.finance.imagePathContra[0].indexOf('pdf') !== -1 ){

        return ( <ViewPdfContra
        id={this.props.id}
      entryFor={this.props.entryFor}
      entryType={this.props.entryType}
      idOfContraEntry={this.props.idOfContraEntry}
      imagePathContra={this.props.imagePathContra}
      checkedStatus={this.props.checkedStatus}
      handleViewPdfModalClose= {this.props.handleAddViewImageModalClose}
      handleRefresh = {this.props.handleRefresh}
        //^^^^^^^^^^^
    
    />)
    }

    //^^^^^^^^^^^^^^^^^
    if(
      ((this.props.entryFor==='Both')&&(this.props.checkedStatus ==='Checked Ok'))
      ||(
        (this.props.entryFor==='Both')&&
        ( (this.props.entryType=== 'Receipt (Dr)')||
        (this.props.entryType=== 'Purchase (Dr)')||
        (this.props.entryType==='Purchase Return (Cr)') )
        )
  ){
    return (
      <ViewImageContraOK
    //  id={this.props.id}
    //  idOfContraEntry={this.props.idOfContraEntry}
      entryFor={this.props.entryFor}
      imagePathContra={this.props.imagePathContra}
      handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
    //  handleRefresh = {this.props.handleRefresh}
      //showAddImage={this.showAddImage}
      />
    )
  }else{ 
    return (
      <ViewImageContra
      id={this.props.id}
      entryFor={this.props.entryFor}
      entryType={this.props.entryType}
      idOfContraEntry={this.props.idOfContraEntry}
      imagePathContra={this.props.imagePathContra}
      handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
      handleRefresh = {this.props.handleRefresh}
      showAddImage={this.showAddImage}
      />
    )
  }
  }
  }
  //********************
render() {
  
  return(
    <div>
    {this.renderAddImagePdf()}
    {this.renderAddImage()}
    {this.renderShowImage()}
    </div>
  )
  }
}

const mapStateToProps = (state) => {
  const { _id,idOfContraEntry, imagePathContra, entryFor,entryType,checkedStatus } = state.finances.finance || {};

  return {
    state,
    id:_id,
    idOfContraEntry, 
    imagePathContra,
    entryFor,
    entryType,
    checkedStatus,
  };
};

export default connect(
  mapStateToProps,
{  ...actions}
)(AddViewImageContra);
//export default AddViewImageContra;
