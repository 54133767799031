import React from 'react';
import axios from 'axios';
import {
  Image,
  // Segment,
  // Header,
  Confirm,
 // Button,
  Icon
} from 'semantic-ui-react';
import { toastr } from "react-redux-toastr";


class ViewImageContra extends React.Component {
  state = {
      rotation: 0,
      path:'',
      open: false, 
      dimensions: {},
  };

//*********************

rotate=()=>{
  let newRotation = this.state.rotation + 90;
  if(newRotation >= 360){
    newRotation =- 360;
  }
  this.setState({
    rotation: newRotation,
  })
}

rotateleft=()=>{
  let newRotation = this.state.rotation - 90;
  if(newRotation <= -360){
    newRotation = 360;
  }
  this.setState({
    rotation: newRotation,
  })
}


show = (path) => {

  this.setState({ open: true,  path:path });
};
///**********************
onImgLoad=({target:img})=> {
        this.setState({dimensions:{height:img.offsetHeight,
                                   width:img.offsetWidth}});
    }
//**********************

  handleConfirm = async () => {
    const  value = {path:this.state.path}
    const imageURL_F=this.state.path.substr(0, 23)

     try {

      //************ */
      if((this.props.entryFor === 'Self')){
        const resImage = await axios.post(`${imageURL_F}/api/ImageDelete`, value);

       if(resImage.data.msg ==='success'){
         //##
         if((this.props.entryType=== 'Receipt (Contra)')||(this.props.entryType==='Payment (Contra)')){
          const removeIndex = this.props.imagePathContra
          .map(item => item.toString())
          .indexOf(this.state.path);
        this.props.imagePathContra.splice(removeIndex, 1);
        
          const values = {
            id:this.props.id,
            idOfContraEntry:this.props.idOfContraEntry,
            imagePathContra:this.props.imagePathContra
          }
        
          const res= await axios.put(`/api/EditForImageContra`, values);
          if(res.data.msg ==='success'){
            this.props.handleRefresh()
            this.props.handleAddViewImageModalClose()
            toastr.success('Image Deleted Successfully')
            }
        }else{
          const removeIndex = this.props.imagePathContra
          .map(item => item.toString())
          .indexOf(this.state.path);
        this.props.imagePathContra.splice(removeIndex, 1);
         const values = {
           id:this.props.id,
           imagePathContra:this.props.imagePathContra
         }
       
         const res= await axios.put(`/api/EditForImageSelf`, values);
         
       if(res.data.msg ==='success'){
       this.props.handleRefresh()
       this.props.handleAddViewImageModalClose()
       toastr.success('Image Deleted Successfully')
       }
       //##
      }
         

      } // end bracket for second if statement
    } //entryFor Self statement end
      //************ */
      if((this.props.entryFor === 'Both')){
       const resCheck = await axios.get(`/api/FinanceDetail/${this.props.id}`);
 if(resCheck.data.checkedStatus ==='Checked Ok'){
  toastr.error("Entry is Locked You Can't do this action");

       
   }else{ 
    const resImage = await axios.post(`${imageURL_F}/api/ImageDelete`, value);

    if(resImage.data.msg ==='success'){
      const removeIndex = this.props.imagePathContra
        .map(item => item.toString())
        .indexOf(this.state.path);
      this.props.imagePathContra.splice(removeIndex, 1);
      const values = {
        id:this.props.id,
        idOfContraEntry:this.props.idOfContraEntry,
        imagePathContra:this.props.imagePathContra
      }
    const res= await axios.put(`/api/EditForImageContra`, values);
    if(res.data.msg ==='success'){
    this.props.handleRefresh()
    this.props.handleAddViewImageModalClose()
    toastr.success('Image Deleted Successfully')
    }
  }
   }

  } //entryFor Both statement end
  //try catch
    } catch (error) {
    
      toastr.error("Oops", "Something went wrong.Please Try Again");
    }


  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });
//**************************
        renderList=(item)=> {
          const {width, height} = this.state.dimensions;
          const margin = (width-height)*.5
          const margin_top = ((this.state.rotation===-90)||(this.state.rotation===90)||(this.state.rotation===-270)||(this.state.rotation===270))? {marginTop:`${margin}px`,marginBottom:`${margin}px`,}:{}
          
              return (
                <div key={item}>
            {/**/}
            <div className='disflex-paginator' style={margin_top}>

            <br/>

            <Image
            onLoad={this.onImgLoad}
            cache='reload'
            alt="image_"
            style={{transform: `rotate(${this.state.rotation}deg)`}}
            src={`${item}`}
            //src={`${imageURL}/${item}`}
            />

            </div>

            {/**/}
            <div className='disflex-paginator'>

<span className='icon-button border1'>{`${item.substr(59, 7)}`}</span>
            <span className='icon-button border1'
            onClick={this.props.handleAddViewImageModalClose}
            > <Icon name='close' />
            </span >
            <span
            onClick={() => this.show(item)}
            className="icon-button"
            >
            <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>

            <div className='nodisplay'>
            <span
            onClick={this.rotateleft}
            className="icon-button"
            >
            <img className= 'yimg ' src='/rotateLeftIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
            <span
            onClick={this.rotate}
            className="icon-button"
            >
            <img className= 'yimg ' src='/rotateRightIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>

            </div>

            </div>
            <br />



            {/**/}
                </div>
                )
              }

//**************
  render() {
const { open,  } = this.state;
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
{/**/}
<Confirm
   header='You Want To Delete !'
  size="mini"
  open={open}
  onCancel={this.handleCancel}
  onConfirm={() => this.handleConfirm()}
/>
{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >

<div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
All Images
</div>
</div>

<div
style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.props.showAddImage}>
<span className='icon-button border1' >Add Image</span >
</div>


</div>

{/**/}


  <br/>


            {this.props.imagePathContra !== undefined && this.props.imagePathContra.sort().map(this.renderList)}



{/**/}
              <br />


</div>
      </div>
    );
  }
}

export default ViewImageContra;
