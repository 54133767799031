import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { openModal } from "../../actions/modalAction";

import { withRouter } from "react-router-dom";

import {  Form, Button,  } from "semantic-ui-react";

import TextInput from "../form/TextInput";
// import TextArea from "../form/TextArea";
 import SelectInput from "../form/SelectInput";
// import DateInput from "../form/DateInput";
import { questions } from "./Data";
import {
  //composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({

    Q1:isRequired('Question 1') ,
    A1:isRequired('Answer 1') ,
    Q2:isRequired('Question 2') ,
    A2:isRequired('Answer 2') ,
    Q3:isRequired('Question 3') ,
    A3:isRequired('Answer 3') ,

});





  class SecurityQAEdit extends Component {
        
    onSubmit = async (values) => {
      const {Q1,Q2,Q3} = values
      //************
        try {
          if((Q1===Q2) || (Q1===Q3)) {
            toastr.error( "No Duplicate Question Allowed.");
        }else{
          const res = await axios.post(`/api/SecurityQACreateUpdate`, values);
  
          if(res.data.msg ==='success'){
            toastr.success('Updated Successfully')
          this.props.handleEditModalCloseSubmit()
            
           }
      }
          
          }catch (error) {
            
            if(error && error.response && error.response.status==401){
              this.props.openModal('LoginModal')
              toastr.error(`${JSON.stringify(error.response.data) }`);
            }else if(error){
              toastr.error("Oops", "Something went wrong.Please Try Again");
            }
        }
      //**********
    }
    
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  //*************** */
  render(){
    
    const { handleSubmit, pristine, reset, submitting } = this.props;

    const {Q1, Q2,Q3} =
      this.props.state.form &&
      this.props.state.form.SecurityQAEdit !== undefined &&
      this.props.state.form.SecurityQAEdit.values !== undefined &&
      this.props.state.form.SecurityQAEdit.values;

      
//************************* */

const renderDuplicateError = (a,b,c) => {
 if((a && a===b) || (a && a===c)) {
  return (
    <span
    style={{color:'red'}}
    >
    <b>You have already selected this question.</b>
    </span>
  );
  }
};








      //*************************** */
  return (

    <div style={{padding:'0px'}}>
<div style={{maxWidth:'700px',margin:'auto'}}>

      <br/>

      {/**/}
      <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Edit Security Questions / Answer 
       {/*  */}
       </div>
       </div>
       {/**/}
       <div className="card-form border">
          <div className=" label" >

          <Form style={{ padding: 15 }} onSubmit={handleSubmit(this.onSubmit)}>

          <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <Button
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>


            <label>Question 1</label>{' '}<span style={{color:'red'}}><b>*</b></span> {' '}{renderDuplicateError(Q1, Q2, Q3)}
            <Field
              search={true}
              name="Q1"
              type="text"
              component={SelectInput}
              options={questions()}
              placeholder="first question"
            />


            <label>Answer 1 </label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="A1"
              type="text"
              component={TextInput}
              placeholder="your answer of first question"
            />
            

            <label>Question 2</label>{' '}<span style={{color:'red'}}><b>*</b></span> {' '}{renderDuplicateError(Q2, Q1, Q3)}
            <Field
              search={true}
              name="Q2"
              type="text"
              component={SelectInput}
              options={questions()}
              placeholder="second question"
            />


            <label>Answer 2 </label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="A2"
              type="text"
              component={TextInput}
              placeholder="your answer of second question"
            />
            

            <label>Question 3</label>{' '}<span style={{color:'red'}}><b>*</b></span> {' '}{renderDuplicateError(Q3, Q1, Q2)}
            <Field
              search={true}
              name="Q3"
              type="text"
              component={SelectInput}
              options={questions()}
              placeholder="third question"
            />


            <label>Answer 3 </label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="A3"
              type="text"
              component={TextInput}
              placeholder="your answer of third question"
            />
            
             {/*  */}

             <Button
              color="olive"
              type="submit"
              disabled={pristine || submitting}
            >
              Submit
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          </Form>

              <br/> <br/> <br/>
            </div>
            </div>
            </div>
            {/* end 1st div */}
            </div>
  );
}
}
const mapStateToProps = (state,props) => {
  return {
    state,
    initialValues:props.val, 
  };
};
export default connect(
  mapStateToProps,
  { openModal}
)(
  reduxForm({
    form: "SecurityQAEdit",   // a unique identifier for this form
    destroyOnUnmount: false,
     validate
  })(withRouter(SecurityQAEdit))
);
