import React, { Component } from "react";
import { connect } from "react-redux";
import { Container  } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";


import AllFinance from "./AllFinance";

import Layout from "../layout/Layout";


class AllFinanceDashboard extends Component {
  // state = {
  //   openPop: false,
  // };
  componentDidMount() {
    window.scroll(0,0)
   
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  // //****************************** */
  // handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
  //   handleClosePop = () => this.setState({ openPop: false })
    //********************** */
  render() {
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



          <Layout onLogoutClick={this.onLogoutClick}>
          <div className='nodisplay' >
          <Container >
            <div className='margin-auto' >
              <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

              {/*  */}
           


           </div>
           </div>
           </Container>
           </div>
           {/*         */}
           <div className='nodisplay-tab' >
           <Container text >
             <div className='margin-auto' >
               <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

               {/*  */}
            


            </div>
            </div>
            </Container>
            </div>
            {/*         */}

          <Switch>

          <Route exact path="/allfin/AllFinance" component={AllFinance} />
                
          </Switch>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div className="mt-10">
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth
});


export default connect(
  mapStateToProps,
{showBackButton, getUserForAuth, logoutUser }
)(AllFinanceDashboard);
