import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";


import {fetchUser,updateUser} from "../../actions/authAction";
import { withRouter } from "react-router-dom";

import { 
  //Segment, 
  Form, Button,  
  // Header, 
  // Container 
} from "semantic-ui-react";
import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
import { residentTypeOption, locality,  } from "./Data";
import { projectName } from "./DataProjectName";
import {
  //composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {//isValidEmail,
  isValidPhone,
  //isValidPassword
} from "../validator/revalidator";

const validate = combineValidators({
//************user */

  uphone2:isValidPhone({}),
//  userTypeW:isRequired('Register As') ,
//  uname:isRequired("User's Name") ,

  //*********company */
  uwhatsapp:isValidPhone({}),
  //residentType: isRequired('Resident Type'),
  projectName : isRequired('Society Name'),
  address:isRequired('Address') ,
  locality:isRequired('Locality') ,
  //city:isRequired('City/District') ,
  //state:isRequired('State') ,
  pincode:isRequired('Pincode') ,
  shopName:isRequired("Shop's Name") ,

});



class UserEdit extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchUser(id);
  }

  onSubmit = values => {
    const { id } = this.props.match.params;
    this.props.updateUser(id, values, this.props.history);
    this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;
    const {userType} =
    this.props.state.form &&
    this.props.state.form.UserEdit !== undefined &&
    this.props.state.form.UserEdit.values !== undefined &&
    this.props.state.form.UserEdit.values;

    //************************ */
const renderShopNameField = ()=>{
  if(userType==='Shop Owner'){
    return (
        <>
        <label>Shop's Name</label><span style={{color:'red'}}><b>*</b></span>
        <Field
        name="shopName"
        type="text"
        component={TextInput}
        placeholder="Shop's Name"
        />
        </>
    )
  }
}


//************************ */
const renderAddressField = ()=>{

    return (
     < React.Fragment>

{userType!=='Shop Owner' &&  ( <><label >Complex/ Society Name</label><span className='color-mandatory'> * </span>
<Field
name="projectName"
placeholder="Enter Key word"
type="text"
options={projectName()}
search={true}
component={SelectInput}
/> </>)}

            <label>Address</label>{' '}<span style={{color:'red'}}><b>*</b></span>

            <Field
              name="address"
              type="text"
              component={TextInput}
              placeholder="Address"
            />
           
           <label htmlFor="">Locality</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              //search={true}
              component={SelectInput}
            />
                        
            <label>Pincode</label>{' '}<span style={{color:'red'}}><b>*</b></span>

            <Field
              name="pincode"
              type="text"
              component={TextInput}
              placeholder="Pincode"
            />



     </React.Fragment>
    )
  }

    //****** */
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>
      {/**/}
      <div className='margin-auto-H' >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
 {/*  */}
 Edit User
{/*  */}
</div>
</div>
{/**/}

        <div className="card border ">
          <div className=" label" >

            <Form
              style={{ padding: 25 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >

             <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>
          
                <label >User's Phone2</label>
                <Field
                  name="uphone2"
                  type="text"
                  component={TextInput}
                  placeholder="Phone2"
                />

                <label >User's Whatsapp ID</label>
                <Field
                  name="uwhatsapp"
                  type="text"
                  component={TextInput}
                  placeholder="Whatsapp ID"
                />
                { userType === 'Individual'  &&  (
            <>
            <label>Resident Type</label>{' '}
            <Field
            //search={true}
            name="residentType"
            type="text"
            component={SelectInput}
            options={residentTypeOption()}
            placeholder="Resident Type"
            />
            </>
            )
            }
            {renderShopNameField()}

            {renderAddressField()}
                
              <br />

              <Button
                color="olive"
                type="submit"
                disabled={pristine || submitting}
              >
                Submit
              </Button>
              <Button
                color="orange"
                type="button"
                disabled={pristine || submitting}
                onClick={reset}
              >
                Clear Values
              </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>
    );
  
  }
}

export default connect(
  state => {
    return {
      state,
       initialValues: state.auth.userD, // pull initial values from auth reducer
      userA: state.auth.userA 
    }; 
  },
  {fetchUser,updateUser}
)(
  reduxForm({
    form: "UserEdit", // a unique identifier for this form
    validate
  })(withRouter(UserEdit))
);
