import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import CookiesPolicy from '../landing/CookiesPolicy';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal };

class CookiesPolicyModal extends Component {
  render() {
    return (
      <Modal size="small"
      open={true}
      onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >

       <Modal.Content className='border'>
          <Modal.Description>
            <CookiesPolicy />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(CookiesPolicyModal);
