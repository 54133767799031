import {
  TRANSPORT,
  MESSAGE,
  RESET_STATE_COMMON,
} from '../actions/types';
import {renderMonth,} from "../components/functions/Functions";
import { getMonth, } from 'date-fns'

const INITIAL_STATE = {
  transporter:null,
  message:'',
  parameter:null,
  month:renderMonth(getMonth(new Date())),
  
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRANSPORT:

      return { ...state, ...action.payload };
      case MESSAGE:

          return { ...state, ...action.payload };
          // to reset the state to initial state
case RESET_STATE_COMMON:
  return INITIAL_STATE;
    default:
      return state;
  }
};
