import React, {Component} from 'react';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { openModal } from "../../actions/modalAction";
import About from './About'
import { Modal,
  //Image 
} from 'semantic-ui-react'


class HomeGuest extends Component {
  state = {
    modalOpenAbout: false,
  };
  
//******************
handleContactUsModal = () => {
  this.props.openModal("ContactUsModal");
};
handleOpenAbout = () => this.setState({ modalOpenAbout: true })
handleCloseAbout = () => this.setState({ modalOpenAbout: false })

//****************** */
handleLoginModal = () => {
  this.props.openModal('LoginModal');
};
//^^^^^^^^^^^^^^^
handlePrivacyPolicyModal = () => {
  this.props.openModal("PrivacyPolicyModal");
};
handleCookiesPolicyModal = () => {
  this.props.openModal("CookiesPolicyModal");
};
handleTermsAndConditionsModal = () => {
  this.props.openModal("TermsAndConditionsModal");
};
//*************** */
         
render() {

  const guestHome = (
    <div style={{marginTop:'50px'}}>
  
  
  {/* about modal */}
  <Modal
  
  open={this.state.modalOpenAbout}
  onClose={this.handleCloseAbout}
  //size='tiny'
  // size='small'
  >
  <Modal.Content style={{maxWidth:'1140px',padding:'0px'}}>
  
  <About 
  handleCloseAbout = {this.handleCloseAbout}
  />
  </Modal.Content>
  
  
  </Modal>
  {/*  */}
  
      <div id="mainP">
  {/* Header: Showcase */}
  
  <section id="showcase" className="masthead ">
    <div className="showcase-content" >
    <div >
      <h1 style={{fontSize:'250%',textAlign:'center',}} className="py-2 lineHeight">
        Manage your Sale | Purchase and Accounting of Daily Use Products as you Reinvent it
      </h1>
      </div>
      <div>
      <h3  style={{fontSize:'150%',color:'white',textAlign:'center',}} className="py-2">
      Digitalize And Ease your Sale | Purchase and Accounting of Daily Use Products 
      </h3>
      </div>
      {/* <div >
      <h3 style={{fontSize:'100%',color:'white',textAlign:'center',}}className="py-2">
        Optimize your all Personal and Professional Work and
        Finance operations from Task, Contact, Notes, Receivable,
        Payable, Cash A/C, Bank A/C, Sale, Purchase, Lending ,
        Borrowing, Location of Important items or Documents
        </h3>
        </div> */}
        <div className='disflex-center'>
  <div
  onClick={this.handleLoginModal}
  className="btnlogin pxy-5-10"
  >
  login
  </div>
  <div
  onClick={() => this.props.history.push('/register')}
  className="btnlogin pxy-5-10"
  >
  Register
  </div>
        </div>
        {/*  */}
        <div style={{marginTop:'20px'}}>
      <h3  style={{fontSize:'150%',color:'black',textAlign:'center',backgroundColor:'orange',padding:'10px 30px'}} >
      EKHATA.IN is FREE ! Register To Start Now
  
      </h3>
      </div>
        {/*  */}
  
    </div>
  
  </section>
  
  
{/* Section: What We Do */}
<section id="what" className="bg-light ">
<div className="containerP">
<h1 style={{marginTop:'20px'}}
className="font-h1"
>What</h1>


<div>
      <h3>EKHATA is a 
    Buyers-to-Sellers Sale | Purchase | Payment Account Management software as a service (SaaS) for
    Individuals and Shopkeepers. It Digitalize and Ease their Sale / Purchase and Accounting of Daily Use Products 
    and make their Buying / Selling process simple and smooth.
       </h3>
</div>

      <div>
        <h3>It will also eliminate any point of difference or
    dispute in sale, purchase, payment, receipt between
    buyer and seller.
        </h3>
        </div>

        

</div>
</section>


 <section id="whatT" >
  <div className="containerP">

  <div id="feature">
    <h1>~ Features ~</h1>
      </div>

  </div>
</section>

<section id="" style={{maxWidth:'700px',margin:'auto',padding:'25px',textAlign:'justify'}}>
 

    
      
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Easy to Use.  Enable users to digitally Record their 
                  Sale | Purchase | Payment transactions with ease.
                  </p>
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>
                  Sale, Purchase, Receipt and Payment entries are recorded
                  for Buyer and Seller in such a way that it  eliminate
                  any point of difference or dispute between Buyer and
                  Sellers
                  </p>
                
        
        <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>EKHATA app provides you one place 
        to record and review all your Sale | Purchase | Payment transactions.
         
</p>
    
<p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Remark writing 
                  facility. You can write details of the transactions 
                  for your reference.
                  </p>
      
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>It saves time.
No more going back and forth, spending time to manually tally 
your transactions as you have to do in traditional ledger. 
EKHATA app automatically handles this, saves  your precious 
time 

                  </p>
          
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Keep your contacts notified.
You can send transaction details through whatsapp message to 
other users when you record a transaction under their name. 
You can also send friendly reminders to request timely payments.

                  </p>
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Back everything up.
The app backs up your data automatically, so there is no risk 
of losing your data even if you lose your mobile device.


                  </p>
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Data Privacy. All the data entered in
                  ekhata app remains private and no one can access that
                  information.  Users need authorization and can access their
                  own data only.
                  </p>
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Data Security & Safety. 
                  We use end to end encryption to
                  secure data. We use databases replication to kept data safe.
                  Data is stored in different locations and regularly backed up
                  therefore your data is and will remain safe and secure in any
                  given circumstances.
                  </p>
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>Advance Searches. 
                  Powerful Search for finding what you are looking for.
                  Narrow down search results for complex searches by using
                  the Advanced Search.
                  </p>
                
        
                  <p className='font-mobile-18B mt-20 mb-20 color-black textAlign-justify' ><span className=' mr-10 border-left-orange '></span>With our 
                  mobile compatible app you can 
                  record and review all your Sale | Purchase | Payment transactions on your mobile. All the data is updated in real time and you
         can access them from your mobile device at anytime from 
         anywhere. 
                  
                  </p>
                
    
</section>
<section id="about" className="contact-bgc">
<div className="containerP">
  <h1 style={{marginTop:'20px'}}
  className="font-h1"
  >Who</h1>


<div>
<h3>EKHATA is a software as a service (SaaS)
developed, managed and owned by 
<span onClick={this.handleOpenAbout} className='btnnavCitsys ff-trebuchet'>
<span style={{color:'blue'}}>C</span>
<span style={{color:'orange'}}>IT</span>
<span style={{color:'blue'}}>SYS</span>
</span>

</h3>
</div>

<div>
<h3>

<span onClick={this.handleOpenAbout} className='btnnavCitsys ff-trebuchet'>
<span style={{color:'white'}}>C</span>
<span style={{color:'orangered'}}>IT</span>
<span style={{color:'white'}}>SYS</span>
</span>

is a technology company that designs,
develops, supports and sells IT Products and Services.

</h3>
</div>


</div>
</section>

  
  
  
  
  
  
  
  
  </div>
  
  
  {/* Footer */}
  <footer id="main-footer" className="bg-dark text-center py-1">
  <div className="containerP">
  <div className="row" style={{border:'none'}}>
  <div className="disflex-nav-footer" style={{ display: 'flex',flex: '50%',padding: 20,flexDirection:'column'}}>
  
  
  
    <span className = ' mb-05 '
    style={{display: 'flex',flexDirection:'row',alignItems:'center'}} 
    >
    <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"80px", height:"60px",borderRadius:'5px',verticalAlign:'middle'}} />
    
      {/* <Image size='mini' src='/logo_ekhata.png'  /> */}
      </span>
      <span className = '  '> 
      <img className= ' ' src='/logo_ekhata_3.png' alt=" "  style={{width:"120px", height:"30px",borderRadius:'5px',verticalAlign:'middle'}} />
      {/* <Image size='mini' src='/logo_ekhata.png'  /> */}
      </span>
    {/*  */}
    
  
  {/*  */}
  <p ><span id="contact"> Plot No. 32, Pocket 1, Sector 13, Dwarka, Delhi-78, INDIA. </span></p>
  
        <div className='font-16B'> 
        
        {/*  */}
        <p className='disflex-start'> 
  
        <img className= 'yimg ' src='/emailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle',marginRight:'10px'}} />
        info@citsys.in 
  
        </p>
        {/*  */}
        </div>
        {/*  */}
  </div>
  
  <div className="main " style={{ flex: '50%'}}>
  <nav id="navbarfoot" className="disflex-nav-footer">
  
  
  
  
      <ul className='disflex-nav-footer-ul'>
  
      <li onClick={this.handleOpenAbout}
      className='btnnavAbout'
  
      >About</li>
  
  
        <li>
        <a
        onClick={this.handlePrivacyPolicyModal}
        >Privacy Policy
        </a>
        </li>
  
        <li>
        <a
        onClick={this.handleCookiesPolicyModal}
        >Cookies Policy
        </a>
        </li>
  
        <li>
        <a
        onClick={this.handleTermsAndConditionsModal}
        >Terms & Conditions
        </a>
        </li>
  
      </ul>
      </nav>
  
      </div>
  
  
  
  </div>
  <p > © Copyright 2020 CITSYS, All Rights Reserved</p>
  <br/>
  </div>
  </footer>
  
  
  {/*  */}
  
  
  
  {/*  */}
    </div>
  );
  


  return(
    <div
    // Disable right click
  onContextMenu={e => e.preventDefault()}
    >
      {guestHome}
    </div>
  );
  
}
}


export default connect(
  null,
  {openModal,}
)(withRouter(HomeGuest));
