import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';


import ErrorReducer from './ErrorReducer';
//import SelectionReducer from './SelectionReducer';
import AuthReducer from './AuthReducer';
import ModalsReducer from './ModalReducer';
import PopupsReducer from './PopupReducer';
import FinanceReducer from './FinanceReducer';
import CommonReducer from './CommonReducer'; 

export default combineReducers({
  commons: CommonReducer,
  modals: ModalsReducer,
  popups: PopupsReducer,
  auth: AuthReducer,
  form: formReducer,

  errors: ErrorReducer,
  finances: FinanceReducer,
  toastr: toastrReducer
});
