import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { closeModal } from "../../actions/modalAction";
import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { CSVLink } from "react-csv";
import format from "date-fns/format";
import {  Form, Button,  } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
//import DateInput from "../form/DateInput";
import {
  //renderMonth,
  //renderPreviousMonth,
  monthOption
} from "../functions/Functions";
//import { getMonth,getYear } from 'date-fns'
import {
 // composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';


const validate = combineValidators({
  csvFileDownloadFor:isRequired('CSV File Download For') ,
  month:isRequired('Month') ,
});

const csvFileDownloadForO = [
  'AllFinance'
];
const csvFileDownloadFor = () => {
  return csvFileDownloadForO.map(value => ({
    key: value,
    text: value,
    value: value
  }));
};
class CSVDownload extends Component {
  state = {
    data:[],
    headers:[],
    filename:'',
    form:true,
    // monthNum:getMonth(new Date()),
    // year:getYear(new Date()),
    // monthWord:''
  }
  componentDidMount() {

    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  }
  backButtonHandler =()=>{
    this.props.history.goBack();
  }
  handleShowForm=()=>{
    this.setState({form:true})
  }
//********************
AllFinanceHandler=(val)=>{
  const  headers = [
      {label:"DATE" , key:"entryDate" },
      {label:"Entry Type" , key:"entryType" },
      {label:"A/C NAME" , key:"accountName" },
      {label:"DESCRIPTION" , key:"description" },
      // {label:"A/C Ref" , key:"accountRef" },
      {label:"AMOUNT INR" , key:"amount" }
    ];
  const Data = () => {
    return val.data.map(value => ({
      entryDate:format(new Date(value.entryDate), "dd-MM-yyyy") ,
      entryType: value.entryType,
      accountName: value.accountName,
      description: value.description,
      // accountRef: value.accountRef,
      amount: value.amount
    }));
  };

  this.setState({data:Data(),headers:headers,filename:val.filename,form:false})
}
// //********************
// sale_purchase_bookHandler=(val)=>{
//   const  headers = [
//       {label:"Date" , key:"entryDate" },
//       {label:"Entry Type" , key:"entryType" },
//       {label:"A/C Name" , key:"accountName" },
//       {label:"Description" , key:"description" },
//       // {label:"Bank A/C Name" , key:"bankAccountName" },
//       // {label:"Transaction Type" , key:"transactionType" },
//       {label:"Entry Ref" , key:"accountRef" },
//       {label:"Amount INR" , key:"amount" }
//     ];
//   const Data = () => {
//     return val.data.map(value => ({
//       entryDate:format(new Date(value.entryDate), "MMM d yyyy") ,
//       entryType: value.entryType,
//       accountName: value.accountName,
//       description: value.description,
//       accountRef: value.accountRef,
//       // bankAccountName: value.bankAccountName,
//       // transactionType: value.transactionType,
//       amount: value.amount
//     }));
//   };

//   this.setState({data:Data(),headers:headers,filename:val.filename,form:false})
// }

//********************

//###################
  onSubmit = async (values) => {

  //************
    try {

      const res = await axios.post(`/api/CSVDownload`, values);

      if((res.data.name ==='AllFinance')){
        this.AllFinanceHandler(res.data)
    }
    
      }catch (error) { 
      
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********
}



  render() {
    const { handleSubmit, pristine, reset, submitting } = this.props;

//******************
 const renderFormOrButton = () =>

  (this.state.form === true) ? (
    <div className="card-form ">
      <div className=" label" >

        <Form
          style={{ padding: 25 }}
          onSubmit={handleSubmit(this.onSubmit)}
        >


        <br /><br />



          <label >Download CSV File  Of</label>
          <Field
            name="csvFileDownloadFor"
            placeholder="Download CSV File Of"
            type="text"
            options={csvFileDownloadFor()}
            search={true}
            //multiple={true}
            component={SelectInput}
          />
          <label>Month</label>
            <Field
             //search={true}
             //multiple={true}
              name="month"
              type="text"
              component={SelectInput}
              options={ monthOption()}
              placeholder="Enter Key word"
            />


          <br />

          <Button
            color="blue"
            type="submit"
           // disabled={pristine || submitting}
          >
            Submit
          </Button>
          <Button
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <Button
          color="teal"
          type="button"
          onClick={this.backButtonHandler}

        >
          Cancel
        </Button>
        </Form>
        </div>
        </div>
  ) : (
    <div className='card'>
    <br/><br/>
    <div className='display-center'>
    <div
    style={{margin:'auto',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}>
     Your File Is Ready For Download</div>
     </div>
     <br/>
    <div className='display-center'>

  <div
  onClick={this.handleShowForm}
  >  <CSVLink
  className='btntask'
    filename={this.state.filename}
    data={this.state.data}
    headers={this.state.headers}>
    Download

    </CSVLink></div>
    <div
    onClick={this.handleShowForm}
    className='btntask'>

      Cancel
    </div>
    </div>
    <br/><br/><br/>
    </div>
  );
  //******************
    return (
      <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Download CSV File
          {/*  */}
          </div>
          </div>
          {/*form*/}
        {renderFormOrButton()}
            <br/><br/><br/>
            {/**/}

            {/**/}

            </div>
    );
  }
}

const mapStateToProps = ({ finances, auth, form }) => {

  const { fetching,limit, offsetC, count } = finances;

  return {

    fetching,
    limit,
    offset:offsetC,
    count,
    form_values: form.CSVDownload,
    userA: auth.userA,
    accountNames: finances.accountNames,
    accountRefs: finances.accountRefs,
    finances: finances.finances,
  };
};


export default connect(
  mapStateToProps,
  {...actions,closeModal}
)(
  reduxForm({
    form: "CSVDownload", // a unique identifier for this form
    //destroyOnUnmount: false,
    validate
  })(withRouter(CSVDownload))
);
