// const cityO = ['Dwarka', 'Rohini', 'Gurugram'];
// export const city = () => {
//   return cityO.map(value => ({
//     key: value,
//     text: value,
//     value: value
//   }));
// };

//for dwarka only
const cityO = ['Dwarka'];
export const city = () => {
  return cityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const userTypeO = ['Individual', 'Shop Owner'];
export const userTypeOption = () => {
  return userTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

const residentTypeO = ['Owner', 'Tenant'];
export const residentTypeOption = () => {
  return residentTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};

const stateO = ['Delhi', 'Haryana', 'U P'];
export const state = () => {
  return stateO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const typeOfPropO = [
  'Flats',
  'Builder Floor',
  'Residential Land /Plot',
  'Office Space',
    'Shop',
    'Space in Shopping Mall',
    'Showroom',
    'Commercial Land /Plot'
]
export const typeOfProp = () => {
  return typeOfPropO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
const businessTypeO = [
  'Manufacturer',
  'Distributor',
  'Exporter',
  'Importer',
  'Wholesaler',
  'Dealer',
  'Service Provider'
];
export const businessType = () => {
  return businessTypeO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
//###############
const localityO = [
  'Sector-01',
  'Sector-02',
  'Sector-03',
  'Sector-04',
  'Sector-05',
  'Sector-06',
  'Sector-07',
  'Sector-08',
  'Sector-09',
  'Sector-10',
  'Sector-11',
  'Sector-12',
  'Sector-13',
  'Sector-14',
  'Sector-15',
  'Sector-16',
  'Sector-17',
  'Sector-18A',
  'Sector-18B',
  'Sector-19A',
  'Sector-19B',
  'Sector-20',
  'Sector-21',
  'Sector-22',
  'Sector-23',
  'Sector-24',
  'Sector-25',
  'Sector-26',
  'Sector-27',
  'Sector-28',
  'Sector-29',
  'Sector-30'
];
export const locality = () => {
  return localityO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
