import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import TermsAndConditions from '../landing/TermsAndConditions';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal };

class TermsAndConditionsModal extends Component {
  render() {
    return (
      <Modal size="small"
      open={true}
      onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >

       <Modal.Content className='border'>
          <Modal.Description>
            <TermsAndConditions />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(TermsAndConditionsModal);
