import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import format from "date-fns/format";
import {fetchUser,resetUser} from "../../actions/authAction";
import { openModal,closeModal } from "../../actions/modalAction";

import {
  Divider,
  //Container,
  //Image,
  //Segment,
  Icon,
} from "semantic-ui-react";
import CopyPhoneNumber from "../common/CopyPhoneNumber";



class UserDetailForModal extends Component {
  state = {
    id: "",
    
  };

  componentDidMount() {
    const { id } = this.props;
    this.props.fetchUser(id);
  }

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  
  componentWillUnmount() {
      this.props.resetUser();
    }
  render() {

    if (!this.props.user) {
      return (
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
    const {
      uname,
      uimage,
      uemail,
      uphone1,
      uphone2,
      uwhatsapp,
      shopName,
      address,
      projectName,
      locality,
      city,
      state,
      pincode,
      residentType,
      // userSubscriptionStartOn,
      // userSubscriptionExpireOn,

      //_id
    } = this.props.user;

    
    return (
      <div>
      
     {/**/}
<div className='user-H'>

<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
>
      {/* <span className='icon-button border1' > <Icon name='search' /></span > */}
      <button

onClick={this.props.closeModal}

className='back-btn'
>
<Icon name="arrow left" />

</button>
    </div>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{uname}
</div>
</div>


</div>
{/**/}

        <div > 
        <div>
          {/*  */}
          <div className="disflex-paginator">
          <img 
              className="icon2-button "
              onClick={() => this.handleOpenModal(uimage)}
              src={`${uimage}`} alt="profile image" 
              style={{width:"100px", height:"100px",}} />
          
          </div>
{/* */}

          <br />
          <Divider fitted />
          <div>
          <div >
          {address && (
            <div className='row'>
              <div className='side'>
              {/* <Image
                floated="left"
                size="tiny"
                src={`${uimage}`}
              /> */}
              <b>Address</b>
              
              </div>
              <div className='main'>
               <div style={{color:'blue'}}><b>{shopName}</b></div>
                <b>{address}{' '}{projectName}{' '} {locality} {' '}{city}{' '}{state}{' '}{pincode}</b>
              </div>

            </div>
          )}
        </div>


        <div >
        {uphone1 && (
        <div className='row'>
          <div className='side'>
          <b>Phone1</b>
          </div>
          <div className='main'>
          <b>{uphone1}</b>
          {' '} <CopyPhoneNumber
            ph={uphone1}
            color={'dodgerblue'}
           // action1={'Cancell'}
            action2={'Ok'}
            dimmer={'inverted'}
            />
          </div>

        </div>
        )}
        </div>

        <div >
        {uphone2 && (
        <div className='row'>
          <div className='side'>
          <b>Phone2</b>
          </div>
          <div className='main'>
          <b>{uphone2}</b>
          {' '} <CopyPhoneNumber
            ph={uphone2}
            color={'teal'}
           // action1={'Cancell'}
            action2={'Ok'}
            dimmer={'inverted'}
            />
          </div>

        </div>
        )}
        </div>


        <div >
        {uemail && (
          <div className='row'>
            <div className='side'>
              <b>Email</b>
            </div>
            <div className='main'>
              <b>{uemail}</b>
            </div>

          </div>
        )}
      </div>


      <div >
      {uwhatsapp && (
      <div className='row'>
        <div className='side'>
        <b>Whatsapp Id</b>
        </div>
        <div className='main'>
        <b>{uwhatsapp}</b>
        {' '} <CopyPhoneNumber
            ph={uwhatsapp}
            color={'brown'}
           // action1={'Cancell'}
            action2={'Ok'}
            dimmer={'inverted'}
            />
        </div>

      </div>
      )}
      </div>

      <div >
        {this.props.userA.userType === 'Shop Owner' && residentType && (
          <div className='row'>
            <div className='side'>
              <b>Resident Type</b>
            </div>
            <div className='main'>
              <b>{residentType}</b>
            </div>

          </div>
        )}
      </div>



          </div>

          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = ({ auth }) => {

  return {
    user: auth.userD,
    userA:auth.userA
  };
};

export default connect(
  mapStateToProps,
  {fetchUser,resetUser, openModal,closeModal}
)(UserDetailForModal);
