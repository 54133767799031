import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import { openModal } from "../../actions/modalAction";
import { openPopup } from "../../actions/popupAction";
import {  Form, Button,  } from "semantic-ui-react";
import CheckboxInput_2 from "../form/CheckboxInput_2"
import SecurityQACreateForm from "./SecurityQACreateForm";


  class SecurityQACreate extends Component {
    state = {
      showP:false,
     password:'',
     passwordCheck:'',
     passwordForm :'YES'
    };
    // componentDidMount() {
      
    // }
    toggle = () => this.setState({ showP: !this.state.showP })
    clearPassword=() => {
      this.setState({password:''});
    }
    
    onSubmit = async () => {
      const values = {password:this.state.password}
      //************
        try {
        
          const res = await axios.post(`/api/PasswordCheckSQACreate`, values);
  
          if(res.data.msg ==='success'){
            this.setState({passwordCheck:'OK',
            passwordForm :'NO'})
        }
        if(res.data.msg ==='errorPass'){
          this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`You Have Submited Wrong Password.Please Try Again.You are left with ${res.data.attemptLeft} more attempts`});
          //this.setState({errorMessage:`You Have Submited Wrong Password.Please Try Again.You are left ${res.data.attemptLeft} more attempt`});
      }
      if(res.data.msg === 'errorAttempt'){
        this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Your Security Q & A Create Access is Locked.Please Contact Support`});
        //this.setState({errorMessage:`You Have Submited Wrong Password.Please Try Again.You are left ${res.data.attemptLeft} more attempt`});
    }
        
          }catch (error) {
            
            if(error && error.response && error.response.status==401){
              this.props.openModal('LoginModal')
              toastr.error(`${JSON.stringify(error.response.data) }`);
            }else if(error){
              toastr.error("Oops", "Something went wrong.Please Try Again");
            }
        }
      //**********
    }
    
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  //**********
  
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^
  PasswordCheckForm = () => {
    const showPass = this.state.showP ? "text" : "password"
    return (
  <>
  <div className="card-form border">
            <div className=" label" style={{padding:'20px'}} >

           <div className="font-20B color-dodgerblue">
Create Security Questions / Answers, which you will need to answer when resetting your password.
</div> <br /> 
            <div className="font-20B color-teal">
Enter and Submit Password to Create Security Questions / Answers.
</div> <br />
{/* <div className="disflex-center color-red">
  {this.state.errorMessage}
</div> */}
  <Form onSubmit={this.onSubmit}>
  <CheckboxInput_2 
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
<br/>
  <Form.Field >
  <label >Password<span className='color-mandatory'> * </span></label>
        <input
        className="input"
        type={showPass}
          placeholder="Enter Password"
          name="password"
          value={this.state.password}
          onChange={this.onChange}

        />
      </Form.Field>
      <Button 
      //inverted 
       color="blue" type="submit">
         Submit
      </Button>
      <Button
              
              color="orange"
              type="button"
              onClick={this.clearPassword}
            >
              Clear Values
            </Button>
      
  </Form>
      </div>
         </div>
  </>
    )
  }

  // ****

  //*************** */
  render(){
    
    

      //*************************** */
  return (

    <div style={{padding:'10px'}}>
<div style={{maxWidth:'700px',margin:'auto'}}>

{((this.props.userA !== null && this.props.userA.SQACreated === 'NO') && (this.state.passwordForm ==='YES') ) && this.PasswordCheckForm ()}

{this.state.passwordCheck === 'OK' && <SecurityQACreateForm/>}

      </div>
            </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
    userA: state.auth.userA
  };
};
export default connect(
  mapStateToProps,
  {openModal,openPopup}
)(withRouter(SecurityQACreate));
