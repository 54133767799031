import React, { Component } from "react";
import { connect } from "react-redux";

import axios from "axios";
import { toastr } from "react-redux-toastr";

import { fetchUsers, } from "../../actions/authAction";
import * as actions from "../../actions/financeAction";



class FinanceEditToAccept extends Component {
  state ={
   
  }
  componentDidMount() {
    const { id,month } = this.props;
   
    this.props.fetchFinanceForEditToAccept(id,month);
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))

  }

  componentWillUnmount(){
    this.props.resetFinance()
  }
  financeEditHandler = async (x) => {

  //************
    try {
      const { id } = this.props;

const idOfContraEntry = this.props.state.finances.finance.idOfContraEntry

      const values = {  checkedStatus:x ,idOfContraEntry, month:this.props.month };
      const res = await axios.put(`/api/FinanceEditToAccept/${id}`, values);

      if(res.data.msg ==='success'){
        toastr.success('Updated Successfully')
      this.props.handleFinanceEditToAcceptModalCloseSubmit()
    }
      }catch (error) {
     toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  //**********
}
  

  render() {
const checkedStatus =this.props.finance !== null && this.props.finance.checkedStatus
const entryType =this.props.finance !== null && this.props.finance.entryType



//^^^^^^^^^^^^^^^^
const renderButton =()=>{
  //+++++++++++++++
if((entryType === 'Receipt')){
  if(checkedStatus === 'Checked Ok'){
    return (
      <React.Fragment>
      {/* <div
      onClick={() => this.financeEditHandler('Unlocked')}
      className="btntask" style={{background:'#20B2AA'}} >
       Unlock Entry
      </div> */}
     <div
     onClick={this.props.handleFinanceEditToAcceptModalClose}
     className="btntask" >
     <b>Cancel</b>
     </div>
   </React.Fragment>

   )
 }else{
   return (
     <React.Fragment>
     <div
     onClick={() => this.financeEditHandler('Checked Ok')}
     className="btntask" style={{background:'#20B2AA'}} >
      Received
     </div>
    <div
    onClick={this.props.handleFinanceEditToAcceptModalClose}
    className="btntask" >
    <b>Cancel</b>
    </div>
  </React.Fragment>

  )
 }

}
//++++++++++++++++++
if(((entryType === 'Purchase')||(entryType === 'Purchase Return'))){

  if((checkedStatus === 'Checked Ok')){
    return (
      <React.Fragment>
      
     <div
     onClick={this.props.handleFinanceEditToAcceptModalClose}
     className="btntask" >
     <b>Cancel</b>
     </div>
   </React.Fragment>

   )
  }
  // if(checkedStatus === 'Unlocked'){
  //   return (
  //     <React.Fragment>
  //     <div
  //     onClick={() => this.financeEditHandler('Checked Ok')}
  //     className="btntask" style={{background:'#20B2AA'}} >
  //      Ok
  //     </div>
  //    <div
  //    onClick={this.props.handleFinanceEditToAcceptModalClose}
  //    className="btntask" >
  //    <b>Cancel</b>
  //    </div>
  //  </React.Fragment>

  //  )
  // }
//^^^^^^^^^^^^^^^^^^^^^^^^^
  if(checkedStatus === 'Not Checked'){
  return  (
    <React.Fragment>
    <div
    onClick={() => this.financeEditHandler('Checked Ok')}
    className="btntask" style={{background:'#20B2AA'}} >
     Ok
    </div>
    {/* <div
    onClick={() => this.financeEditHandler('Checked Not Ok')}
    className="btntask" style={{background:'#FA8072'}} >
     Not Ok
    </div> */}

   <div
   onClick={this.props.handleFinanceEditToAcceptModalClose}
   className="btntask" >

   <b>Cancel</b>

   </div>
   </React.Fragment>
 )
}
//^^^^^^^^^^^^^^
}

//++++++++++++++++++
}

//***************************
   
      return (
        <div className='smalldiv card' style={{backgroundColor:'white'}}>
                <div style={{padding:'15px'}}>
  {/**/}

  {/**/}
  <div className='margin-auto-H smalldiv border-radius-3'  >
  <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
  {/*  */}
  Acknowledge
  {/*  */}
  </div>
  </div>
  {/**/}

    <br/>
  {/**/}
  <div className='disflex-paginator'>
                {renderButton()}
                {/*  */}
             {/*<div className='display-center'>






           </div>*/}
             {/*  */}
  </div>
  {/**/}





  </div>
        </div>
      );
    }

    
}

const mapStateToProps = state => {

  return {
    state,
    userA: state.auth.userA,
    usersA: state.auth.usersA,
    
    finance: state.finances.finance,
    initialValues: state.finances.finance

  };
};

export default connect(
  mapStateToProps,
  {...actions,fetchUsers}
)(FinanceEditToAccept);
