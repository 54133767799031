import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import CashBookCashSearch from '../finances/CashBookCashSearch';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchCashBookCashModal extends Component {
  render() {
    return (
      <Modal size="small" 
      open={true} onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
      <Modal.Content className='border'>
          <Modal.Description>
            <CashBookCashSearch />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchCashBookCashModal);
