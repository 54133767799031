import React from 'react';
import axios from 'axios';
import {
  //Image,
  Segment,
  //Header,
  Confirm,
  //Button,
  Icon
} from 'semantic-ui-react';
import { toastr } from "react-redux-toastr";


class ViewPdfContra extends React.Component {
  state = {
      rotation: 0,
      path:'',
      open: false, 
  };


//**************************************
renderPdfDownloadButton = () =>{
  if (this.props.imagePathContra.length >0){

      return(
        <span>
          <a href={`${this.props.imagePathContra[0]}`}
target='_blank' rel="noopener noreferrer" className='nodisplay'
 >
            <span
//onClick={this.props.handleViewPdfModalClose}
            className="icon-button"
            >
            <img className= 'yimg ' src='/pdfDownloadIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
</a>
{/**/}
<a href={`${this.props.imagePathContra[0]}`} className='nodisplay-tab' >
            <span
          //  onClick={this.props.handleViewPdfModalClose}
            className="icon-button"
            >
            <img className= 'yimg ' src='/pdfDownloadIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
</a>
        </span>
      )
  }else{
    return (
      <span>
        No Pdf Available
        </span>

  )
  }
}
//^^^^^^^^^^
renderDeleteButton = () =>{
  if (this.props.entryFor==='Both'){
if (
   (this.props.checkedStatus ==='Checked Ok') 
  || ( (this.props.entryType=== 'Receipt (Dr)')||
        (this.props.entryType=== 'Purchase (Dr)')||
        (this.props.entryType==='Purchase Return (Cr)') )
  
  ){
      return(
        <span></span>
      )
  }else{
    return (
      <span
        onClick={() => this.show(this.props.imagePathContra[0])}
        className="icon-button"
        >
        <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

  )
  }
}
//^^^^^^^^^^
  if(this.props.entryFor==='Self'){
        return (
          <span
            onClick={() => this.show(this.props.imagePathContra[0])}
            className="icon-button"
            >
            <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
  
      )
      
    }
    //^^^^^^^^^^
  }
  
  //******************

show = (path) => {

  this.setState({ open: true,  path:path });
};
//**********************

  handleConfirm = async () => {
    const  value = {path:this.props.imagePathContra[0]}
    const imageURL_F=this.props.imagePathContra[0].substr(0, 23)

     try {

       //************ */
       if((this.props.entryFor === 'Self')){
        const resPdf = await axios.post(`${imageURL_F}/api/PdfDelete`, value);

        if(resPdf.data.msg ==='success'){ 
         //##
         if((this.props.entryType=== 'Receipt (Contra)')||(this.props.entryType==='Payment (Contra)')){
          const removeIndex = this.props.imagePathContra
          .map(item => item.toString())
          .indexOf(this.state.path);
        this.props.imagePathContra.splice(removeIndex, 1);
        
          const values = {
            id:this.props.id,
            idOfContraEntry:this.props.idOfContraEntry,
            imagePathContra:this.props.imagePathContra
          }
        
          const res= await axios.put(`/api/EditForImageContra`, values);
          if(res.data.msg ==='success'){
            this.props.handleRefresh()
            this.props.handleViewPdfModalClose()
            toastr.success('Pdf Deleted Successfully')
            }
        }else{
          const removeIndex = this.props.imagePathContra
          .map(item => item.toString())
          .indexOf(this.state.path);
        this.props.imagePathContra.splice(removeIndex, 1);
         const values = {
           id:this.props.id,
           imagePathContra:this.props.imagePathContra
         }
       
         const res= await axios.put(`/api/EditForImageSelf`, values);
         
       if(res.data.msg ==='success'){
       this.props.handleRefresh()
       this.props.handleViewPdfModalClose()
       toastr.success('Pdf Deleted Successfully')
       }
       //##
      }
          

      } // end bracket for second if statement
    } //entryFor Self statement end
      //************ */
      if((this.props.entryFor === 'Both')){
       const resCheck = await axios.get(`/api/FinanceDetail/${this.props.id}`);
 if(resCheck.data.checkedStatus==='Checked Ok'){
  toastr.error("Entry is Locked You Can't do this action");
       
   }else{
    const resPdf = await axios.post(`${imageURL_F}/api/PdfDelete`, value);

       if(resPdf.data.msg ==='success'){
         const removeIndex = this.props.imagePathContra
           .map(item => item.toString())
           .indexOf(this.state.path);
         this.props.imagePathContra.splice(removeIndex, 1);
         const values = {
           id:this.props.id,
           idOfContraEntry:this.props.idOfContraEntry,
           imagePathContra:this.props.imagePathContra
         }
       const res= await axios.put(`/api/EditForImageContra`, values);
       if(res.data.msg ==='success'){
       this.props.handleRefresh()
       this.props.handleViewPdfModalClose()
       toastr.success('Pdf Deleted Successfully')
       }
     }
   }

  } ////entryFor Both statement end
//try catch
    } catch (error) {
      
      toastr.error("Oops", "Something went wrong.Please Try Again_view pdf");
    }


  };

  handleCancel = () => this.setState({ result: "cancelled", open: false });

  
//**************************
        renderDownloadPdf=()=> {

              return (
                <div>
            {/**/}


            {/**/}
            <div className='disflex-paginator'>

            {this.renderPdfDownloadButton()} 
            {this.renderDeleteButton()}
            

            <span className='icon-button border2'
            onClick={this.props.handleViewPdfModalClose}
            style={{color:'blue'}}
            > <Icon name='close' />
            </span >



            </div>
            <br />



            {/**/}
                </div>
                )
              }

//**************
  render() {
const { open,} = this.state;
    return (
      <div className='main-div' >
              <div style={{padding:'15px'}}>
{/**/}
<Confirm
   header='You Want To Delete !'
  size="mini"
  open={open}
  onCancel={this.handleCancel}
  onConfirm={() => this.handleConfirm()}
/>
{/**/}
<Segment>
<div className='margin-auto-H smalldiv border-radius-3'  >

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Download Pdf
</div>
</div>




</div>

{/**/}


  <br/>

            {this.renderDownloadPdf()}

{/**/}
              <br />
</Segment>

</div>
      </div>
    );
  }
}

export default ViewPdfContra;
