import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {
  Image,
  //Segment,
  Header,
  Form,
  Button,
  Icon
} from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer';
import { toastr } from "react-redux-toastr";
import { fetchAppParameter } from "../../actions/commonAction";


 
class AddImageFirstTimeContra extends React.Component {
  state = {
    files: [],
    imageName: '',
    cropResult: null,
    image: {},
    imageURL: '',
  };
  componentDidMount() {
    if (this.props.parameter === null){
      this.props.fetchAppParameter()
        }
  }

  fileChangedHandler=(event)=> {
        var fileInput = false
        this.setState({files:event.target.files})
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
          Resizer.imageFileResizer(
                event.target.files[0],
                768,
                1280,
                'JPEG',
                90,
                0,
                uri => {

                    let imageUrl = URL.createObjectURL(uri);
                    this.setState({
                      cropResult: imageUrl,
                      image: uri
                    });

                },
                'blob'
            );
        }
    }

    handleChange = event => {
      this.setState({ imageName: event.target.value });
    };

  cancelImage = () => {
    this.setState({
      files: [],
      image: {},
      cropResult: null,
      cropResult_one: null,
    });
  };



  handleUploadImage = async ev => { 
    ev.preventDefault();
    const imageURL_F=this.props.parameter !== null && this.props.parameter.currentURL




    const data = new FormData();
    data.append('file', this.state.image);
    data.append('imageName', this.state.imageName);
    data.append('id', this.props.id);
    data.append('imageURL_F', imageURL_F);


     try {
       if(this.state.imageName){

        if(this.props.entryFor === 'Self'){
          const resImage = await axios.post(`${imageURL_F}/api/ImageAddContra`, data);
          //@@
          if(resImage.data.msg ==='success'){
            //##
            if(((this.props.entryType=== 'Receipt (Contra)')||(this.props.entryType==='Payment (Contra)')) ){
              this.props.imagePathContra.unshift(resImage.data.path)
              
              const values = {
                id:this.props.id,
                idOfContraEntry:this.props.idOfContraEntry,
                imagePathContra:this.props.imagePathContra
              }
            
              const res= await axios.put(`/api/EditForImageContra`, values);
              if(res.data.msg ==='success'){
                this.props.handleRefresh()
                this.props.handleAddViewImageModalClose()
                toastr.success('Image Added Successfully')
              }
            }else{
            this.props.imagePathContra.unshift(resImage.data.path)
         
           const values = {
             id:this.props.id,
             imagePathContra:this.props.imagePathContra
           }
         
           const res= await axios.put(`/api/EditForImageSelf`, values);
           if(res.data.msg ==='success'){
             this.props.handleRefresh()
             this.props.handleAddViewImageModalClose()
             toastr.success('Image Added Successfully')
           }
           //##
          }
          //@@
        }
}  //end Self
        //************ */ 
        if(this.props.entryFor === 'Both'){
       const resCheck = await axios.get(`/api/FinanceDetail/${this.props.id}`);
       

//  //@@@@
       if(resCheck.data.checkedStatus ==='Checked Ok'){
  toastr.error("Entry is Locked You Can't do this action");
          
}else{
  const resImage = await axios.post(`${imageURL_F}/api/ImageAddContra`, data);


if(resImage.data.msg ==='success'){
   this.props.imagePathContra.unshift(resImage.data.path)

  const values = {
    id:this.props.id,
    idOfContraEntry:this.props.idOfContraEntry,
    imagePathContra:this.props.imagePathContra
  }

  const res= await axios.put(`/api/EditForImageContra`, values);
  if(res.data.msg ==='success'){
    this.props.handleRefresh()
    this.props.handleAddViewImageModalClose()
    toastr.success('Image Added Successfully')
  }
} // end bracket for second if statement
} //@@@@
       
       // }
        } //end Both
//************** */
}else{
  toastr.error("Enter Page No.");
}// end bracket for first if statement
    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }

  };
//%%%%%%%%%%%%%%%%%%%%%


  render() {

const renderAddImageButton =()=>{
  if(this.state.files.length <1){
    return(
      <React.Fragment>
      <div>
<label size="tiny" for="hidden-new-file" class="ui icon button">

<span className="icon-size">
<Icon  name="plus circle"/>
</span><span className="icon-size">Add Image</span>

</label>
<input
type="file" id="hidden-new-file" accept="image/*"
style={{display: 'none'}}
onChange={this.fileChangedHandler}
/>
</div>
    </React.Fragment>
    )
  }
}
//*******************
const renderPreview =()=>{
  if(this.state.files.length >0){
    return(
      <React.Fragment>
      <div
      //style={{padding:'15px'}}
      >
      <br/>
      <Header  color="teal" content="Preview and Upload" />
        <br/>
        <Image
        src={this.state.cropResult}/>

        </div>


        <br /><br />
  <div className='disflex-paginator'>
        <Button.Group>
          <Button
            //loading={loading}
            onClick={this.handleUploadImage}
            // style={{ width: '120px' }}
            positive
            icon="check"
            content="upload"
          />
          <Button
            size='mini'
            onClick={this.cancelImage}
            // style={{ width: '100px' }}
            icon="close"
            content="cancell"
          />
        </Button.Group>

    </div>
    </React.Fragment>
    )
  }
}
    return (
      <div className='main-div' style={{backgroundColor:'white'}}>
              <div style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >

<div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Add Image
</div>
</div>

<div
style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.props.showAddPdf}>
<span className='icon-button border1' >Add Pdf</span >
</div>


</div>

{/**/}
  <br/>
  {/**/}
  <br/>
  <div className='disflex-paginator'>
    <Form onSubmit={this.handleSubmit}>
      {/*<Header color="teal" sub content="Select Page No." />*/}

      <Form.Field >
      <label>Select Image No. </label>
      <select
        name="imageName"
        value={this.state.imageName}
        onChange={this.handleChange}
      >
      <option value="">Select Image No.  </option>
      <option value="image01">image01 </option>
      <option value="image02">image02</option>
      <option value="image03">image03</option>
      <option value="image04">image04</option>
      <option value="image05">image05</option>
      </select>
      </Form.Field>

    </Form>
    </div>
    <br />
{/**/}
<div className='disflex-paginator'>
              {/*<div>
  <label size="tiny" for="hidden-new-file" class="ui icon button">

    <span className="icon-size">
    <Icon  name="plus circle"/>
</span><span className="icon-size">Add Image</span>

  </label>
  <input
  type="file" id="hidden-new-file"
  style={{display: 'none'}}
  onChange={this.fileChangedHandler}
  />
</div>*/}
{renderAddImageButton()}
<Button
color="teal"
type="button"
onClick={this.props.handleAddViewImageModalClose}
>
Close
</Button>
</div>
{/**/}

{renderPreview()}
              {/*<div
              //style={{padding:'15px'}}
              >
              <br/>
              <Header  color="teal" content="Preview and Upload" />
                <br/>
                <Image
                src={this.state.cropResult}/>

                </div>


                <br /><br />
          <div className='disflex-paginator'>
                <Button.Group>
                  <Button
                    //loading={loading}
                    onClick={this.handleUploadImage}
                    // style={{ width: '120px' }}
                    positive
                    icon="check"
                    content="upload"
                  />
                  <Button
                    size='mini'
                    onClick={this.cancelImage}
                    // style={{ width: '100px' }}
                    icon="close"
                    content="cancell"
                  />
                </Button.Group>

            </div>*/}


</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
  };
};

export default connect(
  mapStateToProps,
{  fetchAppParameter}
)(AddImageFirstTimeContra);
