
import { toastr } from "react-redux-toastr";
import {reset, } from "redux-form";
import axios from 'axios';
import {  openModal } from "./modalAction";

import {
  TRANSPORT
} from "./types";



export const transport = ( transporter) => async dispatch => {

  try {

    dispatch({ type: TRANSPORT, payload: {transporter:transporter} });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
export const setMonth = ( transporter) => async dispatch => {

  try {

    dispatch({ type: TRANSPORT, payload: transporter });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
export const setInOut = ( transporter) => async dispatch => {

  try {

    dispatch({ type: TRANSPORT, payload: transporter });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

export const resetForm = ( value) => async dispatch => {

  try {
    dispatch(reset(value)); //value is the name of form

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//fetch AppParameter
export const fetchAppParameter = () => async dispatch => {
  try {
    const res = await axios.get(`/api/AppParameter`);
    dispatch({ type: TRANSPORT, payload: res.data });
    

  } catch (error) {
    if(error && error.response && error.response.status==401){
      dispatch(openModal('LoginModal')); 
      toastr.error(`${JSON.stringify(error.response.data) }`);
      }else if(error){
      toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
};

