import without from "lodash/without";
import {
  //finance types

  FETCH_FINANCE,
  FETCH_FINANCES,
  SEARCH_FINANCES,
  DESELECT_FINANCE,
  SELECT_FINANCE,
  SELECT_FINANCEALL,
  DESELECT_FINANCEALL,
  RESET_SELECTION,
  FETCHING_FINANCES,
  // accountName types
  
  FETCH_ACCOUNTNAMES,
  FETCH_ACCOUNTNAME,
  // accountRef types
  
  FETCH_ACCOUNTREFS,
  FETCH_ACCOUNTREF,

  RESET_STATE_FINANCE,


} from '../actions/types';

const INITIAL_STATE = {
  fetching:true,
  noEntry: false,
  //sum: [],
  cashBooks: [],
  finance: null,
  saleBooks: [],
  purchaseBooks: [],
  allFinances: [],
  allFinancesSA: [],
  // accountNames: [],
  // accountName: null,
  // accountRefs: [],
  // accountRef: null,
  selections: [],
  offset: 0,
  offsetC: 0,
  offsetSale: 0,
  offsetPurchase: 0,
  offsetAll: 0,
  offsetAllSA: 0,
  limit:10
};

export default (state = INITIAL_STATE, action) => {
  const removeDup = (data)=> [...new Set(data)]
  switch (action.type) {
    //finance types
    case FETCH_FINANCES:

      return { ...state, ...action.payload };

    case FETCH_FINANCE:
      return { ...state, finance:action.payload };

    case SEARCH_FINANCES:
//const data={ ...state,allFinances:action.payload.allFinances, ...action.payload };

      return { ...state, ...action.payload }
      //#####################
      case SELECT_FINANCE:

        return { ...state, selections: [...state.selections, action.payload] };

        case SELECT_FINANCEALL:
        //const removeDup = (data)=> [... new Set(data)]
          return { ...state, selections: removeDup( [...state.selections, ...action.payload]) };

          case DESELECT_FINANCEALL:
          //const removeDup = (data)=> [... new Set(data)]
            return { ...state, selections: without(state.selections, ...removeDup(action.payload)) };

      case DESELECT_FINANCE:
        return {
          ...state,
          selections: without(state.selections, action.payload)
        };

      case RESET_SELECTION:

        return { ...state, selections: [] };
        // case FETCHING_FINANCES:

        //   return { ...state, fetching: action.payload };

        case FETCHING_FINANCES:

          return { ...state, ...action.payload };

    // accountName types
    case FETCH_ACCOUNTNAMES:
      return { ...state, accountNames: [...action.payload] };
    case FETCH_ACCOUNTNAME:
      return { ...state, accountName: action.payload };
    
   
      // accountRef types
      case FETCH_ACCOUNTREFS:
        return { ...state, accountRefs: [...action.payload] };
      case FETCH_ACCOUNTREF:
        return { ...state, accountRef: action.payload };
      // to reset the state to initial state
      case RESET_STATE_FINANCE:
      return INITIAL_STATE;
      
          default:
            return state;
  }
};
