import React from 'react';
import { Link} from 'react-router-dom';
const LinkItem_1 = (props) => {

  return (
    <div className='nodisplay-at-mobile'>
<div className="hexagon-item">
<div className="hex-item">
<div></div>
<div></div>
<div></div>
</div>
<div className="hex-item">
<div></div>
<div></div>
<div></div>
</div>
<Link to={props.url} className="hex-content" >
<span className="hex-content-inner">

<span className="title color-aqua">{props.title}</span>
</span>
<svg className="gradient-icon" viewBox="0 0 173.20508075688772 200" height="200" width="174" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z" fill="url(#link1)"></path>

<defs>
<linearGradient id="link1">
<stop offset="5%" stopColor="#FDABDD" />
<stop offset="95%" stopColor="#374A5A" />
</linearGradient>

</defs>
</svg>

</Link>

</div>
    </div>
  );
};

export default LinkItem_1;
