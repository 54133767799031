import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { closeModal } from "../../actions/modalAction";
//import { setMonth} from "../../actions/commonAction";

import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";
import { fetchMyGroupUsers, } from "../../actions/authAction";
import {  Form, Button,  } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
//import DateInput from "../form/DateInput_2";
import {renderMonth,
  //renderPreviousMonth,
  monthOption
} from "../functions/Functions";
import { getMonth,getYear } from 'date-fns'

class SingleAccountFinanceSearch extends Component {
  state = {
    
    monthNum:getMonth(new Date()),
    year:getYear(new Date()),
    monthWord:''
 };
  componentDidMount() {
    if (!this.props.state.auth.usersG.length){
      this.props.fetchMyGroupUsers()
        }
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  }

  handleCancel = () => {
    this.props.closeModal()
    this.props.reset()
  };

  onSubmit = values => {
    const {
      limit
    } = this.props;
    const renderEntryType = () => {
      if(this.props.renderType === 'Payment'){
        return {entryTypeA: ["Payment", 'Sale','Purchase Return']}
      }
      if(this.props.renderType === "Receipt"){
        return {entryTypeA: ["Receipt", 'Purchase','Sale Return'] }
      }
    }
    
    const values_F = { month:renderMonth(getMonth(new Date())), ...values ,accountId:this.props.accountId,  ...renderEntryType()  };
    this.props.searchAllFinanceSA(values_F,limit);

    const valuesT = {month:renderMonth(getMonth(new Date())), ...values  , accountId:this.props.accountId, ...renderEntryType()  ,search: 'total'  };
    this.props.searchAllFinanceSA(valuesT,limit);

    const valuesTGT = { month:renderMonth(getMonth(new Date())), ...values , accountId:this.props.accountId,  ...renderEntryType()  ,search: 'totalGT'  };
    this.props.searchAllFinanceSA(valuesTGT,limit);
    
  };


  render() {
    
    
    const { handleSubmit, pristine, reset, submitting } = this.props;

    

    //****************************
    return (
      <div className='marginBottom' >
      <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search Single Account Finance
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card-form ">
          <div className=" label" >

            <Form
              onSubmit={handleSubmit(this.onSubmit)}
            >

            <Button
            color="teal"
            type="button"
            onClick={this.handleCancel}

          >
            Cancel
          </Button>
        <Button
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button>
        <Button
          color="blue"
          type="submit"
         // disabled={pristine || submitting}
        >
          Search
        </Button>
            <br /><br />


            <label>Month</label>
            <Field
             //search={true}
             //multiple={true}
              name="month"
              type="text"
              component={SelectInput}
              options={ monthOption()}
              placeholder="Select Month"
            />


              <br />

              <Button
                color="blue"
                type="submit"
               // disabled={pristine || submitting}
              >
                Search
              </Button>
        <Button
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button>
        <Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
    );
  }
}

const mapStateToProps = (state) => {

  const { fetching,limit, offsetC, count } = state.finances;

  return {
    state,
    fetching,
    limit,
    offset:offsetC,
    count,
    form_values: state.form.SingleAccountFinanceSearch,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    finances: state.finances.finances,
    
  };
};


export default connect(
  mapStateToProps,
  {...actions,closeModal,fetchMyGroupUsers,}
)(
  reduxForm({
    form: "SingleAccountFinanceSearch", // a unique identifier for this form
    destroyOnUnmount: false,
    // validate
  })(withRouter(SingleAccountFinanceSearch))
);
