import React, { Component } from "react";
import { connect } from "react-redux";
//import { Popup,Icon,Container } from "semantic-ui-react";
import { Switch, Route, } from "react-router-dom";
import {showBackButton,  } from "../../actions/authAction";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import PassChange from "./PassChange";
// import PassReset from "./PassReset";
// import FormPassReset from "./ReqForOTP";
import SecurityQACreate from "./SecurityQACreate";
import ViewSecurityQA from "./ViewSecurityQA";

import Layout from "../layout/Layout";

class AuthDashboard extends Component { 
  state = {
    openPop: false,
  };
  componentDidMount() {
   
    if(this.props.history.location.pathname !=='/'){
      this.props.showBackButton()
  }
    }
  
  render() {
      const { isAuthenticated,  } = this.props.auth;

      const authLinks = (
        


    <div>
    <div className='maindiv'>



          <Layout onLogoutClick={this.onLogoutClick}>


        

            <Switch>
            <Route exact path="/auth/passchange" component={PassChange} />
            
            <Route exact path="/auth/SecurityQACreate" component={SecurityQACreate} />
          <Route exact path="/auth/ViewSecurityQA" component={ViewSecurityQA} />

            
            </Switch>

            </Layout >
            </div>

<div >
    <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
</div>

</div>
        
      );

      // ************
      
    return (
      <div className="mt-10">
        {isAuthenticated ? (
          authLinks
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth
});
export default connect(
  mapStateToProps,
  { showBackButton, }
)(AuthDashboard);

