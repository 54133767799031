
import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { openPopup } from "../../actions/popupAction";
// import { Modal, } from 'semantic-ui-react';
import { connect } from "react-redux";

const CopyPhoneNumber = (props) => {

  const handleOpenPopup = (val) => {
    const message= `${val.ph} Number Copied` 
    props.openPopup("CopyPhoneFixedPopup" , {...val,message});
  };

  return (
   
    <span className='nodisplay-tab'>
    <CopyToClipboard text={props.ph}  >
    <span className="icon-button"  onClick= {() => handleOpenPopup({ph:props.ph,color:props.color,action1:props.action1,action2:props.action2,dimmer:props.dimmer} )}>
 <img className= 'yimg ' src='/copyIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
 </span>
   </CopyToClipboard>
   </span>  
    
  );
}


// function mapStateToProps(state,) {
 
//   return { userA: state.auth.userA };
// }

export default connect(
  null,
  {openPopup}
)(CopyPhoneNumber);

// export default CopyPhoneNumber;
