import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { closeModal } from "../../actions/modalAction";
//import { setMonth} from "../../actions/commonAction";

import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";
import { fetchMyGroupUsers, } from "../../actions/authAction";
import {  Form, Button,  } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
//import DateInput from "../form/DateInput_2";
import {renderMonth,
  //renderPreviousMonth,
  monthOption
} from "../functions/Functions";
import { getMonth,getYear } from 'date-fns'


class PurchaseBookSearch extends Component {
  state = {
    
    monthNum:getMonth(new Date()),
    year:getYear(new Date()),
    monthWord:''
 };
  componentDidMount() {
    if (!this.props.state.auth.usersG.length){
      this.props.fetchMyGroupUsers()
        }
    const datePickers = document.getElementsByClassName("react-datepicker__input-container");
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  }

  onSubmit = values => {   
    const {
      limit
    } = this.props;
    const values_F = { entryType: 'Purchase',  month:renderMonth(getMonth(new Date())), ...values    };
  const valuesTGT = {entryType: 'Purchase', month:renderMonth(getMonth(new Date())), ...values  ,search: 'totalGT'};
   
  this.props.searchPurchaseBook(values_F,limit);

    this.props.searchPurchaseBook(valuesTGT,limit);
  };


  render() {
    
    
    const { handleSubmit, pristine, reset, submitting } = this.props;

    const userType = this.props.userA !== null && this.props.userA.userType
    const accountId = () => {
      return this.props.usersG.map(value => ({
        key: value._id,
        text: `${userType==='Shop Owner'? value.uname: value.shopName} ( ${value.address} ${userType==='Shop Owner'? '#'.concat('',value.userId):''})` ,
        value: value._id,style:{fontSize:'18px'}
      }));
    };
    //*************************
    return (
      <div className='marginBottom' >
      <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Search PurchaseBook
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card-form ">
          <div className=" label" >

            <Form
              onSubmit={handleSubmit(this.onSubmit)}
            >

<Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
        <Button
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button>
        <Button
                color="blue"
                type="submit"
               // disabled={pristine || submitting}
              >
                Search
              </Button>
            <br /><br />


            <label>Account Name</label>
            <Field
             search={true}
             multiple={true}
              name="accountIdA"
              type="text"
              component={SelectInput}
              options={accountId()}
              placeholder="Select Account"
            />
                <label>Month</label>
            <Field
             //search={true}
             //multiple={true}
              name="month"
              type="text"
              component={SelectInput}
              options={ monthOption()}
              placeholder="Select Month"
            />


              <br />

              <Button
                color="blue"
                type="submit"
               // disabled={pristine || submitting}
              >
                Search
              </Button>
        <Button
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button>
        <Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
            </Form>
            <br/><br/><br/>
            </div>
            </div>
            </div>

            
</div>
    );
  }
}

const mapStateToProps = (state) => {

  const { fetching,limit, offsetC, count } = state.finances;

  return {
    state,
    fetching,
    limit,
    offset:offsetC,
    count,
    form_values: state.form.PurchaseBookSearch,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    finances: state.finances.finances,
  };
};


export default connect(
  mapStateToProps,
  {...actions,closeModal,fetchMyGroupUsers,}
)(
  reduxForm({
    form: "PurchaseBookSearch", // a unique identifier for this form
    destroyOnUnmount: false,
    // validate
  })(withRouter(PurchaseBookSearch))
);
