import React from 'react';

import {
  //Icon,
  Button,
} from 'semantic-ui-react';

var ps = {

  backgroundColor: 'aqua',
  // width: '120px',
  // height: '30px',
  position: 'fixed',
  top: '50px',
  right: '10px',
};


class ScrollBottomButton extends React.Component {
  constructor() {
    super();

    this.state = {
        intervalId: 0
    };
  }

  scrollStep = ()=> {
    if (window.pageYOffset === 0) {
        clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset + this.props.scrollStepInPx);
  }

  // scrollToBottom =()=> {
  //   let intervalId = setInterval(this.scrollStep(), this.props.delayInMs);
  //   this.setState({ intervalId: intervalId });
  // }

  scrollToBottom =()=> {
    window.scroll(0,100000)
  }

  render () {
      return (
        <Button
          circular
          icon='chevron down'
          onClick={this.scrollToBottom}
          style={ps}
        />


      );

   }
}

export default ScrollBottomButton;
