import React, { Component } from 'react';
import { Link,withRouter } from 'react-router-dom';
import { reduxForm, Field } from "redux-form";
import { connect } from 'react-redux';
import { loginUser } from '../../actions/authAction';
import { closeModal } from "../../actions/modalAction";
import {
  Button,
  Form,
  //Image,
} from 'semantic-ui-react';

import TextInput from "../form/TextInput";
//import SelectInput from "../form/SelectInput";
import CheckboxInput_2 from "../form/CheckboxInput_2"
import {
  combineValidators,
  isRequired,
} from 'revalidate';

const validate = combineValidators({
//************user */

  cid:isRequired('Company ID') ,
  userId:isRequired('UserId') ,
  password:isRequired('Password') ,
  
});

class Login extends Component {
  state = {
    showP:false,
  }

   
  onSubmit = values => {
    this.props.loginUser(values,this.props.history);
  };
  

  toggle = () => this.setState({ showP: !this.state.showP })
  render() {
  
    const { handleSubmit} = this.props;
    const showPass = this.state.showP ? "text" : "password"
    // const { userTypeW} =
    //   this.props.state.form &&
    //   this.props.state.form.Login !== undefined &&
    //   this.props.state.form.Login.values !== undefined &&
    //   this.props.state.form.Login.values;

      
    return (
      <div className='border borderwidth-5'
      style={{borderWidth:'5px'}}
      >
    
      <div  className="card-attached " style={{maxWidth:'500px',margin:'auto'}}>
            {/*  */}  
        
<div className='disflex-paginator' >
<div className = '  nodisplay372' style={{ marginRight: '.5em',backgroundColor:'',padding:'2px' }} >
        <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"60px", height:"39px",borderRadius:'5px',verticalAlign:'middle',marginRight: '.50em',}} />
        <img className= ' ' src='/logo_ekhata_3.png' alt=" "  style={{width:"120px", height:"30px",borderRadius:'5px',verticalAlign:'middle'}} />
        </div>

</div>


{/*  */}
<div className='masthead  font-18B textAlign-center pxy-10 color-white'>  
Login to your account
</div>
<br/>
{/*  */}
            <div className="card-attached ">
            <div className=" label pxy-10" >
          
            <Form  onSubmit={handleSubmit(this.onSubmit)}>
            
            <label >User Id</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="User Id"
            />
              
              <label >Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Password"
            />
            
            <CheckboxInput_2 
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
              <br/><br/>
              <div className='disflex-paginator' >
              <Button 
              color="teal" 
              //type="button"
               >
                Login
              </Button>
              <Button
            color="blue"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
              </div>
              
            </Form>
          </div>

          <div 
          className='format-login2 flex-direction-column border-db-top '>
          <div > <span> New to us?</span> {' '}
          <span>
          <Button
              //floated="right"
              color="teal"
              type="button"
              as={Link}
              to={`/register`}
              onClick={()=>this.props.closeModal()}
            >
              Register
            </Button>
            </span>
          
            </div>
          <div >Forgot Your Password?{' '}
          <Button
              //floated="right"
              color="blue"
              type="button"
              as={Link}
              to={`/formpassreset`}
              onClick={()=>this.props.closeModal()}
            >
              Reset
            </Button>
         
          </div>
          </div>




      </div>
      
      </div>

      </div>
    );
  }
}



const mapStateToProps = state => ({
  state,
});

export default connect(
  mapStateToProps,
  { loginUser, closeModal }
)(
  reduxForm({
    form: "Login",   // a unique identifier for this form
    //destroyOnUnmount: false,
     validate
  })(withRouter(Login))
  
  );
