import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { searchUsers ,addRemoveToMyGroup} from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";
import {
  Header,
  Container,
  Divider,
  Segment,
  //Icon,
  Button,
  Form,
  Input,
  Image,
  //Modal,
} from "semantic-ui-react";
import {
  renderMonth,
} from "../functions/Functions";
import { getMonth } from 'date-fns'



class UserSearchList extends Component {
  state = {
    userId:'',
    uphone1: '',
  };
clearValues =()=>{
    this.setState({ userId:'', uphone1: '', });
}
  myGroupAddRemoveHandler = (id) => {
    const month = renderMonth(getMonth(new Date()))
      this.props.addRemoveToMyGroup(id, month);
  };
  //*****************************

  onSubmit = () => {
    const values = this.state;
    this.props.searchUsers(values);
    this.clearValues()
  };

  handleOpenModal = (url) => {   
    this.props.openModal("ImageZoomModal" , {imageURL:url});
  };
  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
//*******************************************************
  renderList=(user)=> {
    const {
      uname,
      uimage,
      // address,
      // city,
      // state,
      // pincode,

      _id
    } = user;



    const renderButton = () =>
      user._id !== (this.props.userA !== undefined && this.props.userA !== null && this.props.userA._id) ? (
        <div>

        { renderMyGroupButton()}
        
        <span
        className="icon-button"
        onClick={()=>this.props.openModal('UserDetailModal',{id:_id})}
        >
        <img className= 'yimg ' src='/detailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
          </div>


      ) : (
        <div></div>
      );

      const checkID = this.props.userA !== undefined && this.props.userA !== null && this.props.userA.mygroup !==undefined && this.props.userA.mygroup.filter(val => {

        return val.toString() === _id;
      });
      const renderMyGroupButton = () =>
            checkID.length > 0 ? (
              <span
              className="icon-button"
              onClick={() => this.myGroupAddRemoveHandler(_id)}
              >
              <img className= 'yimg ' src='/removeGroupIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>

        ) : (
          <span
          className="icon-button"
          onClick={() => this.myGroupAddRemoveHandler(_id)}
          >
          <img className= 'yimg ' src='/addGroupIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>

        );

// @@
    //******************
    return (
      <Segment key={_id}>
          <div>
          <div >

          <Header color='blue'>{uname}</Header>
          {/**/}

          {/**/}


            </div>

{/**/}
<Divider fitted />
<div >
{uname && (
  <div className='row' style={{alignItems: 'center'}}>
    <div className='side'>

    
    <Image
    className="icon2-button "
    onClick={() => this.handleOpenModal(uimage)}
    style={{maxWidth:"50px", maxHeight:"50px",}}
      floated="left"
      size="tiny"
      src={`${uimage}`}
    />
    



    </div>
    <div className='main' >
    <div className='display-center'>
      { renderButton()}
    </div>

    </div>

  </div>
)}
</div>
{/**/}
          </div>

      </Segment>
    );
  }

  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {


    //******************


    return (
      <Container text>

<div className='cashbook-H'>

<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Search User To Add To Your Group
</div>
</div>



</div>
{/**/}
<Segment attached>
<div>
  <Form onSubmit={this.onSubmit}>

  
    {/*  */}
    <div >

      <Form.Field >
        
        <label>User ID.</label>
        <Input
          type="text"
          placeholder="User ID."
          name="userId"
          value={this.state.userId}
          onChange={this.onChange}
        />
      </Form.Field>


      <Button.Group>
      <Button style={{marginRight:'5px'}}  color="blue" type="submit">
        Search
      </Button>
      <Button   color="teal" onClick={this.clearValues}>
        Clear Values
      </Button>
    </Button.Group>
    </div>
  </Form>

  </div>
</Segment>

        {this.props.users.map(this.renderList)}



<br/><br/><br/>

      </Container>
    );
  
  }
}

const mapStateToProps = state => {

  return {
    users: state.auth.usersA,
    userA: state.auth.userA,
    user: state.auth.user,
  };
};
export default connect(
  mapStateToProps,
  {  searchUsers ,addRemoveToMyGroup,openModal}
)(UserSearchList);
