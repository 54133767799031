import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import UserDetailForModal from '../companys/UserDetailForModal';
import { closeModal } from '../../actions/modalAction';

const actions = { closeModal }; 

class UserDetailModal extends Component {
  render() {
    return (
      <Modal size="small"   
      open={true} onClose={this.props.closeModal}
      style={{margin:'0px',padding:'0px'}}
      >
       
        <Modal.Content className='border'>
          <Modal.Description>
            <UserDetailForModal 
            id={this.props.id}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(UserDetailModal);
