//import React, { Component } from 'react';
import React  from 'react';
import { Modal, } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { closePopup } from '../../actions/popupAction';

const actions = { closePopup };


 const AlertCreateSQAPopup = (props)=> {

  return (
    <Modal 
    // dimmer='blurring'
    //size="tiny"
    dimmer={props.dimmer} // "inverted"/"blurring"
    open={true} 
    onClose={props.closePopup}
    closeOnEscape={false}
    closeOnDimmerClick={false} 
    style = {{
      top:'auto',  // for positioning of modal at center of view port
    maxWidth:'350px'
    }}
    >
     
      <Modal.Content className='border-2'
      >


        <div
        className='font-18B mb-20'
        
        >
        <div className="font-18B color-teal">
Create Security Questions / Answers, which you will need to answer when resetting your password in case you forget password.
</div>
        </div><br/>

        <div className='display-flex j-c-flex-end'>
        <span 
       className='icon-button1 font-16B border-skyblue-2 pxy-5-10 mr-05 '
        onClick={props.closePopup}
        > 
        <Link to="/auth/SecurityQACreate" className="" >


<span className="title">Create Security Q & A</span>


</Link>
        </span >
     <span 
       className='icon-button1 color-red font-16B border-skyblue-2 pxy-5-10 '
        onClick={props.closePopup}
        > 
        Not Now
        </span >
 
        </div>
      </Modal.Content>
    </Modal>
  );
    
    
  }

// const mapState = state => ({
//   message1: state.popups.popupProps.message
// });
export default connect(
  null,
  actions
)(AlertCreateSQAPopup); 
