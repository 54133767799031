import React from "react";
import { connect } from "react-redux";
import CopyValuePopup from './CopyValuePopup';
import CopyPhoneFixedPopup from './CopyPhoneFixedPopup';
import AlertCreateSQAPopup from './AlertCreateSQAPopup';


// import UnauthModal from './UnauthModal'

const popupLookup = {
  CopyValuePopup,
  CopyPhoneFixedPopup,
  AlertCreateSQAPopup,
  
  // UnauthModal
};

const mapState = state => ({
  currentPopup: state.popups
});

const PopupManager = ({ currentPopup }) => {
  let renderedPopup;

  if (currentPopup) {
    const { popupType, popupProps } = currentPopup;
    const PopupComponent = popupLookup[popupType];

    renderedPopup = <PopupComponent {...popupProps} />;
  }
  return <span>{renderedPopup}</span>;
};

export default connect(mapState)(PopupManager);
