

import React, { Component } from "react";

// import {Image, } from "semantic-ui-react";

class NavbarGuest extends Component {


  render() {
    
    const guestLinks = (

      <div >
      <nav id="navbar" className="masthead" style={{height:'60px'}} >
      <div style={{display:'flex',alignItems:'center',marginLeft:'10px'}}>
        <div className = '  nodisplay372' style={{ marginRight: '.5em',backgroundColor:'',padding:'2px' }} >
        <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"60px", height:"39px",borderRadius:'5px',verticalAlign:'middle',marginRight: '.50em',}} />
        <img className= ' ' src='/logo_ekhata_3.png' alt=" "  style={{width:"120px", height:"30px",borderRadius:'5px',verticalAlign:'middle'}} />
        </div>
{/* <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
<span style={{color:'aqua'}}>DAILY.</span>
<span style={{color:'pink'}}>P</span>
<span style={{color:'white'}}>P</span>
<span style={{color:'orange'}}>2</span>
<span style={{color:'white'}}>P</span></div> */}  
</div>
          {/* <ul>
            <li><b><a href="http://localhost:3011">Home</a></b></li>

            <li><b><a href="#what">Product</a></b></li>

            <li><b><a href="#whatT">Features</a></b></li>

            <li><b><a href="https://citsys.in" target='_blank'
            rel="noopener noreferrer" >About</a></b></li>

            <li><b><a href="#contact">Contact</a></b></li>

          </ul> */}
        </nav>

      </div>
    );



    return (
      <div
      // Disable right click
  onContextMenu={e => e.preventDefault()}
      >
        { guestLinks}
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@




export default NavbarGuest
