import React from 'react';
import { 
  Image,
  
} from "semantic-ui-react";

 const AdFindprop = props => {

  //******** */
  return (
    
    <div className='disflex-paginator card-paginator media-600 bgColor-dodgerblue text-center py-1'>
      
    <div 
    // className="font-16B color-yellow pxy-10 font-family-bellMT" 
    className={props.className1}
    style={{textAlign: 'center',lineHeight:'24px',margin:'0px'}}>
  India's Most Trusted Property Site
  </div>
    {/*  */}
    <div className="display-center">

    <div className = ' border-logo '><Image size='mini' src='/logoProp.png'  /></div>
{/*  */}
<a href="https://demo.findprop.in" target="_blank" >
<div className='ml-010' style={{fontSize:'1.5rem',fontWeight:'bold'}}>
<span style={{color:'white'}}>FIND</span>
<span style={{color:'yellow'}}>PROP</span>
<span style={{color:'white'}}>.</span>
<span style={{color:'orange'}}>.IN</span>
</div>
</a>

</div>
{/*  */}
<div 
className={props.className2} 
//className="font-16B color-white pxy-10 font-family-Calibri" 
style={{textAlign: 'center',lineHeight:'24px',margin:'0px'}}>
  The Most Genuine And Accurate Information About Real Estate In Dwarka Delhi
  </div>

    
    </div>
   

  );
}


export default AdFindprop