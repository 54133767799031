import React, { Component } from "react";
import { connect } from "react-redux";
//import { Container,  } from "semantic-ui-react";
import { Switch, Route,  } from "react-router-dom";

import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";
import { showBackButton,logoutUser } from "../../actions/authAction";

import CSVDownload from "./CSVDownload";


import Layout from "../layout/Layout";


  class OptionDashboard extends Component {
    state = {
      openPop: false,
    };
    componentDidMount() {
      //this.props.getUserForAuth();
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
    }
      }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
    handleClosePop = () => this.setState({ openPop: false })
    //********************** */
  render(){
    const { isAuthenticated,  } = this.props.auth;

    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



           <Layout onLogoutClick={this.onLogoutClick}>


          <Switch>
          <Route  exact path="/option/CSVDownload" component={CSVDownload} />
           
          </Switch>
          </Layout >
          </div>

  <div >
  <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div className="mt-10">
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
auth: state.auth
});

export default connect(
  mapStateToProps,
  {showBackButton, logoutUser }
)(OptionDashboard);
