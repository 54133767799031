import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { openModal } from "../../actions/modalAction";
import {
  fetchMyGroupUsers,
  addRemoveToMyGroup,
} from "../../actions/authAction";

import {
  Header,
  Container,
  Divider,
  Segment,
  Icon,
  Button,
  Modal,
  Input,
  Image,
} from "semantic-ui-react";
import {
  renderMonth,renderPreviousMonth,
} from "../functions/Functions";
import { getMonth } from 'date-fns'
import AllFinanceSA from '../finances/AllFinanceSA'
import CashBookCashCreateFromMyGroup from '../finances/CashBookCashCreateFromMyGroup'
import SaleBookCreateFromMyGroup from '../finances/SaleBookCreateFromMyGroup'
class MyGroup extends Component {
  state = {
    pageO:0,
    search: "",
    header:true,
    searchField:false,
    modalOpenImg: false,
    image :'',
    allFinanceSAModalOpen: false,
    createModalOpen:false,
  };
  componentDidMount() {
    this.props.fetchMyGroupUsers();
      }    
      
  showSearchField = () => {
    this.setState({header:false,searchField:true,});
  };
  showHeader = () => {
    this.setState({header:true,searchField:false,search: ""});
  };
  myGroupAddRemoveHandler = (id) => {
    
    const monthPre = renderPreviousMonth(getMonth(new Date()))
    const month = renderMonth(getMonth(new Date()))
    this.props.addRemoveToMyGroup(id, month,monthPre);
  };
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleAllFinanceSAModalOpen = (accountId,month) => this.setState({ allFinanceSAModalOpen:true, accountId,month});
handleAllFinanceSAModalClose = () => {
  this.setState({ allFinanceSAModalOpen:false, })
};
//**********************************
handleCreateModalOpen = (id) => this.setState({ createModalOpen:true, id:id })
handleCreateModalClose = () =>{
   this.setState({ createModalOpen:false })
      }  //
handleCreateModalCloseSubmit = () =>{
  this.handleRefreshAll()
        this.setState({ createModalOpen:false })
}

//**********************************
handleOpenModal = (url) => {   
  this.props.openModal("ImageZoomModal" , {imageURL:url});
};
//****************************************************

  //^^^^^^^^^^^^^^^^^^^^^^^^^
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  renderSaleOrCashCreate=()=>{
    return this.props.userA !== null && this.props.userA.userType === 'Shop Owner'?
    (
<SaleBookCreateFromMyGroup
accountId={this.state.id}
handleCreateModalClose= {this.handleCreateModalClose}
/>
    ) : (
<CashBookCashCreateFromMyGroup
accountId={this.state.id}
handleCreateModalClose= {this.handleCreateModalClose}
/>
      )
  }
//*******************************************************
  renderList=(user) => {
    const {
      //forSearch,
      userType,
      shopName,
      uname,
      uimage,
      address,
      projectName,
      locality,
      city,
      state,
      pincode,

      _id
    } = user;


//************************** */
      const checkID = this.props.userA !== undefined && this.props.userA !== null && this.props.userA.mygroup !==undefined && this.props.userA.mygroup.filter(val => {

        return val === _id.toString();
      });
      
      const renderMyGroupButton = () =>
            checkID.length > 0 ? (
              <span
              className="icon-button"
              onClick={() => this.myGroupAddRemoveHandler(_id)}
              >
              <img className= 'yimg ' src='/removeGroupIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>

        ) : (
          <span
          className="icon-button"
          onClick={() => this.myGroupAddRemoveHandler(_id)}
          >
          <img className= 'yimg ' src='/addGroupIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>

        );

    //******************
    const renderUserDetailButton = () =>
      (   <span
        className="icon-button"
        onClick={()=>this.props.openModal('UserDetailModal',{id:_id})}
        >
        <img className= 'yimg ' src='/detailIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

      );
    const renderDetailFinButton = () =>
      (   <span
        className="icon-button"
        onClick={()=>this.handleAllFinanceSAModalOpen(_id,renderMonth(getMonth(new Date())))}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

      );
      const renderCreateButton = () =>
      (   <Button
        circular
        icon='plus'
        onClick={()=>this.handleCreateModalOpen(_id)}
        style={{backgroundColor: 'aqua',marginRight:'15px'}}
      />

      );

      //*******************
      //*******************

    return (
      <Segment key={_id}>
          <div>
          <div >
            {/*  */}
          <div className='display-center'>
                

                  { renderMyGroupButton()}
                  {renderUserDetailButton()}

                  {renderDetailFinButton()}
                  {renderCreateButton()}

                 
                </div>
                {/**/}
          <Header  color='blue'>{userType === 'Shop Owner'? shopName : uname}</Header>


            </div>

            {/**/}
            <Divider fitted /> 
            <div >
            {uname && (
              <div className='row' style={{alignItems: 'center'}}>
                <div className='side'>
               
                <Image
    className="icon2-button "
    onClick={() => this.handleOpenModal(uimage)}
    style={{maxWidth:"50px", maxHeight:"50px",}}
      floated="left"
      size="tiny"
      src={`${uimage}`} alt="profile image"
    />
                

                </div>
                <div className='main'>
                {/**/}
                {address && (
                <b>{address}{' '}{projectName}{' '} {locality} {' '}{city}{' '}{state}{' '}{pincode}</b>
                )}

                {/**/}
                </div>

              </div>
            )}
            </div>
            {/**/}
          </div>

      </Segment>
    );
  }

  renderLoading() {
    if (this.props.fetching===true) {
      return (
        <div><span style={{fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
      );
    }
  }


  render() {

    const { search } = this.state;
        const usersF = this.props.users!== undefined && this.props.users.filter(item => {
          return item.forSearch.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        });
    //******************
const renderHeaderOrSearch =()=>{
  if(this.state.header===true){
    return(
      <React.Fragment>
      <div className='cashbook-H'>

      <div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >
      <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
      {this.props.count}{' '}My Group Users
      </div>
      </div>

      <div
      style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
      onClick={this.showSearchField}
      >
          <span className='icon-button border1' > <Icon name='search' /></span >
        </div>


      </div>
      </React.Fragment>
    )
  }
  if(this.state.searchField===true){
    return(
      <React.Fragment>
<Segment attached>
<div className='disflex'>
{/**/}
<div
style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.showHeader}
>
<button

onClick={this.showHeader}

    className='back-btn'
  >
    <Icon name="arrow left" />

  </button>
  </div>

{/**/}
<div  style={{display: 'flex',flex: '90%',justifyContent: 'center',}} >

<Input
 icon='search' placeholder='Search...'
  type="text"
  name="search"
  value={this.state.search}
  onChange={this.onChange}
/>

</div>




</div>

</Segment>
      </React.Fragment>
    )
  }
}
    //******************

    return (
      <Container text>
{/* EditCashBook Modal*/}

<Modal
      open={this.state.createModalOpen}
      onClose={this.handleCreateModalClose}
      basic
      closeOnEscape={false}
      closeOnDimmerClick={false}
      style={{margin:'0px',padding:'0px'}}
    >

      <Modal.Content>
      {this.renderSaleOrCashCreate()}     

      </Modal.Content>

    </Modal>
    
        {/* AllFinanceSA Modal*/}
<Modal
        open={this.state.allFinanceSAModalOpen}
        onClose={this.handleAllFinanceSAModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        style={{margin:'0px',padding:'0px'}}
        >

        <Modal.Content>
        <div className="mb-10"
style={{color:'white'}}
>
     
      <button

onClick={this.handleAllFinanceSAModalClose}

className='back-btn color-white'
>
<Icon name="arrow left" />

</button>
    </div>
        <AllFinanceSA 
        accountId = {this.state.accountId}
        month = {this.state.month} 
        handleAllFinanceSAModalClose = {this.handleAllFinanceSAModalClose}
       
        />

        </Modal.Content>
        </Modal>

         {/* AllFinanceSA Modal end*/}
        {/*  */}

      {renderHeaderOrSearch()}

{/**/}
        {this.renderLoading()}

        {/*{this.props.users.map(this.renderList)}*/}
        {usersF.map(this.renderList)}


<br/><br/><br/>

      </Container>
    );

  }

  }


const mapStateToProps = state => {

  return {
    fetching: state.auth.fetching, 
    count: state.auth.countG,
    users: state.auth.usersG,
    userA: state.auth.userA,
    user: state.auth.user,
  };
};
export default connect(
  mapStateToProps,
  {  fetchMyGroupUsers, addRemoveToMyGroup, openModal }
)(MyGroup);
 