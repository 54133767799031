import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {
  // Image,
  // Segment,
  // Header,
  // Form,
  Button,
  Icon
} from 'semantic-ui-react';
//import Resizer from 'react-image-file-resizer';
import { toastr } from "react-redux-toastr";
import { fetchAppParameter } from "../../actions/commonAction";

 

class AddPdfFirstTimeContra extends React.Component {
  state = {
    selectedFile: null,
    loaded: 0,
    files: [],
    imageName: '',
    cropResult: null,
    image: {},
    imageURL: '',
  };
  componentDidMount() {
    if (this.props.parameter === null){
      this.props.fetchAppParameter()
        }
  }

  //@@@@@@@@
  handleselectedFile = event => {
    this.setState({
      files: event.target.files,
      selectedFile: event.target.files[0],
      loaded: 0,
    })
  }


  //@@@@@@@@@@@@@

  handleUploadPdf = async ev => {
    ev.preventDefault();
    const imageURL_F=this.props.parameter !== null && this.props.parameter.currentURL

    const data = new FormData();
    data.append('file', this.state.selectedFile);
    //data.append('imageName', this.state.imageName);
    data.append('id', this.props.id);
    data.append('imageURL_F', imageURL_F);


     try {
       
        //************ */
if(this.state.files.length >0 && this.state.files[0].size <(500*1024)){

  if(this.props.entryFor === 'Self'){
    const resPdf = await axios.post(`${imageURL_F}/api/PdfAddContra`, data);

    if(resPdf.data.msg ==='success'){

      //##
      if(((this.props.entryType=== 'Receipt (Contra)')||(this.props.entryType==='Payment (Contra)')) ){
        this.props.imagePathContra.unshift(resPdf.data.path)
   
        const values = {
          id:this.props.id,
          idOfContraEntry:this.props.idOfContraEntry,
          imagePathContra:this.props.imagePathContra
        }
      
        const res= await axios.put(`/api/EditForImageContra`, values);
        if(res.data.msg ==='success'){
          this.props.handleRefresh()
          this.props.handleAddViewImageModalClose()
          toastr.success('Pdf Added Successfully')
        }
      }else{
        this.props.imagePathContra.unshift(resPdf.data.path)
   
     const values = {
       id:this.props.id,
       imagePathContra:this.props.imagePathContra
     }
   
     const res= await axios.put(`/api/EditForImageSelf`, values);
     if(res.data.msg ==='success'){
       this.props.handleRefresh()
       this.props.handleAddViewImageModalClose()
       toastr.success('Pdf Added Successfully')
     }
     //##
    }

      
   } // end bracket for second if statement
} //entryFor Self statement end
  //************ */
  if(this.props.entryFor === 'Both'){
       const resCheck = await axios.get(`/api/FinanceDetail/${this.props.id}`);
 
    
 //@@@@
       if(resCheck.data.checkedStatus ==='Checked Ok'){
  toastr.error("Entry is Locked You Can't do this action");
          
}else{
  const resPdf = await axios.post(`${imageURL_F}/api/PdfAddContra`, data);


if(resPdf.data.msg ==='success'){
   this.props.imagePathContra.unshift(resPdf.data.path)

  const values = {
    id:this.props.id,
    idOfContraEntry:this.props.idOfContraEntry,
    imagePathContra:this.props.imagePathContra
  }

  const res= await axios.put(`/api/EditForImageContra`, values);
  if(res.data.msg ==='success'){
    this.props.handleRefresh()
    this.props.handleAddViewImageModalClose()
    toastr.success('Pdf Added Successfully')
  }

} // end bracket for second if statement
}// end bracket for first if statement
      // }
  } //entryFor Both statement end

//^^^^^^^^^^^^^^^^^^^^^^
}else{
  toastr.error('File Size is More than Allowed Size')
}//size check statement end

//try catch
    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }
//last closing bracket
  };

  render() {
   
const renderAddImageButton =()=>{
  if(this.state.files.length <1){
    return(
      <React.Fragment>
      <div>
<label size="tiny" for="hidden-new-file" class="ui icon button">

<span className="icon-size">
<Icon  name="plus circle"/>
</span><span className="icon-size">Add Pdf</span>

</label>
<input
type="file" id="hidden-new-file" accept="application/pdf"
style={{display: 'none'}}
onChange={this.handleselectedFile}
/>
</div>
    </React.Fragment>
    )
  }
  //**
  if(this.state.files.length >0){
    return(
      <Button
      color="blue"
      type="button"
      onClick={this.handleUploadPdf}
      >
      Upload
      </Button>
    )
  }
}
const renderSizeLimitField =()=>{
return(  <div style={{color:'black'}}>
  file size {Math.ceil( (this.state.files.length >0 && this.state.files[0].size)/1024  )}kb (maximum allowed 500kb)
  </div>)
}
    return (
      <div className='main-div' style={{backgroundColor:'white'}}>
              <div style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >

<div  style={{display: 'flex',flex: '70%',justifyContent: 'center',}} >
<div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
Add Pdf
</div>
</div>

<div
style={{display: 'flex',flex: '30%',justifyContent: 'center',alignItems: 'center',}}
onClick={this.props.showAddImage}>
<span className='icon-button border1' >Add Images</span >
</div>


</div>

{/**/}
  <br/>
  {/**/}
  <br/>

{/**/}
<div className='disflex-paginator'>
{renderSizeLimitField()}
</div>
<div className='disflex-paginator'>
              {/*<div>
  <label size="tiny" for="hidden-new-file" class="ui icon button">

    <span className="icon-size">
    <Icon  name="plus circle"/>
</span><span className="icon-size">Add Image</span>

  </label>
  <input
  type="file" id="hidden-new-file"
  style={{display: 'none'}}
  onChange={this.fileChangedHandler}
  />
</div>*/}

{renderAddImageButton()}
<Button
color="teal"
type="button"
onClick={this.props.handleAddViewImageModalClose}
>
Close
</Button>
</div>
{/**/}




</div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
  };
};

export default connect(
  mapStateToProps,
{  fetchAppParameter}
)(AddPdfFirstTimeContra);
