import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { closeModal } from "../../actions/modalAction";
import { setMonth} from "../../actions/commonAction";

import * as actions from "../../actions/financeAction";
import { withRouter } from "react-router-dom";
import {  Form, Button,  } from "semantic-ui-react";
//import TextInput from "../form/TextInput";
import SelectInput from "../form/SelectInput";
//import DateInput from "../form/DateInput_2";
import {
  //renderMonth,
  //renderPreviousMonth,
  monthOption
} from "../functions/Functions";
import { getMonth,getYear } from 'date-fns'


class SelectMonth extends Component {
  state = {
    monthNum:getMonth(new Date()),
    year:getYear(new Date()),
    monthWord:''
 };
  // componentDidMount() {
   
  //   const datePickers = document.getElementsByClassName("react-datepicker__input-container");
  //   Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute("readOnly", true)))
  // }

  onSubmit = values => {
    const month = values.month
    this.props.fetchSummarySA(month);
    this.props.setMonth({month:values.month})
    this.props.closeModal()
  
  };


  render() {
    
    
    const { handleSubmit } = this.props;

    
    return (
      <div className='marginBottom' >
      <div style={{maxWidth:'700px',margin:'auto'}}>

                   {/**/}
                   <div className='margin-auto-H' >
            <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {/*  */}
              Select Month
          {/*  */}
          </div>
          </div>
          {/**/}
        <div className="card-form ">
          <div className=" label" >

            <Form
              style={{ padding: 10 }}
              onSubmit={handleSubmit(this.onSubmit)}
            >


            <br />


           
                <label>Month</label>
            <Field
             //search={true}
             //multiple={true}
              name="month"
              type="text"
              component={SelectInput}
              options={ monthOption()}
              placeholder="Enter Key word"
            />


              <br />

              <Button
                color="blue"
                type="submit"
               // disabled={pristine || submitting}
              >
                Submit
              </Button>
        {/* <Button
          color="orange"
          type="button"
          disabled={pristine || submitting}
          onClick={reset}
        >
          Clear Values
        </Button> */}
        <Button
            color="teal"
            type="button"
            onClick={()=>this.props.closeModal()}

          >
            Cancel
          </Button>
            </Form>
            <br/>
            </div>
            </div>
            </div>

            
</div>
    );
  }
}

const mapStateToProps = (state) => {

  const { fetching,limit, offsetC, count } = state.finances;

  return {
    state,
    fetching,
    limit,
    offset:offsetC,
    count,
    form_values: state.form.SaleBookSearch,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    finances: state.finances.finances,
    initialValues: {month:(state.commons.month!== null && state.commons.month)}
  };
};


export default connect(
  mapStateToProps,
  {...actions,closeModal,setMonth}
)(
  reduxForm({
    form: "SelectMonth", // a unique identifier for this form
    destroyOnUnmount: false,
    // validate
  })(withRouter(SelectMonth))
);
