import React, { Component } from "react";
//import { Link } from "react-router-dom";
//import includes from "lodash/includes";
import { connect } from "react-redux";
import format from "date-fns/format";
import writtenNumber from "written-number";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import Paginator from "../paginator/Paginator";
import { openModal } from "../../actions/modalAction";
import { transport,resetForm , } from "../../actions/commonAction";
import * as actions from "../../actions/financeAction";
import { fetchMyGroupUsers, } from "../../actions/authAction";
import SingleAccountFinanceEdit from "./SingleAccountFinanceEdit";
import FinanceEditToAccept from "./FinanceEditToAccept";

import {capitalizeFirst,
  //renderPreviousMonth,
} from "../functions/Functions";
//import AdFindprop from "../advertisement/AdFindprop";

import {
  Table,
  Container,
  Divider,
  Segment,
  Button,
  Label,
  Popup,
  Confirm,
  Icon,
  Modal,
} from "semantic-ui-react";



class AccountDetail extends Component {
  state = {
    pageO:'',
    id: "",
    idOfContraEntry:'',
    path:"",
    imagePathContra:[],
    checkedStatus:'',
    entryFor:'',
    entryType:'',
    open: false,
    result: "show the modal to capture a result",
    renderType: "Payment",
   editModalOpen: false,
   financeEditToAcceptModalOpen: false,
   selectAll:false,
  
  };

  componentDidMount() {
    
    const {limit} = this.props
    if (!this.props.state.auth.usersG.length){
      this.props.fetchMyGroupUsers()
        }
    
    const values = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  };
    this.props.searchAllFinanceSA(values,limit);

    const valuesT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'total'  };
    this.props.searchAllFinanceSA(valuesT,limit);

    const valuesTAll = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalAll'  };
    this.props.searchAllFinanceSA(valuesTAll,limit);
    
    const valuesTGT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalGT'  };
    this.props.searchAllFinanceSA(valuesTGT,limit);
  
  }
  componentWillUnmount(){
    this.props.resetForm('SingleAccountFinanceSearch')
    this.props.resetNoEntry()
  }
  

  handleSearchSingleAccountFinance = () => {
    this.props.openModal("SearchSingleAccountFinanceModal",{accountId:this.props.accountId,renderType:this.state.renderType});
  };

  show = (id , path,entryType,idOfContraEntry) => {

    this.setState({ open: true, id: id, path:path,entryType:entryType,idOfContraEntry:idOfContraEntry });
  };


  renderEntryType = () => {
    if(this.state.renderType === 'Payment'){
      return {entryTypeA: ["Payment", 'Sale','Purchase Return']}
    }
    if(this.state.renderType === "Receipt"){
      return {entryTypeA: ["Receipt", 'Purchase','Sale Return'] }
    }
  }

  handleRefresh = ()=>{
    const {offset, limit} = this.props;
    const values = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   ...this.renderEntryType()  }
    this.props.searchAllFinanceSA(values, limit,offset)
  }

handleRefreshAll = ()=>{
  const {offset, limit} = this.props;
  const values = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   ...this.renderEntryType()  }
  this.props.searchAllFinanceSA(values, limit,offset)

  const valuesT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   ...this.renderEntryType() ,search: 'total'  }
  this.props.searchAllFinanceSA(valuesT, limit,offset)
  
  const valuesTGT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   ...this.renderEntryType() ,search: 'totalGT'  }
  this.props.searchAllFinanceSA(valuesTGT, limit,offset)
  
}


handleConfirm = async () => {
  const did = this.state.id;
  const  value = {paths:this.state.path}
  const  valueDel = {entryType:this.state.entryType,idOfContraEntry:this.state.idOfContraEntry}
  const imageURL_F =()=>{
    if(this.state.path.length >0){
      return this.state.path[0].substr(0, 23)
    }
  }

//************
try {
  if(this.state.path.length >0){
  const resImage = await axios.post(`${imageURL_F()}/api/ImageDeleteAllContra`, value);
    if(resImage.data.msg ==='success'){
      const res = await axios.put(`/api/FinanceDelete/${did}`,valueDel);

      if(res.data.msg ==='success'){
        this.handleRefreshAll()
        this.setState({ result: "confirmed", open: false });
        toastr.success('Deleted Successfully')
        }
    }
  }
  //^^^^^^^^^^^
  if(this.state.path.length <1){

    const res = await axios.put(`/api/FinanceDelete/${did}`,valueDel);

      if(res.data.msg ==='success'){
        this.handleRefreshAll()
        this.setState({ result: "confirmed", open: false });
        toastr.success('Deleted Successfully')
        }
    }


  }catch (error) {
    
 toastr.error("Oops", "Something went wrong.Please Try Again single account fin");
}
//**********
};
  handleCancel = () => this.setState({ result: "cancelled", open: false });
//**********************************
  handleEditModalOpen = (id) => this.setState({ editModalOpen:true, id:id })
  handleEditModalClose = () =>{
     this.setState({ editModalOpen:false })
        }  //
  handleEditModalCloseSubmit = () =>{
    this.handleRefreshAll()
          this.setState({ editModalOpen:false })
  }
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleFinanceEditToAcceptModalOpen = (id) => this.setState({ financeEditToAcceptModalOpen:true, id:id })
handleFinanceEditToAcceptModalClose = () =>{
   this.setState({ financeEditToAcceptModalOpen:false })
    }  //
handleFinanceEditToAcceptModalCloseSubmit = () =>{
  this.handleRefresh()
      this.setState({ financeEditToAcceptModalOpen:false })
}

//***********************************
renderPayment = () => {
  const {limit} = this.props;
  
  this.setState({renderType: "Payment",},
  () => {
    const values = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  };
    
    this.props.searchAllFinanceSA(values,limit);
    const valuesTGT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,   entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalGT'  };
    this.props.searchAllFinanceSA(valuesTGT,limit);
  }
  
  )
};
renderReceipt = () => {
  const {limit} = this.props;
  
  this.setState({renderType: "Receipt",},
  () => {
    const values = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  };
    this.props.searchAllFinanceSA(values,limit);

  const valuesTGT = {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) ,  accountId:this.props.accountId,  entryTypeA: ["Receipt", 'Purchase','Sale Return'] ,search: 'totalGT'  };
  
    this.props.searchAllFinanceSA(valuesTGT,limit);
  }
  
  )
};

//*******************************************************

renderButtonGroup =()=>{
  
  return ( 
<div className = 'display-start'>
    <span className="icon3-button">
    <Button
    style={{margin:'0px'}}
        disabled={this.state.renderType === 'Payment'? true:false}
       // floated="right"
        color="blue"
        type="button"
        onClick={this.renderPayment}
      >
        OUT ( Cr )
      </Button>
    </span>
    <span className="icon3-button" >
    <Button
    style={{margin:'0px'}}
        disabled={this.state.renderType === 'Receipt'? true:false}
        //floated="left"
        color="orange"
        type="button"
        onClick={this.renderReceipt}
      >
        IN ( Dr )
      </Button>
    </span>
                    
  </div>

  )
  
}
//**************************************

   renderCheckedStatusButton = (entryType,checkedStatus,id) =>{
    if(checkedStatus==='Not Checked'){
      
      if(((entryType==='Receipt')||(entryType==='Purchase')||(entryType==='Purchase Return'))){
        return (
          <span
          onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOnHold.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }else{
        return (
          <span
          //onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOnHold.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }

    }
    if(checkedStatus==='Checked Ok'){
      
      if(((entryType==='Receipt')||(entryType==='Purchase')||(entryType==='Purchase Return'))){

          return (
            <span
            //onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
            className="icon-button"
            >
            <img className= 'yimg btn-disabled' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
          )
          }else{
            return (
              <span
              //onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
              className="icon-button"
              >
              <img className= 'yimg btn-disabled' src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
              </span>
            )
          }
    }
    
    //^^^^^^^^^^^ last bracket
  }


  //^^^^^^^^^^^^^^^
   nameClass =(entryType)=>{
    
    if(((entryType==='Receipt')||(entryType==='Purchase')||(entryType==='Purchase Return'))){
      return 'yimg '
    }else{
      return 'yimg btn-disabled'
    }
  }
  // ^^^^^^^^^^^^^^^ 
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
//*******************************************************
  renderList=(finance)=> {
    const {
      entryType,
      description,
      // accountId,
      amount,
      entryDate,
      checkedStatus,
      _id,
      
    } = finance;

      
    

      //*******************
                // //^^^^^^^^^^^^^^^
    return (
      <Table.Row key={_id}>
      
        <Table.Cell>{format(new Date(entryDate), "MMM d yyyy")}</Table.Cell>
        <Table.Cell>{entryType}
        </Table.Cell>
        <Table.Cell>{description && capitalizeFirst(description)}</Table.Cell>
        
        <Table.Cell>{amount}</Table.Cell>

        <Table.Cell>
        <div className='disflex-paginator'>
        <span
        onClick={()=>this.handleEditModalOpen(_id)}
        className="icon-button"
        >
        <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
        
        {this.renderCheckedStatusButton(entryType,checkedStatus,_id)}

          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
//*********************************** for mobile
renderListMobile=(finance)=> {
  const {
    entryType,
    description,
    // accountId,
    amount,
    entryDate,
    checkedStatus,
    _id,
    
  } = finance;

    
  const colorRibbon = ((entryType === 'Payment')||(entryType === 'Payment (Contra)')||(entryType === 'Sale')||(entryType === 'Purchase Return')) ? 'blue': 'teal';
  
    //******************


  return (
    <Segment key={_id}>
      <Label color={colorRibbon} ribbon>
{entryType}
      </Label>
      
      
      {/* <div className='disflex-paginator'> */}
      <span
      onClick={()=>this.handleEditModalOpen(_id)}
      className="icon-button ml-20"
      >
      <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
      </span>
      
      {this.renderCheckedStatusButton(entryType,checkedStatus,_id)}


        {/* </div> */}
      <br /><br />


      <Divider fitted />

      <div >
      {entryDate && (
        <div className='row'>
        <div className='side'>
          <b>Date</b>
        </div>
        <div className='main'>

        <b>{format(new Date(entryDate), "MMM d yyyy")}
        </b>
        </div>

        </div>
      )}
      </div>

      <div >
      {entryType && (
        <div className='row'>
        <div className='side'>
          <b>Entry Type</b>
        </div>
        <div className='main color-date'>

        <b>{entryType}
        </b>
        </div>

        </div>
      )}
      </div>

      

      <div >
      {description && (
        <div className='row'>
        <div className='side'>
          <b>Description</b>
        </div>
        <div className='main'>
          <b>{capitalizeFirst(description)}</b>
        </div>

        </div>
      )}
      </div>

      
      <div >
      {amount && (
        <div className='row'>
        <div className='side'>
          <b>Amount INR</b>
        </div>
        <div className='main'>
          <b>{amount}{' '}(   {capitalizeFirst(writtenNumber(amount, {
      lang: "enIndian"
    }))}  )
          {" "}</b>
        </div>

        </div>
      )}
      </div>


    </Segment>
  );
}

//**********************
  back = () => {
    const {
      offset,
      limit

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }


    if (offset === 0) {
      return;
    }

    this.props.searchAllFinanceSA(values, limit, (offset - limit));
    window.scroll(0,11000)
  };

  advance = () => {
    const {
      offset,
      limit,
      count

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }

    if (offset + limit > count) {
      return;
    }

    this.props.searchAllFinanceSA(values, limit, (offset + limit));
    window.scroll(0,0)
  };
  handleGoToPage = () => {
    const {

      limit,
      count

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.month, ...(this.props.form !== undefined && this.props.form.values) , accountId:this.props.accountId,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }

      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return;
      }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchAllFinanceSA(values, limit, (page * limit));
      window.scroll(0,0)

  };

  //****************** */
  renderPaginator() {
    if (this.props.allFinances !== undefined && this.props.allFinances !== null  && this.props.allFinances.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


    // renderLoading() {
    //   if (this.props.fetching===true) {
    //     return (
    //       <Container text>
    //     <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

    //     </div>
    //     </Container>
    //     );
    //   }
    // }
    renderNoEntry() {
      if (this.props.noEntry===true) {
        return (
          <>
          <Table.Row className="nodisplay-at-mobile disflex-center">
            <Table.Cell>
            <span className="font-20B color-dodgerblue">No Entry Exist</span>
            </Table.Cell>
          </Table.Row>
        <Segment className="nodisplay-at-tab disflex-center"><span className="font-20B color-dodgerblue">No Entry Exist</span></Segment>
        </>
        );
      }
    }

  //########## RENDER ###############################
  render() {
    
    const { open,  } = this.state; 
const userA = this.props.userA !== undefined && this.props.userA !== null && this.props.userA 
//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const array = this.props.usersG !== null && this.props.usersG !==undefined && this.props.usersG.map(val => {
  return {id:val._id,accountName: val.uname}
});

const accName = array.find(item => item.id === this.props.accountId)

//$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ 

const BalanceAll = this.props.BalanceAll !==undefined && this.props.BalanceAll
const BalanceAllTotal = this.props.BalanceAllTotal !==undefined && this.props.BalanceAllTotal

const colorRibbon = this.state.renderType === 'Payment' ? 'blue': 'teal'
const EntryType = ()=>{
  if(this.state.renderType === 'Payment'){
    return 'OUT'
  } 
  if(this.state.renderType === 'Receipt'){
    return 'IN'
  }

}

//*********************************
    let sumT = this.props.allFinances !== undefined && this.props.allFinances.reduce((acc, val) => acc + val.amount, 0);


    // const selec = this.props.allFinances !== undefined && this.props.allFinances !== null && this.props.allFinances !==undefined && this.props.allFinances.map(val => {

    //   return val._id
    // });
    const AllFin = (this.props.allFinances !== undefined && this.props.allFinances !== null  && this.props.allFinances) ||[]
    // const AllFin = AllFinO.map(val => {

    //   return {...val,entryTypeForm:entryTypeForm}
    // }) 

//#####################################
const renderPayableReceivable =()=>
( BalanceAll < 0 ) ? (
<span style={{color:'red'}}><b> Receivable</b></span>
) : (
  <span style={{color:'blue'}}><b> Payable</b></span>
);
const renderPayableReceivableTotal =()=>
( BalanceAllTotal < 0 ) ? (
<span style={{color:'red'}}><b> Receivable</b></span>
) : (
  <span style={{color:'blue'}}><b> Payable</b></span>
);
// const renderNetAmount =()=>{
//   if(!entryTypeForm){
//     return(
//       <div style={{marginTop:'15px'}} >
//         <span className='border' style={{padding:'10px'}}>
//   <b><span>Net Amount</span> :  {renderNumber()}
//   <span style={{color:'blue'}} >( {renderMinusWord()}{' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
//   lang: "enIndian"
//   }))} )</span></b>
//
//   </span>
//         </div>
//     )
//   }
//
// }
// const renderNetAmountMobile =()=>{
//   if(!entryTypeForm){
//     return(
//       <div className='sideF'><b><span>Net Amount</span> : {renderNumber()}
//   <span style={{color:'blue'}} >({renderMinusWord()} {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
//   lang: "enIndian"
// }))} )</span></b></div>
//     )
//   }
//
// }
//***********************
// const renderTransactionHeader = () =>{
//   if( this.props.userA !== null && this.props.userA.userType !== "guest"){
//     return  (<Table.HeaderCell>Transaction Type</Table.HeaderCell>)
//     }
// }


//   const renderTransactionFooter = () =>{
//     if( this.props.userA !== null && this.props.userA.userType !== "guest"){
//       return  (<Table.HeaderCell />)
//       }
//   }

//**************************

      return (
        <div className="color-black">
        {/**/}
        {/**/}
        <div >
        {/**/}

          <Confirm
             header='Do You Want To Delete ?'
            size="mini"
            open={open}
            onCancel={this.handleCancel}
            onConfirm={() => this.handleConfirm()}
          />
        {/* EditCashBook Modal*/}

        <Modal
        open={this.state.editModalOpen}
        onClose={this.handleEditModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        // size='small'
        >

        <Modal.Content> 
        <SingleAccountFinanceEdit
        id={this.state.id}
        month={this.props.accountMonth}
        handleEditModalCloseSubmit={this.handleEditModalCloseSubmit}
        handleEditModalClose= {this.handleEditModalClose}
        />

        </Modal.Content>

        </Modal>
        {/* FinanceEditToAccept Modal*/}

        <Modal
        open={this.state.financeEditToAcceptModalOpen}
        onClose={this.handleFinanceEditToAcceptModalClose}
        basic
        // closeOnEscape={false}
        // closeOnDimmerClick={false}
        // size='small'
        >

        <Modal.Content>
        <FinanceEditToAccept
        id={this.state.id}
        month={this.props.accountMonth}
        handleFinanceEditToAcceptModalCloseSubmit={this.handleFinanceEditToAcceptModalCloseSubmit}
        handleFinanceEditToAcceptModalClose= {this.handleFinanceEditToAcceptModalClose}
        />

        </Modal.Content>

        </Modal>
        
        </div >
    {/**/}
        <div className='nodisplay'>

        <Container  >
                    {/**/}
                    <div className='cashbook-H border-sb-top'>


                    <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-10 ml-10 border1' > <Icon name='refresh' />Refresh</span >
                </div>

            <div  style={{display: 'flex',flex: '80%',justifyContent: 'center',}} >
            <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              {accName!== undefined && accName.accountName}'s  AllFinance # {this.props.accountMonth}
            </div>
            </div> 

            <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleSearchSingleAccountFinance}>
                  <span className='icon-button px-10 border1' > <Icon name='search' />Search</span >
                </div>


            </div>
            {/**/}
          <Segment  attached
          style={{color:'black'}}
          >
          <div >
            <Label color={colorRibbon} ribbon="right"  >
            {" "}
              {EntryType()}
            </Label>
            </div>
            <div >
              {this.renderButtonGroup()}
              {/* {this.renderSelectDeselectAllButton(selec)} */}

            </div>

            {userA.userType === "Shop Owner" && (<div style={{marginTop:'15px'}} >
              <span >
              <Popup trigger={<span className="border-circle mr-05">?</span>
              } wide='very'>
              <b>Overall Total Amount Payable or Receivable </b>
              </Popup>
  <b><span>Total Amount{' '}{renderPayableReceivableTotal()}</span> : <Icon className="rupee" ></Icon>{Math.abs(BalanceAllTotal)} {' '}
      <span style={{color:'blue'}} > {' '}( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAllTotal), {
      lang: "enIndian"
    }))} )</span></b>

    </span>
              </div>)}
              <Divider/>
{/**/}
<div style={{marginTop:'15px'}} >
  <span >
  <Popup trigger={<span className="border-circle mr-05">?</span>
  } wide='very'>
  <b>Net Amount Payable or Receivable For current Search Criteria</b>
  </Popup>
<b><span>Net Amount {' '}{renderPayableReceivable()}{' '}</span> : <Icon className="rupee" ></Icon>{Math.abs(BalanceAll)}{' '}
<span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
lang: "enIndian"
}))} )</span></b>

</span>
  </div>

{/**/}
{/*{renderNetAmount()}*/}
            {/* {this.renderLoading()} */}
            <Table compact="very" celled striped >
              <Table.Header>
                <Table.Row>
                
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Entry Type</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                
                <Table.HeaderCell>Amount <Icon className="rupee" ></Icon></Table.HeaderCell>

                <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
              {AllFin.map(this.renderList)}
              {this.renderNoEntry()}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                

                <Table.HeaderCell><span className='color-date'>
                <Popup trigger={<span className="border-circle mr-05">?</span>
                } wide='very'>
                <b>Total of the all entries For current Search Criteria</b>
                </Popup>
                <b>Grand Total</b></span></Table.HeaderCell>
                <Table.HeaderCell><b><Icon className="rupee" ></Icon>{this.props.sumGTotalAll} </b></Table.HeaderCell>
                <Table.HeaderCell><span style={{color:'teal'}}>

                <Popup trigger={<span className="border-circle mr-05">?</span>
                } wide='very'>
                <b>Total of the all entries on the current page</b>
                </Popup>

                <b>Total</b></span></Table.HeaderCell>
                <Table.HeaderCell><b><Icon className="rupee" ></Icon>{sumT}</b></Table.HeaderCell>

                  <Table.HeaderCell />

                  {/*<Table.HeaderCell />*/}


                </Table.Row>
              </Table.Footer>
            </Table>

          </Segment>
          <Segment style={{ marginLeft: '0px' }}>
          <div className='disflex-paginator '>
{this.renderPaginator()}
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
  <form>
<input
className='inputPage'
placeholder="number"
name="pageO"
type="number"
value={this.state.pageO}
onChange={this.onChange}
/>
<span  className="btnNumber"  onClick={this.handleGoToPage} >
Submit
</span>
</form>

</div>

</div>
          </Segment>
        </Container>
</div>

<div className='nodisplay-tab'>

        <Container text className='marginBottom-25'>

          {/**/}
          <div className='cashbook-H'>

          <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-05 ml-05 border1' > <Icon name='refresh' /></span >
                </div>

        <div  style={{display: 'flex',flex: '80%',justifyContent: 'center',}} >
        <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {accName!== undefined && accName.accountName}'s  AllFinance # {this.props.accountMonth}
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
       onClick={this.handleSearchSingleAccountFinance}>
              <span className='icon-button px-05 border1' > <Icon name='search' /></span >
            </div>


       </div>
       {/**/}
          <Segment  attached
          //style={{color:'black'}}
          >

            <div>
              {this.renderButtonGroup()}
               
            </div>

            {/*    */}
        { userA.userType === "Shop Owner" &&  ( <div className="font-18B" >
              <span >
              <Popup trigger={<span className="border-circle mr-05">?</span>
              } wide='very'>
              <b>Overall Total Amount Payable or Receivable </b>
              </Popup>

              <b><span>Total Amount{' '}{renderPayableReceivableTotal()}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAllTotal)}{' '}
                  <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAllTotal), {
                  lang: "enIndian"
                }))} )</span></b>

      </span>
              </div>)}
      {/*{renderNetAmountMobile()}*/}


  </Segment>
              {/* {this.renderLoading()} */}
                {AllFin.map(this.renderListMobile)}
                {this.renderNoEntry()}


          <Segment 
          style={{color:'black'}}
          >
          <div className='display-flex-fin font-18B'>
          <div className='sideF'>

          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Net Amount Payable or Receivable For current Search Criteria</b>
          </Popup>

          <b><span>Net Amount {' '}{renderPayableReceivable()}{' '}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAll)}{' '}
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
          lang: "enIndian"
          }))} )</span></b></div>
          <Divider/>
          <div className='sideF'>

          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries on the current page</b>
          </Popup>

          {' '}<b><span>Total : <Icon className="rupee" ></Icon>{sumT}{' '} </span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(sumT), {
          lang: "enIndian"
          }))} )</span>
          </b></div>
          <Divider/>
          <div className='sideF' >
          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries For current Search Criteria</b>
          </Popup>

          {' '}<b><span style={{color:'blue'}}>Grand Total </span> : <span> <Icon className="rupee" ></Icon>{this.props.sumGTotalAll}{' '} </span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(this.props.sumGTotalAll !==undefined && this.props.sumGTotalAll), {
          lang: "enIndian"
          }))} )</span>
          </b></div>

          </div> 

        

          </Segment>

{/*  */}
      
<div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator" >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      {/*  */}
      
      {/* <AdFindprop
      className1={"font-18B color-aqua pxy-10 font-family-bellMT"}
      className2={"font-18B color-aqua pxy-10 font-family-Calibri"}
      /> */}
      {/*  */}

        </Container>
        </div>
        </div>
      );
    }

    
}


const mapStateToProps = (state) => {
  const {accountMonthSA,noEntry, fetching, offsetAllSA,limit, countSA } = state.finances;

  return {
    state,
    fetching,
    noEntry,
    limit,
    offset:offsetAllSA,
    count:countSA,
    form:state.form.SingleAccountFinanceSearch,
    userA: state.auth.userA,
    usersG: state.auth.usersG,
    accountMonth:accountMonthSA,
    //month: (state.commons.month!== null && state.commons.month) ,
    selections: state.finances.selections,
    allFinances: state.finances.allFinancesSA,
    sumGTotalAll: state.finances.sumGTotalAllSA,
    BalanceAll: state.finances.BalanceAllSA,
    BalanceAllTotal: state.finances.BalanceAllTotalSA,
  };
};

export default connect(
  mapStateToProps,
{  ...actions,fetchMyGroupUsers,openModal,transport,resetForm,}
)(AccountDetail);
