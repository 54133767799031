
import React, { Component } from "react";
//import { Link } from "react-router-dom";

import { connect } from "react-redux";
import format from "date-fns/format";
import writtenNumber from "written-number";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import Paginator from "../paginator/Paginator";
import { resetForm  , } from "../../actions/commonAction";
import { openModal } from "../../actions/modalAction";
import * as actions from "../../actions/financeAction"; 
import {renderMonth,capitalizeFirst,
  //renderPreviousMonth,
} from "../functions/Functions";
import { getMonth,getYear } from 'date-fns'

import {
  Table,
  Container, 
  Divider,
  Segment,
  //Input,
  Button,
  Label,
  Popup,
  //Confirm,
  Icon,
  Modal,
} from "semantic-ui-react";
import AllFinanceSA from './AllFinanceSA'
//import AdFindprop from "../advertisement/AdFindprop";

class AllFinance extends Component {
  state = {
    pageO:'',
    id: "",
    accountId:'',
    month:'',
    open: false,
    result: "show the modal to capture a result",
    renderType: "Payment",
    allFinanceSAModalOpen: false,
    monthNum:getMonth(new Date()),
      year:getYear(new Date()),
      monthWord:''
  };

  componentDidMount() {
    
    const {limit} = this.props
    const values = { month:renderMonth(this.state.monthNum), ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  };
    this.props.searchAllFinance(values,limit);

    const valuesT = { month:renderMonth(this.state.monthNum), ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'total'  };
    this.props.searchAllFinance(valuesT,limit);

    const valuesTAll = { month:renderMonth(this.state.monthNum), ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalAll'  };
    this.props.searchAllFinance(valuesTAll,limit);
    
    const valuesTGT = { month:renderMonth(this.state.monthNum), ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalGT'  };
    this.props.searchAllFinance(valuesTGT,limit);
  }
  componentWillUnmount(){
    this.props.resetForm('AllFinanceSearch')
    this.props.resetLoading()
  }

  handleSearchAllFinance = () => {
    this.props.openModal("SearchAllFinanceModal", {renderType:this.state.renderType});
  };

 
  // @@@
  
  show = id => {
    this.setState({ open: true, id: id });
  };


  renderEntryType = () => {
    if(this.state.renderType === 'Payment'){
      return {entryTypeA: ["Payment", 'Sale','Purchase Return']}
    }
    if(this.state.renderType === "Receipt"){
      return {entryTypeA: ["Receipt", 'Purchase','Sale Return'] }
    }
  }


  handleRefresh = ()=>{
    const {offset, limit} = this.props;
    const values = {  month:this.props.accountMonthA,...(this.props.form !== undefined && this.props.form.values) ,   ...this.renderEntryType()  }
    this.props.searchAllFinance(values, limit,offset)
  }

handleRefreshAll = ()=>{
  const {offset, limit} = this.props;
  const values = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,   ...this.renderEntryType()  }
  this.props.searchAllFinance(values, limit,offset)

  const valuesT = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,   ...this.renderEntryType() ,search: 'total'  }
  this.props.searchAllFinance(valuesT, limit,offset)

  const valuesTGT = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  ...this.renderEntryType() ,search: 'totalGT'  }
  this.props.searchAllFinance(valuesTGT, limit,offset)
  
}


 handleConfirm = async () => {
    const did = this.state.id;

//************
  try {
    const res = await axios.delete(`/api/FinanceDelete/${did}`);

    if(res.data.msg ==='success'){
      this.handleRefreshAll()
      this.setState({ result: "confirmed", open: false });
      toastr.success('Deleted Successfully')
      }
    }catch (error) {
   toastr.error("Oops", "Something went wrong.Please Try Again");
  }
//**********
  };
  handleCancel = () => this.setState({ result: "cancelled", open: false });
//**********************************
  handleEditModalOpen = (id) => this.setState({ editModalOpen:true, id:id })
  handleEditModalClose = () =>{
     this.setState({ editModalOpen:false })
     this.props.resetFinance()
   }  //
  handleEditModalCloseSubmit = () =>{
    this.handleRefreshAll()
     this.props.resetFinance()
     this.setState({ editModalOpen:false })
  }
  //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleAllFinanceSAModalOpen = (accountId,month) => this.setState({ allFinanceSAModalOpen:true, accountId,month});
handleAllFinanceSAModalClose = () => {
  this.setState({ allFinanceSAModalOpen:false, })
};
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
handleFinanceEditToAcceptModalOpen = (id) => this.setState({ financeEditToAcceptModalOpen:true, id:id })
handleFinanceEditToAcceptModalClose = () =>{
   this.setState({ financeEditToAcceptModalOpen:false })
   this.props.resetFinance()
 }  //
handleFinanceEditToAcceptModalCloseSubmit = () =>{
  this.handleRefresh()
   this.props.resetFinance()
   this.setState({ financeEditToAcceptModalOpen:false })
}

    //***********************************
renderPayment = () => {
  const {limit} = this.props;
  this.setState({renderType: "Payment",},
  () => {
    const values = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  };
    const valuesTGT = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  ,search: 'totalGT'  };
    
    this.props.searchAllFinance(values,limit);
    this.props.searchAllFinance(valuesTGT,limit);
  }
  
  )
};
renderReceipt = () => {
  const {limit} = this.props;
  this.setState({renderType: "Receipt",},
  () => {
    const values = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  };
    this.props.searchAllFinance(values,limit);

  const valuesTGT = {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,   entryTypeA: ["Receipt", 'Purchase','Sale Return'] ,search: 'totalGT'  };
  
    this.props.searchAllFinance(valuesTGT,limit);
  }
  
  )
};


//**************************************
renderButtonGroup =()=>{
  
  return ( 
<div className = 'display-start'>
    <span className="icon3-button">
    <Button
    style={{margin:'0px'}}
        disabled={this.state.renderType === 'Payment'? true:false}
       // floated="right"
        color="blue"
        type="button"
        onClick={this.renderPayment}
      >
        OUT ( Cr )
      </Button>
    </span>
    <span className="icon3-button" >
    <Button
    style={{margin:'0px'}}
        disabled={this.state.renderType === 'Receipt'? true:false}
        //floated="left"
        color="orange"
        type="button"
        onClick={this.renderReceipt}
      >
        IN ( Dr )
      </Button>
    </span>
                    
  </div>

  )
  
}

//**************************************
 renderButton = (deleteEntry,id) =>{
if(  this.props.userA !== null &&
  (this.props.userA.allFinanceDelete === "Yes" ||
    this.props.userA.userType === "admin") ){
      if(deleteEntry ===false){
        return (
            <span></span>
      )
    }else{
      return (
        <span
        onClick={() => this.show(id)}
        className="icon-button"
        >
        <img className= 'yimg ' src='/deleteIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>

    )
    }
  }else{
    return(
      <span></span>
    )
  }
}

  //******************
   
  
   renderCheckedStatusButton = (entryType,checkedStatus,id) =>{
    if(checkedStatus==='Not Checked'){
      if(entryType==='Payment'){
        return (
          <span
          // onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOnHold.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
      if(entryType==='Receipt'){
        return (
          <span
          onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOnHold.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }

    }
    if(checkedStatus==='Checked Ok'){
      if(entryType==='Payment'){
        return (
          <span
        //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(_id)}
          className="icon-button"
          >
          <img className= {this.nameClass(entryType)} src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
      if(entryType==='Receipt'){

          return (
            <span
            onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
            className="icon-button"
            >
            <img className= {this.nameClass(entryType)} src='/statusIconOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
            </span>
          )
          }
    }
    if(checkedStatus==='Checked Not Ok'){
      if(entryType==='Payment'){
        return (
          <span
        //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button "
          >
          <img className= {this.nameClass(entryType)} src='/statusIconNotOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }

      if(entryType==='Receipt'){
        return (
          <span
          onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button "
          >
          <img className= {this.nameClass(entryType)} src='/statusIconNotOk.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
    }
    if(checkedStatus==='Unlocked'){
      if(entryType==='Payment'){
        return (
          <span
        //  onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button "
          >
          <img className= {this.nameClass(entryType)} src='/statusIconInProgress.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }

      if(entryType==='Receipt'){
        return (
          <span
          onClick={()=>this.handleFinanceEditToAcceptModalOpen(id)}
          className="icon-button "
          >
          <img className= {this.nameClass(entryType)} src='/statusIconInProgress.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
          </span>
        )
      }
    }
    //^^^^^^^^^^^ last bracket
  }


  //^^^^^^^^^^^^^^^
   nameClass =(entryType)=>{
    if(entryType==='Payment'){
      return 'yimg btn-disabled'
    }
    if(entryType==='Receipt'){
      return 'yimg '
    }
  }
  // ^^^^^^^^^^^^^^^
  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
//*******************************************************
  renderList=(finance)=> {
    const {
      entryType,
     // entryFor,
      description,
      //accountName,
      accountId,
      amount,
      entryDate,
     
      // checkedStatus,
      // deleteEntry,
      _id
    } = finance;

    const userType = this.props.userA !== null && this.props.userA.userType
  const accountDetail = this.props.usersG !== undefined && this.props.usersG !== null && this.props.usersG !==undefined && this.props.usersG.filter(val => {

      return val._id === accountId.toString();
    })[0];
    
                // //^^^^^^^^^^^^^^^
    return (
      <Table.Row  key={_id}>
        <Table.Cell>{format(new Date(entryDate), "MMM d yyyy")}</Table.Cell>
        <Table.Cell>
          <>
          <span 
        className="color-indigo font-14B underline cursor-pointer"
        onClick={()=>this.props.openModal('UserDetailModal',{id:accountId})}
        > 
         {userType==='Shop Owner'? accountDetail!== undefined && accountDetail.uname : accountDetail!== undefined && accountDetail.shopName}{' '}({accountDetail!== undefined && accountDetail.address}{' '}{userType==='Shop Owner'? '#'.concat(accountDetail!== undefined && accountDetail.userId):''})
        </span>
        </>
        
        </Table.Cell>
        <Table.Cell>{description && capitalizeFirst(description)}</Table.Cell>
        
        <Table.Cell>{amount}</Table.Cell>
        <Table.Cell>{entryType}</Table.Cell>
        <Table.Cell>
        <div className='disflex-paginator'>
        <span
        className="icon-button ml-20"
        onClick={()=>this.handleAllFinanceSAModalOpen(accountId,this.props.accountMonthA)}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
        
          </div>
        </Table.Cell>
      </Table.Row>
    );
  }
//*********************************** for mobile
renderListMobile=(finance)=> {
  const {
    entryType,
    //entryFor,
    description,
    accountName,
    accountId,
    amount,
    entryDate,
   
    // checkedStatus,
    // deleteEntry,
    _id
  } = finance;

  const userType = this.props.userA !== null && this.props.userA.userType
  const accountDetail = this.props.usersG !== undefined && this.props.usersG !== null && this.props.usersG !==undefined && this.props.usersG.filter(val => {

    return val._id === accountId.toString();
  })[0];
  
  const colorRibbon = ((entryType === 'Payment')||(entryType === 'Payment (Contra)')||(entryType === 'Sale')||(entryType === 'Purchase Return')) ? 'blue': 'teal';
  
    //******************


  return (
    <Segment key={_id}>
      <Label color={colorRibbon} ribbon>
{entryType}
      </Label>
      
      <span
        className="icon-button ml-20 mb-20"
        onClick={()=>this.handleAllFinanceSAModalOpen(accountId,this.props.accountMonthA)}
        >
        <img className= 'yimg ' src='/inrIcon.jpg' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
        </span>
      

      <Divider fitted />

      <div >
      {entryDate && (
        <div className='row'>
        <div className='side'>
          <b>Date</b>
        </div>
        <div className='main'>

        <b>{format(new Date(entryDate), "MMM d yyyy")}
        </b>
        </div>

        </div>
      )}
      </div>

      <div  >
      {entryType && (
        <div className='row'>
        <div className='side'>
          <b>Entry Type</b>
        </div>
        <div className='main color-date'>

        <b>{entryType}
        </b>
        </div>

        </div>
      )}
      </div>

      <div >
      {accountName && (
        <div className='row'>
        <div className='side'>
          <b>Account Name</b>
        </div>
        <div 
        onClick={()=>this.props.openModal('UserDetailModal',{id:accountId})}
        className='main'>
          <b>{<span className="font-18B color-indigo"
        > 
         
         {userType==='Shop Owner'? accountDetail!== undefined && accountDetail.uname : accountDetail!== undefined && accountDetail.shopName}{' '}({accountDetail!== undefined && accountDetail.address}{' '}{userType==='Shop Owner'? '#'.concat(accountDetail!== undefined && accountDetail.userId):''})
        
        </span>}</b>
        
        </div>

        </div>
      )}
      </div>

      <div >
      {description && (
        <div className='row'>
        <div className='side'>
          <b>Description</b>
        </div>
        <div className='main'>
          <b>{capitalizeFirst(description)}</b>
        </div>

        </div>
      )}
      </div>

      

      <div >
      {amount && (
        <div className='row'>
        <div className='side'>
          <b>Amount INR</b>
        </div>
        <div className='main'>
          <b>{amount}{' '}(   {capitalizeFirst(writtenNumber(amount, {
      lang: "enIndian"
    }))}  )
          {" "}</b>
        </div>

        </div>
      )}
      </div>


    </Segment>
  );
}

//**********************
  back = () => {
    const {
      offset,
      limit

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }


    if (offset === 0) {
      return;
    }

    this.props.searchAllFinance(values, limit, (offset - limit));
    window.scroll(0,11000)
  };

  advance = () => {
    const {
      offset,
      limit,
      count

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }

    if (offset + limit > count) {
      return;
    }

    this.props.searchAllFinance(values, limit, (offset + limit));
    window.scroll(0,0)
  };
  handleGoToPage = () => {
    const {

      limit,
      count

    } = this.props;

    const values = (this.state.renderType === 'Payment') ?
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Payment", 'Sale','Purchase Return']  }:
     {  month:this.props.accountMonthA, ...(this.props.form !== undefined && this.props.form.values) ,  entryTypeA: ["Receipt", 'Purchase','Sale Return']  }

      // use Math.abs for strict equality ===  to work and safe guard against negative value from user
      if (Math.abs(this.state.pageO) === 0) {
        return;
      }

    const page=(Math.abs(this.state.pageO*limit) > count)?(Math.ceil(count/limit-1)):(Math.abs(this.state.pageO)-1)


    this.props.searchAllFinance(values, limit, (page * limit));
      window.scroll(0,0)

  };

  //****************** */
  renderPaginator() {
    if (this.props.allFinances!== undefined && this.props.allFinances.length) {
      return (
        <Paginator
          advance={this.advance}
          back={this.back}
          offset={this.props.offset}
          limit={this.props.limit}
          count={this.props.count}
        />
      );
    }
  }


    renderLoading() {
      if (this.props.fetching===true) {
        return (
          <Container text>
        <div><span style={{marginLeft: '200px',fontSize: "18px",color: "teal"}}><b>Loading...</b></span>

        </div>
        </Container>
        );
      }
    }
    renderNoEntry() {
      if (this.props.noEntry===true) {
        return (
          <>
          <Table.Row className="nodisplay-at-mobile disflex-center">
            <Table.Cell>
            <span className="font-20B color-dodgerblue">No Entry Exist</span>
            </Table.Cell>
          </Table.Row>
        <Segment className="nodisplay-at-tab disflex-center"><span className="font-20B color-dodgerblue">No Entry Exist</span></Segment>
        </>
        );
      }
    }
  //########## RENDER ###############################
  render() {

    
const BalanceAll = this.props.BalanceAll !==undefined && this.props.BalanceAll
const BalanceAllTotal = this.props.BalanceAllTotal !==undefined && this.props.BalanceAllTotal

const colorRibbon = this.state.renderType === 'Payment' ? 'blue': 'teal'
const EntryType = ()=>{
  if(this.state.renderType === 'Payment'){
    return 'OUT'
  } 
  if(this.state.renderType === 'Receipt'){
    return 'IN'
  }

}
//**********************************
const renderPayableReceivable =()=>
( BalanceAll < 0 ) ? (
<span style={{color:'red'}}><b> Receivable</b></span>
) : (
  <span style={{color:'blue'}}><b> Payable</b></span>
);
const renderPayableReceivableTotal =()=>
( BalanceAllTotal < 0 ) ? (
<span style={{color:'red'}}><b> Receivable</b></span>
) : (
  <span style={{color:'blue'}}><b> Payable</b></span>
);
// const renderMinusWord =()=>
// ( BalanceAll < 0 ) ? (
// <span style={{color:'red'}}><b> minus</b></span>
// ) : (
//   <span></span>
// );
// const renderNumber =()=>
// ( BalanceAll < 0 ) ? (
// <span style={{color:'red'}}><b> {''}{BalanceAll}{' '}</b></span>
// ) : (
//   <span>{''}{BalanceAll}{' '}</span>
// );
//*********************************
    let sumT =this.props.allFinances!== undefined && this.props.allFinances.reduce((acc, val) => acc + val.amount, 0);

    //const { open,  } = this.state;

    
      return (
        <div >
       
{/* AllFinanceSA Modal*/}
<Modal
        open={this.state.allFinanceSAModalOpen}
        onClose={this.handleAllFinanceSAModalClose}
        basic
        closeOnEscape={false}
        closeOnDimmerClick={false}
        style={{margin:'0px',padding:'0px'}}
        >

        <Modal.Content>
        <div className="mb-10"
style={{color:'white'}}
>
     
      <button

onClick={this.handleAllFinanceSAModalClose}

className='back-btn color-white'
>
<Icon name="arrow left" />

</button>
    </div>
        <AllFinanceSA 
        accountId = {this.state.accountId}
        month = {this.state.month} 
        handleAllFinanceSAModalClose = {this.handleAllFinanceSAModalClose}
       
        />

        </Modal.Content>
        </Modal>

         {/* AllFinanceSA Modal end*/}
    
    
        <div className='nodisplay'>

        <Container  >
                    {/**/}
                    <div className='cashbook-H'>

                    <div
            style={{display: 'flex',flex: '15%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-10 border1' > <Icon name='refresh' />Refresh</span >
                </div>

            <div  style={{display: 'flex',flex: '75%',justifyContent: 'center',}} >
            <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
              AllFinance # {this.props.accountMonthA}
            </div>
            </div>

            <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleSearchAllFinance}>
                  <span className='icon-button px-10 border1' > <Icon name='search' />Search</span >
                </div>


            </div>
            {/**/}
          <Segment  attached>
          <div >
            <Label color={colorRibbon} ribbon="right"  >
            {" "}
              {EntryType()}
            </Label>
            </div>
            <div>
              {this.renderButtonGroup()}
               
            </div>
            
            

            <div style={{marginTop:'15px'}} >
              <span >
              <Popup trigger={<span className="border-circle mr-05">?</span>
              } wide='very'>
              <b>Overall Total Amount Payable or Receivable </b>
              </Popup>
  <b><span>Total Amount{' '}{renderPayableReceivableTotal()}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAllTotal)} {' '}
      <span style={{color:'blue'}} > {' '}( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAllTotal), {
      lang: "enIndian"
    }))} )</span></b>

    </span>
              </div>
              <Divider/>
{/**/}
<div style={{marginTop:'15px'}} >
  <span >
  <Popup trigger={<span className="border-circle mr-05">?</span>
  } wide='very'>
  <b>Net Amount Payable or Receivable For current Search Criteria</b>
  </Popup>
<b><span>Net Amount {' '}{renderPayableReceivable()}{' '}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAll)}{' '}
<span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
lang: "enIndian"
}))} )</span></b>

</span>
  </div>

{/**/}

            {this.renderLoading()}
            <Table compact="very" celled striped >
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>A/C Name</Table.HeaderCell>
                <Table.HeaderCell>Description</Table.HeaderCell>
                {/* <Table.HeaderCell>Bank A/C Name</Table.HeaderCell>
                <Table.HeaderCell>Transaction Type</Table.HeaderCell>
                <Table.HeaderCell>Entry Ref</Table.HeaderCell> */}
                <Table.HeaderCell>Amount <Icon className="rupee" ></Icon></Table.HeaderCell>
                <Table.HeaderCell>Entry Type</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>

                {this.props.allFinances!== undefined && this.props.allFinances.map(this.renderList)}
                {this.renderNoEntry()} 
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                <Table.HeaderCell />
                  {/* <Table.HeaderCell />

                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell> */}


                  <Table.HeaderCell><span className='color-date'>
                  <Popup trigger={<span className="border-circle mr-05">?</span>
                  } wide='very'>
                  <b>Total of the all entries For current Search Criteria</b>
                  </Popup>
                  <b>Grand Total</b></span></Table.HeaderCell>
                  <Table.HeaderCell><b><Icon className="rupee" ></Icon>{this.props.sumGTotalAll}</b></Table.HeaderCell>
                  <Table.HeaderCell><span style={{color:'teal'}}>

                  <Popup trigger={<span className="border-circle mr-05">?</span>
                  } wide='very'>
                  <b>Total of the all entries on the current page</b>
                  </Popup>

                  <b>Total</b></span></Table.HeaderCell>
                  <Table.HeaderCell><b><Icon className="rupee" ></Icon>{sumT}</b></Table.HeaderCell>

                  <Table.HeaderCell />
                </Table.Row>
              </Table.Footer>
            </Table>

          </Segment>
          <Segment style={{ marginLeft: '0px' }}>
          <div className='disflex-paginator '>
{this.renderPaginator()}
<div className="disflex-paginator mb-0">
  <div className= 'font-paginator'>Go To Page</div>
  <form>
<input
className='inputPage'
placeholder="number"
name="pageO"
type="number"
value={this.state.pageO}
onChange={this.onChange}
/>
<span  className="btnNumber"  onClick={this.handleGoToPage} >
Submit
</span>
</form>

</div>

</div>
          </Segment>
        </Container>
</div>

<div className='nodisplay-tab'>

        <Container text className='marginBottom-25'>

          {/**/}
          <div className='cashbook-H'>
          <div
            style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
            onClick={this.handleRefresh}>
                  <span className='icon-button px-05 ml-05 border1' > <Icon name='refresh' /></span >
                </div>

        <div  style={{display: 'flex',flex: '80%',justifyContent: 'center',}} >
        <div   style={{display: 'flex',justifyContent: 'center',fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        All Finance # {this.props.accountMonthA}
       </div>
       </div>

       <div
       style={{display: 'flex',flex: '10%',justifyContent: 'center',alignItems: 'center',}}
       onClick={this.handleSearchAllFinance}>
              <span className='icon-button px-05 border1' > <Icon name='search' /></span >
            </div>


       </div>
       {/**/}
          <Segment  attached>

          <div>
              {this.renderButtonGroup()}
               
            </div>
          

            
            {/*    */}
            <div className="font-18B" >
              <span >
              <Popup trigger={<span className="border-circle mr-05">?</span>
              } wide='very'>
              <b>Overall Total Amount Payable or Receivable </b>
              </Popup>

              <b><span>Total Amount{' '}{renderPayableReceivableTotal()}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAllTotal)}{' '}
                  <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAllTotal), {
                  lang: "enIndian"
                }))} )</span></b>

      </span>
              </div>

  </Segment>
              {this.renderLoading()}
                {this.props.allFinances!== undefined && this.props.allFinances.map(this.renderListMobile)}
                {this.renderNoEntry()}

          <Segment >
          <div className='display-flex-fin font-18B'>
          <div className='sideF'>

          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Net Amount Payable or Receivable For current Search Criteria</b>
          </Popup>

          <b><span>Net Amount {' '}{renderPayableReceivable()}{' '}</span> :  <Icon className="rupee" ></Icon>{Math.abs(BalanceAll)}{' '}
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(BalanceAll), {
          lang: "enIndian"
          }))} )</span></b></div>
          <Divider/>
          <div className='sideF'>

          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries on the current page</b>
          </Popup>

          {' '}<b><span>Total : <Icon className="rupee" ></Icon>{sumT}{' '}</span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(sumT), {
          lang: "enIndian"
          }))} )</span>
          </b></div>
          <Divider/>
          <div className='sideF' >
          <Popup trigger={<span className="border-circle mr-05">?</span>
          } wide='very'>
          <b>Total of the all entries For current Search Criteria</b>
          </Popup>

          {' '}<b><span style={{color:'blue'}}>Grand Total </span> : <span> <Icon className="rupee" ></Icon>{this.props.sumGTotalAll}{' '}</span>
          <span style={{color:'blue'}} >( {' '}{capitalizeFirst(writtenNumber(Math.abs(this.props.sumGTotalAll !==undefined && this.props.sumGTotalAll), {
          lang: "enIndian"
          }))} )</span>
          </b></div>

          </div>

        

          </Segment>

{/*  */}
      
<div className='disflex-paginator card-paginator media-600'>
      {this.renderPaginator()}
      <div className="disflex-paginator" >
        <div className= 'font-paginator'>Go To Page</div>
        <form>
              <input
              className='inputPage'
                placeholder="number"
                name="pageO"
                type="number"
                value={this.state.pageO}
                onChange={this.onChange}
              />
              <span  className="btnNumber"  onClick={this.handleGoToPage} >
                Submit
              </span>
            </form>
      
              
      </div>
      <br/>
      </div>
      
      {/*  */}
      
      {/* <AdFindprop
      className1={"font-18B color-aqua pxy-10 font-family-bellMT"}
      className2={"font-18B color-aqua pxy-10 font-family-Calibri"}
      /> */}
      {/*  */}

        </Container>
        </div>
        </div>
      );
    }

    
}


const mapStateToProps = (state) => {
const {accountMonthA,noEntry, fetching, offsetAll,limit, countAll } = state.finances;

  return {
    fetching,
    noEntry,
    limit,
    offset:offsetAll,
    count:countAll,
    form:state.form.AllFinanceSearch,
    accountMonthA:accountMonthA, 
    usersG: state.auth.usersG,
    userA: state.auth.userA,
    allFinances: state.finances.allFinances,
    sumGTotalAll: state.finances.sumGTotalAll,
    BalanceAll: state.finances.BalanceAll,
    BalanceAllTotal: state.finances.BalanceAllTotal, 
  };
};

export default connect(
  mapStateToProps,
{  ...actions,openModal,resetForm,  }
)(AllFinance);
