
//COMMON action Types
export const TRANSPORT = 'TRANSPORT';
export const MESSAGE = 'MESSAGE';
//companys action Types
export const SEARCH_COMPANYS = 'SEARCH_COMPANYS';
export const CREATE_COMPANYS = 'CREATE_COMPANYS';
export const FETCH_COMPANYS = 'FETCH_COMPANYS';
export const FETCH_COMPANYSBLOCKED = 'FETCH_COMPANYSBLOCKED';
export const FETCH_COMPANYSUNBLOCKED = 'FETCH_COMPANYSUNBLOCKED';
export const FETCH_COMPANY = 'FETCH_COMPANY';
export const DELETE_COMPANY = 'DELETE_COMPANY';
export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const PROPREQ_COUNT = 'PROPREQ_COUNT';

export const SELECT_COMPANY = 'SELECT_COMPANY';
export const DESELECT_COMPANY = 'DESELECT_COMPANY';
export const FETCHING_COMPANYS = 'FETCHING_COMPANYS';
//##############################################
export const RESET_SELECTION = 'RESET_SELECTION';
//##############################################


//finance action Types
export const SEARCH_FINANCES = 'SEARCH_FINANCES';
export const CREATE_FINANCES = 'CREATE_FINANCES';
export const FETCH_FINANCES = 'FETCH_FINANCES';
export const FETCH_TODAYFINANCES = 'FETCH_TODAYFINANCES';
export const FETCH_FINANCE = 'FETCH_FINANCE';
export const FETCH_RECEIVABLE_PAYABLE = 'FETCH_RECEIVABLE_PAYABLE';
export const FETCH_RECEIVABLE_PAYABLE_INTEREST = 'FETCH_RECEIVABLE_PAYABLE_INTEREST';

export const DELETE_FINANCE = 'DELETE_FINANCE';
export const UPDATE_FINANCE = 'UPDATE_FINANCE';
export const SELECT_FINANCE = 'SELECT_FINANCE';
export const SELECT_FINANCEALL = 'SELECT_FINANCEALL';
export const DESELECT_FINANCEALL = 'DESELECT_FINANCEALL';
export const DESELECT_FINANCE = 'DESELECT_FINANCE';
export const FETCHING_FINANCES = 'FETCHING_FINANCES';

//BOOKNAME action Types
export const SEARCH_BOOKNAMES = 'SEARCH_BOOKNAMES';
export const CREATE_BOOKNAMES = 'CREATE_BOOKNAMES';
export const FETCH_BOOKNAMES = 'FETCH_BOOKNAMES';
export const FETCH_BOOKNAME = 'FETCH_BOOKNAME';
export const DELETE_BOOKNAME = 'DELETE_BOOKNAME';
export const UPDATE_BOOKNAME = 'UPDATE_BOOKNAME';

//accountType action Types
export const SEARCH_ACCOUNTTYPES = 'SEARCH_ACCOUNTTYPES';
export const CREATE_ACCOUNTTYPES = 'CREATE_ACCOUNTTYPES';
export const FETCH_ACCOUNTTYPES = 'FETCH_ACCOUNTTYPES';
export const FETCH_ACCOUNTTYPE = 'FETCH_ACCOUNTTYPE';
export const DELETE_ACCOUNTTYPE = 'DELETE_ACCOUNTTYPE';
export const UPDATE_ACCOUNTTYPE = 'UPDATE_ACCOUNTTYPE';

//accountName action Types

export const CREATE_ACCOUNTNAMES = 'CREATE_ACCOUNTNAMES';
export const FETCH_ACCOUNTNAMES = 'FETCH_ACCOUNTNAMES';
export const FETCH_ACCOUNTNAME = 'FETCH_ACCOUNTNAME';
export const DELETE_ACCOUNTNAME = 'DELETE_ACCOUNTNAME';
export const UPDATE_ACCOUNTNAME = 'UPDATE_ACCOUNTNAME';
//accountRef action Types

export const CREATE_ACCOUNTREFS = 'CREATE_ACCOUNTREFS';
export const FETCH_ACCOUNTREFS = 'FETCH_ACCOUNTREFS';
export const FETCH_ACCOUNTREF = 'FETCH_ACCOUNTREF';
export const DELETE_ACCOUNTREF = 'DELETE_ACCOUNTREF';
export const UPDATE_ACCOUNTREF = 'UPDATE_ACCOUNTREF';

//NOTE action Types
//export const SEARCH_NOTES = 'SEARCH_NOTES';
export const CREATE_NOTES = 'CREATE_NOTES';
export const FETCH_NOTES = 'FETCH_NOTES';
export const FETCH_NOTE = 'FETCH_NOTE';
export const DELETE_NOTE = 'DELETE_NOTE';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const FETCHING_NOTES = 'FETCHING_NOTES';
//CONTACT action Types
export const SEARCH_CONTACTS = 'SEARCH_CONTACTS';
export const CREATE_CONTACTS = 'CREATE_CONTACTS';
export const FETCH_CONTACT = 'FETCH_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const FETCHING_CONTACTS = 'FETCHING_CONTACTS';
//BORROWEDITEMS action Types
export const SEARCH_BORROWEDITEMS = 'SEARCH_BORROWEDITEMS';
export const SELECT_BORROWEDITEM = 'SELECT_BORROWEDITEM';
export const DESELECT_BORROWEDITEM = 'DESELECT_BORROWEDITEM';
export const CREATE_BORROWEDITEMS = 'CREATE_BORROWEDITEMS';
export const FETCH_BORROWEDITEMS = 'FETCH_BORROWEDITEMS';
export const FETCH_TODAYBORROWEDITEMS = 'FETCH_TODAYBORROWEDITEMS';
export const FETCH_BORROWEDITEM = 'FETCH_BORROWEDITEM';
export const DELETE_BORROWEDITEM = 'DELETE_BORROWEDITEM';
export const UPDATE_BORROWEDITEM = 'UPDATE_BORROWEDITEM';
export const FETCHING_BORROWEDITEMS = 'FETCHING_BORROWEDITEMS';
//LentItem action Types
export const SEARCH_LENTITEMS = 'SEARCH_LENTITEMS';
export const SELECT_LENTITEM = 'SELECT_LENTITEM';
export const DESELECT_LENTITEM = 'DESELECT_LENTITEM';
export const CREATE_LENTITEMS = 'CREATE_LENTITEMS';
export const FETCH_LENTITEMS = 'FETCH_LENTITEMS';
export const FETCH_TODAYLENTITEMS = 'FETCH_TODAYLENTITEMS';
export const FETCH_LENTITEM = 'FETCH_LENTITEM';
export const DELETE_LENTITEM = 'DELETE_LENTITEM';
export const UPDATE_LENTITEM = 'UPDATE_LENTITEM';
export const FETCHING_LENTITEMS = 'FETCHING_LENTITEMS';
//ITEMLOCATIONS action Types
export const SEARCH_ITEMLOCATIONS = 'SEARCH_ITEMLOCATIONS';
export const SELECT_ITEMLOCATION = 'SELECT_ITEMLOCATION';
export const DESELECT_ITEMLOCATION = 'DESELECT_ITEMLOCATION';
export const CREATE_ITEMLOCATIONS = 'CREATE_ITEMLOCATIONS';
export const FETCH_ITEMLOCATIONS = 'FETCH_ITEMLOCATIONS';
export const FETCH_ITEMLOCATION = 'FETCH_ITEMLOCATION';
export const DELETE_ITEMLOCATION = 'DELETE_ITEMLOCATION';
export const UPDATE_ITEMLOCATION = 'UPDATE_ITEMLOCATION';
export const FETCHING_ITEMLOCATIONS = 'FETCHING_ITEMLOCATIONS';
//task action Types
export const SEARCH_TASKS = 'SEARCH_TASKS';
export const SELECT_TASK = 'SELECT_TASK';
export const DESELECT_TASK = 'DESELECT_TASK';
export const CREATE_TASKS = 'CREATE_TASKS';
export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_TODAYTASKS = 'FETCH_TODAYTASKS';
export const FETCH_TASK = 'FETCH_TASK';
export const DELETE_TASK = 'DELETE_TASK';
export const UPDATE_TASK = 'UPDATE_TASK';
export const FETCHING_TASKS = 'FETCHING_TASKS';
//agreement action Types
export const SEARCH_AGREEMENTS = 'SEARCH_AGREEMENTS';
export const DESELECT_AGREEMENT = 'DESELECT_AGREEMENT';
export const CREATE_AGREEMENTS = 'CREATE_AGREEMENTS';
export const FETCH_AGREEMENTS = 'FETCH_AGREEMENTS';
export const FETCH_AGREEMENT = 'FETCH_AGREEMENT';
export const DELETE_AGREEMENT = 'DELETE_AGREEMENT';
export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';
export const FETCHING_AGREEMENTS = 'FETCHING_AGREEMENTS';

//lead action Types
export const SEARCH_LEADS = 'SEARCH_LEADS';
export const SELECT_LEAD = 'SELECT_LEAD';
export const DESELECT_LEAD = 'DESELECT_LEAD';
export const CREATE_LEADS = 'CREATE_LEADS';
export const FETCH_LEADS = 'FETCH_LEADS';
export const FETCH_LEAD = 'FETCH_LEAD';
export const DELETE_LEAD = 'DELETE_LEAD';
export const UPDATE_LEAD = 'UPDATE_LEAD';
export const BULKUPDATE_LEADS = 'BULKUPDATE_LEADS';
export const FETCHING_LEADS = 'FETCHING_LEADS';
//require action Types Sale
export const SEARCH_REQRESISALES = 'SEARCH_REQRESISALES';
export const SELECT_REQRESISALE = 'SELECT_REQRESISALE';
export const DESELECT_REQRESISALE = 'DESELECT_REQRESISALE';
export const CREATE_REQRESISALES = 'CREATE_REQRESISALES';
export const FETCH_REQRESISALES = 'FETCH_REQRESISALES';
export const FETCH_REQRESISALE = 'FETCH_REQRESISALE';
export const DELETE_REQRESISALE = 'DELETE_REQRESISALE';
export const UPDATE_REQRESISALE = 'UPDATE_REQRESISALE';
export const FETCHING_REQRESISALES = 'FETCHING_REQRESISALES';
//require action Types rent
export const SEARCH_REQRESIRENTS = 'SEARCH_REQRESIRENTS';
export const SELECT_REQRESIRENT = 'SELECT_REQRESIRENT';
export const DESELECT_REQRESIRENT = 'DESELECT_REQRESIRENT';
export const CREATE_REQRESIRENTS = 'CREATE_REQRESIRENTS';
export const FETCH_REQRESIRENTS = 'FETCH_REQRESIRENTS';
export const FETCH_REQRESIRENT = 'FETCH_REQRESIRENT';
export const DELETE_REQRESIRENT = 'DELETE_REQRESIRENT';
export const UPDATE_REQRESIRENT = 'UPDATE_REQRESIRENT';
export const FETCHING_REQRESIRENTS = 'FETCHING_REQRESIRENTS';
//require COM action Types Sale
export const SEARCH_REQCOMSALES = 'SEARCH_REQCOMSALES';
export const SELECT_REQCOMSALE = 'SELECT_REQCOMSALE';
export const DESELECT_REQCOMSALE = 'DESELECT_REQCOMSALE';
export const CREATE_REQCOMSALES = 'CREATE_REQCOMSALES';
export const FETCH_REQCOMSALES = 'FETCH_REQCOMSALES';
export const FETCH_REQCOMSALE = 'FETCH_REQCOMSALE';
export const DELETE_REQCOMSALE = 'DELETE_REQCOMSALE';
export const UPDATE_REQCOMSALE = 'UPDATE_REQCOMSALE';
export const FETCHING_REQCOMSALES = 'FETCHING_REQCOMSALES';
//require COM action Types rent
export const SEARCH_REQCOMRENTS = 'SEARCH_REQCOMRENTS';
export const SELECT_REQCOMRENT = 'SELECT_REQCOMRENT';
export const DESELECT_REQCOMRENT = 'DESELECT_REQCOMRENT';
export const CREATE_REQCOMRENTS = 'CREATE_REQCOMRENTS';
export const FETCH_REQCOMRENTS = 'FETCH_REQCOMRENTS';
export const FETCH_REQCOMRENT = 'FETCH_REQCOMRENT';
export const DELETE_REQCOMRENT = 'DELETE_REQCOMRENT';
export const UPDATE_REQCOMRENT = 'UPDATE_REQCOMRENT';
export const FETCHING_REQCOMRENTS = 'FETCHING_REQCOMRENTS';
//propSuggested action Types
export const SEARCH_PROPSUGGESTEDS = 'SEARCH_PROPSUGGESTEDS';
export const SELECT_PROPSUGGESTED = 'SELECT_PROPSUGGESTED';
export const DESELECT_PROPSUGGESTED = 'DESELECT_PROPSUGGESTED';
export const CREATE_PROPSUGGESTEDS = 'CREATE_PROPSUGGESTEDS';
export const FETCH_PROPSUGGESTEDS = 'FETCH_PROPSUGGESTEDS';
export const FETCH_PROPSUGGESTED = 'FETCH_PROPSUGGESTED';
export const DELETE_PROPSUGGESTED = 'DELETE_PROPSUGGESTED';
export const UPDATE_PROPSUGGESTED = 'UPDATE_PROPSUGGESTED';
export const FETCHING_PROPSUGGESTEDS = 'FETCHING_PROPSUGGESTEDS';

export const CREATE_ERROR = 'CREATE_ERROR';
export const CLEAR_ERROR = 'CLEAR_ERROR';

//###################################
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_ERRORS = 'GET_ERRORS';
//##################################,
// user action types
export const SEARCH_USERS = 'SEARCH_USERS';
export const CREATE_USERS = 'CREATE_USERS';
export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USER = 'FETCH_USER';
export const FETCH_USERDETAIL = 'FETCH_USERDETAIL';
export const FETCH_MYDETAIL = 'FETCH_MYDETAIL';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
// EMPLOYEE types
export const CREATE_EMPLOYEES = 'CREATE_EMPLOYEES';
export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
// modal action types
export const MODAL_OPEN = 'MODAL_OPEN';
export const MODAL_CLOSE = 'MODAL_CLOSE';
export const FETCHING = 'FETCHING';
// popup action types
export const POPUP_OPEN = 'POPUP_OPEN'; 
export const POPUP_CLOSE = 'POPUP_CLOSE';

// sidebar action types
export const SHOW_SIDEBAR = 'SHOW_SIDEBAR';
export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';
// Backbutton action types
export const SHOW_BACKBUTTON = 'SHOW_BACKBUTTON';
export const HIDE_BACKBUTTON = 'HIDE_BACKBUTTON';

// reset state action types
export const RESET_STATE_AUTH = 'RESET_STATE_AUTH';
export const RESET_STATE_FINANCE = 'RESET_STATE_FINANCE';
export const RESET_STATE_COMMON = 'RESET_STATE_COMMON';