import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { connect } from 'react-redux';

import { closeModal } from '../../actions/modalAction';
import SingleAccountFinanceSearch from '../finances/SingleAccountFinanceSearch';
//import * as actions from '../../actions/companysAction';
const actions = { closeModal };

class SearchSingleAccountFinanceModal extends Component {
  render() {
    return (
      <Modal size="small" 
      open={true} onClose={this.props.closeModal}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      >
<Modal.Content className='border'>
          <Modal.Description>
            <SingleAccountFinanceSearch 
            renderType={this.props.renderType}
            accountId={this.props.accountId}
            />
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

export default connect(
  null,
  actions
)(SearchSingleAccountFinanceModal);
