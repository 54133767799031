import React, { Component } from "react";
import { connect } from "react-redux";
import {  withRouter } from "react-router-dom";
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { getUserForAuth, } from "../../actions/authAction";
import { openPopup } from "../../actions/popupAction";
//import TextInput from '../form/TextInput';
import CheckboxInput_2 from "../form/CheckboxInput_2"
import {
  Container,
  Form,
  Segment,
  Divider,
  Button,
  Modal,
} from "semantic-ui-react";
import SecurityQAEdit from "./SecurityQAEdit";




class ViewSecurityQA extends Component {
  state = {
    showP:false,
   password:'',
   editModalOpen: false,
  };
  // componentDidMount() {
    
  // }
  toggle = () => this.setState({ showP: !this.state.showP })
  clearPassword=() => {
    this.setState({password:''});
  }
  //**********************************
  handleEditModalOpen = () => this.setState({ editModalOpen:true, })
  handleEditModalClose = () =>{
     this.setState({ editModalOpen:false })
   }  //
  handleEditModalCloseSubmit = () =>{
    this.props.getUserForAuth();
    this.onSubmit()
     this.setState({ editModalOpen:false })
  }

  //**********
  onSubmit = async () => {

    const values = {password:this.state.password}
    
      try {
             
        const res = await axios.post(`/api/SecurityQADetail`, values);
        
          if(res.data.msg ==='success'){
            this.setState({...res.data.securityQA})
          }
          if(res.data.msg ==='errorPass'){
          this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`You Have Submited Wrong Password.Please Try Again.You are left with ${res.data.attemptLeft} more attempts`});
          }

          if(res.data.msg === 'errorAttempt'){
          this.props.openPopup("CopyValuePopup" , {action1:'Ok',color:'red',message:`Your Security Q & A View Access is Locked.Please Contact Support`});
          }


        }catch (error) {
      
       toastr.error("Oops", "Something went wrong.Please Try Again");
      }
  }
  //**********
  
onChange=(e) => {
  this.setState({ [e.target.name]: e.target.value });
}
//^^^^^^^^^^^
  PasswordCheckForm = () => {
    const showPass = this.state.showP ? "text" : "password"
    return (
  <>
  <div className="card-form border">
            <div className=" label" style={{padding:'20px'}} >
            
            <div className="font-20B color-teal">
Enter and Submit Password to View Security Questions / Answers.
</div> <br />
  <Form onSubmit={this.onSubmit}>
  <CheckboxInput_2 
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
<br/>
  <Form.Field >
  <label >Password<span className='color-mandatory'> * </span></label>
        <input
        className="input"
        type={showPass}
          placeholder="Enter Password"
          name="password"
          value={this.state.password}
          onChange={this.onChange}

        />
      </Form.Field>
      <Button 
      //inverted 
       color="blue" type="submit">
         Submit
      </Button>
      <Button
              
              color="orange"
              type="button"
              onClick={this.clearPassword}
            >
              Clear Values
            </Button>
      
  </Form>
      </div>
         </div>
  </>
    )
  }

  // ****

  renderSecurityQA = ()=>{
   
    const {
      Q1,
      Q2,
      Q3,
      A1,
      A2,
      A3,
      
    } = this.state;

    //^^^^^^^^^^^
    const renderEditButton = () =>
    (
<span
    onClick={()=>this.handleEditModalOpen()}
    className="icon-button"
    >
    <img className= 'yimg ' src='/editIcon.png' alt=" " style={{width:"25px", height:"25px",borderRadius:'5px',verticalAlign:'middle'}} />
    </span>
    )
    //^^^^^^^^^^
    return (
      <div>


      {/**/}
      <div className='margin-auto-H' >
      <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
        {/*  */}
        Security Questions & Answers
     {/*  */}
     </div>
     </div>
     {/**/}

        <Segment attached>
        <div>
        <div className="font-20B color-teal">
Detail of Security Questions / Answers, which you will need to answer when resetting your password.
</div> <br />
          <div className="disflex-center">
            {renderEditButton()}
          </div>
         

          <br />
          <Divider fitted />
          <div>
          

          
        <div >
        {Q1 && (
        <div className='row-projName font-16B'>
          <div className='color-teal'>
          <b>Q: {Q1}</b>
          </div>
          <div className=''>
          <b>A: {A1}</b>
          
          </div>

        </div>
        )}
        </div>

        <div >
        {Q1 && (
        <div className='row-projName font-16B'>
          <div className='color-teal'>
          <b>Q: {Q2}</b>
          </div>
          <div className=''>
          <b>A: {A2}</b>
          
          </div>

        </div>
        )}
        </div>

        <div >
        {Q1 && (
        <div className='row-projName font-16B'>
          <div className='color-teal'>
          <b>Q: {Q3}</b>
          </div>
          <div className=''>
          <b>A: {A3}</b>
          
          </div>

        </div>
        )}
        </div>
      



         

          </div>

          </div>
        </Segment>
      </div>
    );
    
  }
  //@@@@
  render() {
 
 return(
<Container text>
{/* Edit Modal*/}

<Modal
  open={this.state.editModalOpen}
  onClose={this.handleEditModalClose}
  basic
  closeOnEscape={false}
  closeOnDimmerClick={false}
  style={{margin:'0',padding:'0'}}
>

  <Modal.Content>
  <SecurityQAEdit
  val={this.state}
  handleEditModalCloseSubmit={this.handleEditModalCloseSubmit}
  handleEditModalClose= {this.handleEditModalClose}
   />

  </Modal.Content>

</Modal>

{((this.props.userA !== null && this.props.userA.SQACreated === 'YES') && !this.state.Q1) && this.PasswordCheckForm ()}

{this.state.Q1 && this.renderSecurityQA ()}
</Container>
 )

    
  }
}

function mapStateToProps(state) {
 
  return { userMy: state.auth.userMy,userA: state.auth.userA };
}

export default connect(
  mapStateToProps,
  {getUserForAuth,openPopup}
)(withRouter(ViewSecurityQA));
