
import React, { Component } from "react";
import { connect } from "react-redux";

import NavbarAuth from "./NavbarAuth";
import NavbarGuest from "./NavbarGuest";


class Navbar extends Component {
  renderNav =()=>{
    const { isAuthenticated,  } = this.props.auth;
    if(!isAuthenticated){
return (<NavbarGuest/>)
    }
    if(isAuthenticated){
      return (<NavbarAuth/>)
          }
  }
  
  render() {
   
    //const { isAuthenticated,  } = this.props.auth;
    
    return (
      <div
      // Disable right click
  onContextMenu={e => e.preventDefault()}
      >
        
        {this.renderNav()}
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => {
  return({
  auth: state.auth,
})
};

export default connect(
  mapStateToProps,
  null
)(Navbar);
