
import axios from "axios";
import { toastr } from "react-redux-toastr";
import { closeModal, openModal } from "./modalAction";

import {
  //finance types
  FETCH_FINANCES,
  FETCH_FINANCE,
  SEARCH_FINANCES,
  DESELECT_FINANCE,
  SELECT_FINANCEALL,
  DESELECT_FINANCEALL,
  SELECT_FINANCE,
  RESET_SELECTION,
  FETCHING_FINANCES,
  // accountName types
  

} from "./types";
//select  finance
export const selectFinance = id => async dispatch => {
  try {

    dispatch({ type: SELECT_FINANCE, payload: id });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//select all finance
export const selectAll = val => async dispatch => {
  try {

    dispatch({ type: SELECT_FINANCEALL, payload: val });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
//select all finance
export const deselectAll = val => async dispatch => {
  try {

    dispatch({ type: DESELECT_FINANCEALL, payload: val });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// deselect Finance

export const deselectFinance = id => async dispatch => {
  try {
    dispatch({ type: DESELECT_FINANCE, payload: id });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// RESET SELECTION
export const resetSelections = () => async dispatch => {
  dispatch({ type: RESET_SELECTION });
};


//search  Finances All

//**************************************************
//search  AllFinance

export const searchAllFinance = (
  values,
  limit,
  offsetAll,
) => async dispatch => {

  try {
    const res = await axios.post("/api/AllFinanceSearch", {
      values,
      limit,
      offsetAll
    });


    dispatch({ type: SEARCH_FINANCES, payload: res.data });
        dispatch({ type:FETCHING_FINANCES, payload: { fetching: false}  });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^


export const fetchSummarySA = (month) => async dispatch => {

  try {
    const res = await axios.post("/api/SummarySA", {month:month});

     dispatch({ type: SEARCH_FINANCES, payload: res.data });

  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//@@@@

//##########
//search  AllFinanceSA

export const searchAllFinanceSA = (
  values,
  limit,
  offsetAllSA,
) => async dispatch => {

  try {
    const res = await axios.post("/api/AllFinanceSASearch", {
      values,
      limit,
      offsetAllSA
    });


    dispatch({ type: SEARCH_FINANCES, payload: res.data });
        //dispatch({ type:FETCHING_FINANCES, payload: { fetching: false}  });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};


//**************************************************
//search  CashBook

export const searchCashBook = (
  values,
  limit,
  offsetC,
) => async dispatch => {

  try {
    const res = await axios.post("/api/CashBookSearch", {
      values,
      limit,
      offsetC
    });


    dispatch({ type: SEARCH_FINANCES, payload: res.data });
        dispatch({ type:FETCHING_FINANCES, payload: { fetching: false}  });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//**************************************************
//search  SaleBook

export const searchSaleBook = (
  values,
  limit,
  offsetSale,
) => async dispatch => {

  try {
    const res = await axios.post("/api/SaleBookSearch", {
      values,
      limit,
      offsetSale
    });


    dispatch({ type: SEARCH_FINANCES, payload: res.data });
        dispatch({ type:FETCHING_FINANCES, payload: { fetching: false}  });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//**************************************************
//search  PurchaseBook

export const searchPurchaseBook = (
  values,
  limit,
  offsetPurchase,
) => async dispatch => {

  try {
    const res = await axios.post("/api/PurchaseBookSearch", {
      values,
      limit,
      offsetPurchase
    });


    dispatch({ type: SEARCH_FINANCES, payload: res.data });
    dispatch({ type:FETCHING_FINANCES, payload: { fetching: false}  });
    dispatch(closeModal());
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//fetch single entry detail
export const fetchFinance = (id,month) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinanceDetail/${id}`,{month});

    dispatch({ type: FETCH_FINANCE, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//fetch single entry detail for edit to accept
export const fetchFinanceForEditToAccept = (id,month) => async dispatch => {
  try {
    const res = await axios.post(`/api/FinanceDetailForEditToAccept/${id}`,{month});

    dispatch({ type: FETCH_FINANCE, payload: res.data });
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};

//setInitialValues
export const setInitialValues = values => async dispatch => {
  try {

    dispatch({ type: FETCH_FINANCE, payload: values });
  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again_fetchCashBook");
  }
};
//create Finance
export const createFinance = (values, history) => async (dispatch) => {
  
  try {
    const res = await axios.post("/api/FinanceCreate", { values });


if((values.entryType=== 'Purchase')){
  history.push("/purchase/PurchaseBook");
}
if((values.entryType=== 'Sale' ) ){
  history.push("/sale/SaleBook");
}
if((values.entryType=== 'Payment')){
history.push("/cashBook/CashBook");
}

    if(res.data.msg ==='success'){
      toastr.success('Created Successfully')
    }
  } catch (error) {
    if(error && error.response && error.response.status==401){
    dispatch(openModal('LoginModal')); 
    toastr.error(`${JSON.stringify(error.response.data) }`);
    }else if(error){
    toastr.error("Oops", "Something went wrong.Please Try Again");
    }
  }
};



//******************** */
//reset Finance
export const resetFinance = () => async dispatch => {
  try {

    dispatch({ type: FETCH_FINANCE, payload: null });

   // dispatch({ type: FETCH_ACCOUNTREF, payload: null });


  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};


export const resetAllFinance = () => async dispatch => {
  try {
dispatch({ type: FETCH_FINANCES, payload: {finances:[],loans:[],
  cashBooks: [],
saleBooks: [],
purchaseBooks: [],
allFinances: [] } });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};

// ****
export const resetLoading = () => async dispatch => {
  try {

dispatch({ 
  type:FETCHING_FINANCES, 
  payload:{
    fetching: true,
    noEntry: false,
  } 
});
dispatch({ 
  type: FETCH_FINANCES, 
  payload: {
      cashBooks: [],
      saleBooks: [],
      purchaseBooks: [],
      allFinances: [] ,
      //allFinancesSA: [],
    } 
  });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
// ****
export const resetNoEntry = () => async dispatch => {
  try {

dispatch({ 
  type:FETCHING_FINANCES, 
  payload:{
    noEntry: false,
  } 
});
dispatch({ 
  type: FETCH_FINANCES, 
  payload: {
      allFinancesSA: [],
    } 
  });

  } catch (error) {
    toastr.error("Oops", "Something went wrong.Please Try Again");
  }
};
