

export const questionsO=[
"What is your oldest cousin's first name?",
"What was your childhood nickname?",
"What is the nickname of your best friend?",
"What is your favorite Sport?",
"Who is your favorite Player?",
"Who is your favorite Actor?",
"Who is your favorite Actress?",
"What is your favorite Book?",
"Who was your favorite childhood cartoon character?",
"Who was your childhood hero?",
"What was your favorite subject in high school?",
"What is the name of your favorite childhood teacher?",
"Which school did you attend for primary education?",
"In which city or town did your mother and father meet?",
"Where were you when you first heard about Mumbai attack on 26/11 ?",
"Which is the country of your ultimate dream vacation?"
]

export const questions = () => {
  return questionsO.map(value => ({
    key: value,
    text: value,
    value: value,style:{fontSize:'18px'}
  }));
};
