import React from 'react';
import AddPdfFirstTimeContra from "../image/AddPdfFirstTimeContra";
import AddImageFirstTimeContra from "../image/AddImageFirstTimeContra";

class AddImagePdfContra extends React.Component {
  state = {
    addImage:true,
    addPdf:false,
  };
//   componentDidMount() {

// }
//*
  showAddImage = ()=> this.setState({addImage:true,addPdf:false})
  showAddPdf = ()=> this.setState({addPdf:true,addImage:false})
//*
  renderAddImage = ()=>{
    if(this.state.addImage){
  return (
    <AddImageFirstTimeContra
    id={this.props.id}
    entryFor={this.props.entryFor}
    entryType={this.props.entryType}
    idOfContraEntry={this.props.idOfContraEntry}
    imagePathContra={this.props.imagePathContra}
    handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
    handleRefresh = {this.props.handleRefresh}
    showAddPdf={this.showAddPdf}
    />
  )
}
}
renderAddPdf = ()=>{
  if(this.state.addPdf){
return (
  <AddPdfFirstTimeContra
  id={this.props.id}
  entryFor={this.props.entryFor}
  entryType={this.props.entryType}
  idOfContraEntry={this.props.idOfContraEntry}
  imagePathContra={this.props.imagePathContra}
  handleAddViewImageModalClose= {this.props.handleAddViewImageModalClose}
  handleRefresh = {this.props.handleRefresh}
  showAddImage={this.showAddImage}
  />
)
}
}
//********************

render() {

  return(
    <div>
    {this.renderAddImage()}
    {this.renderAddPdf()}
    </div>
  )
  }
}

export default AddImagePdfContra;
