import React from 'react';
import {
  Icon,
  Button,
} from 'semantic-ui-react';

var ps = {

  backgroundColor: 'aqua',
  // width: '100px',
  // height: '30px',
  position: 'fixed',
  bottom: '10px',
  right: '10px',
  zIndex:'1',
};



class ScrollTopButton extends React.Component {
  constructor() {
    super();

    this.state = {
        intervalId: 0
    };
  }

  scrollStep = ()=> {
    if (window.pageYOffset !== 0) {
        clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - this.props.scrollStepInPx);
  }

  // scrollToTop =()=> {
  //   let intervalId = setInterval(this.scrollStep.bind(this), this.props.delayInMs);
  //   this.setState({ intervalId: intervalId });
  // }
  scrollToTop =()=> {
    window.scroll(0,0)
  }
  render () {
      return (
        <Button
        circular
          //color="teal"
          icon
          onClick={this.scrollToTop}
          style={ps}
        >
          <Icon name="chevron up" />

        </Button>
      );

   }
}

export default ScrollTopButton;
