import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm, Field } from "redux-form";
import { openModal } from "../../actions/modalAction";
import {createUser} from "../../actions/authAction";
import { withRouter } from "react-router-dom";

import {  Form, Button,  Header,  Popup, } from "semantic-ui-react";
import { projectName } from "./DataProjectName";
import TextInput from "../form/TextInput";
// import TextArea from "../form/TextArea";
 import SelectInput from "../form/SelectInput";
 import CheckboxInput_2 from "../form/CheckboxInput_2"
// import DateInput from "../form/DateInput";
import { userTypeOption,residentTypeOption, locality, city, state } from "./Data";
import {
  composeValidators,
  combineValidators,
  isRequired,
} from 'revalidate';
import {isValidEmail,isValidPhone,isValidPassword} from "../validator/revalidator";
var passwordValidator = require('password-validator');

const validate = combineValidators({
//************user */

userId:composeValidators(
    isRequired('User Mobile as user ID'),
    isValidPhone({})

  )(),
  uemail:composeValidators(
    //isRequired('User Email'),
    isValidEmail({})

  )(),
  password:composeValidators(
    isRequired('Password'),
    isValidPassword({})

  )(),
    uphone2:isValidPhone({}),
    userType:isRequired('Register As') ,
    uname:isRequired("User's Name") ,
    // password:isRequired('Password') ,
    password2:isRequired('Confirm Password') ,
    //*********company */
    uwhatsapp:isValidPhone({}),
    //residentType: isRequired('Resident Type'),
    projectName : isRequired('Society Name'),
    address:isRequired('Address') ,
    locality:isRequired('Locality') ,
    city:isRequired('City/District') ,
    state:isRequired('State') ,
    pincode:isRequired('Pincode') ,
    shopName:isRequired("Shop's Name") ,

});



// Create a schema
var schema = new passwordValidator();

// Add properties to it
schema
.is().min(8)                                    // Minimum length 8
.is().max(16)                                  // Maximum length 16
.has().uppercase()                              // Must have uppercase letters
.has().lowercase()                              // Must have lowercase letters
.has().digits()                               // Must have digits
.has().symbols()                                // Must have symbols
.has().not().spaces()                           // Should not have spaces
.is().not().oneOf(['Passw0rd', 'Password123']); // Blacklist these values

  class UserCreate extends Component {
    state = {
      showP:false,
      modalOpen: false,
      activeSubmit:false,
    };
    handleOpen = () => this.setState({ modalOpen: true })

    handleClose = () => this.setState({ modalOpen: false })
   onSubmit = values => {
    this.props.createUser(values, this.props.history);
    //this.props.history.goBack()
  };
  backButtonHandler =()=>{
    this.props.history.goBack()
  }

  onChange=(e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  toggle = () => this.setState({ showP: !this.state.showP })
  toggleSubmit = () => this.setState({ activeSubmit: !this.state.activeSubmit })
  //****************** */
  handleTermsAndConditionsModal = () => {
    this.props.openModal("TermsAndConditionsModal");
  };
  //*************** */
  render(){
    const showPass = this.state.showP ? "text" : "password"

    const { handleSubmit, pristine, reset, submitting } = this.props;

    const {userType, password,password2} =
      this.props.state.form &&
      this.props.state.form.CreateUserForm !== undefined &&
      this.props.state.form.CreateUserForm.values !== undefined &&
      this.props.state.form.CreateUserForm.values;

      const renderMatchPassword =()=>{
        if(password && password2){

            if(password !== password2){
             return (<div style={{ color:'red'}} >
              <b>Password do not match</b>
             </div>)
            }


        }
      }
//************************* */
// const renderRequire=()=>{
//   if(password){
//     return (<span
//       style={{color:'red'}} >

//       <b>Require</b>
//       </span>)
//   }
// }
const renderPasswordError = () => {
 if(password) return schema.validate(password, { list: true }).map(item => {
  return (
    <span
    style={{color:'red'}}
    key={item}>
    {" "}
    <b>{item} ,</b>
    </span>
  );
  });
};


//************************ */
const renderShopNameField = ()=>{
  if(userType==='Shop Owner'){
    return (
        <>
        <label>Shop's Name</label><span style={{color:'red'}}><b>*</b></span>
        <Field
        name="shopName"
        type="text"
        component={TextInput}
        placeholder="Shop's Name"
        />
        </>
    )
  }
}


//************************ */
const renderAddressField = ()=>{

    return (
     < React.Fragment>

{userType!=='Shop Owner' &&  ( <><label >Complex/ Society Name</label><span className='color-mandatory'> * </span>
<Field
name="projectName"
placeholder="Enter Key word"
type="text"
options={projectName()}
search={true}
component={SelectInput}
/> </>)}

{userType!=='Shop Owner' &&  ( 
<>
<label >Flat/House No.</label><span className='color-mandatory'> * </span>
 
 
 </>)
 
 }
 {userType==='Shop Owner' &&  ( <><label >Address</label><span className='color-mandatory'> * </span>
 </>)}

            {/* <label>Address</label>{' '}<span style={{color:'red'}}><b>*</b></span> */}

            <Field
              name="address"
              type="text"
              component={TextInput}
              placeholder= {userType==='Shop Owner'? "Society/ Complex Name":"Flat/ House Number"}
            />
           
           <label htmlFor="">Locality</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="locality"
              placeholder="Locality"
              type="text"
              options={locality()}
              search={true}
              component={SelectInput}
            />
            <label>City/District</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              search={true}
              name="city"
              type="text"
              component={SelectInput}
              options={city()}
              placeholder="City/District"
            />
            <label>State</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              search={true}
              name="state"
              type="text"
              component={SelectInput}
              options={state()}
              placeholder="State"
            />
            <label>Pincode</label>{' '}<span style={{color:'red'}}><b>*</b></span>

            <Field
              name="pincode"
              type="text"
              component={TextInput}
              placeholder="Pincode"
            />



     </React.Fragment>
    )
  }





      //*************************** */
  return (

    <div style={{marginTop:'50px',padding:'10px'}}>
<div style={{maxWidth:'700px',margin:'auto'}}>

      <br/>

      {/**/}
      <div className='margin-auto-H' >
        <div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
          {/*  */}
          Register
       {/*  */}
       </div>
       </div>
       {/**/}
        <div className="card-form ">
          <div className=" label" >

          <Form style={{ padding: 25 }} onSubmit={handleSubmit(this.onSubmit)}>

          <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          <Button
            color="orange"
            type="button"
            disabled={pristine || submitting}
            onClick={reset}
          >
            Clear Values
          </Button>
          <br /><br />
          <div>(All field marked with <span style={{color:'red'}}><b>*</b></span> are required)
          </div>




          <Header  color="teal" content="User's Details" />


            <label>Register As</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              //search={true}
              name="userType"
              type="text"
              component={SelectInput}
              options={userTypeOption()}
              placeholder="Register As"
            />


            <label>User's Name</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="uname"
              type="text"
              component={TextInput}
              placeholder="First Name Last Name"
            />
            <label>User's Mobile as user ID</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="userId"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>User's Mobile2</label>
            <Field
              name="uphone2"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>User's Whatsapp Id</label>
            <Field
              name="uwhatsapp"
              type="text"
              component={TextInput}
              placeholder="Enter Ten Digits Mobile Number"
            />
            <label>User's Email</label>
            <Field
              name="uemail"
              type="email"
              component={TextInput}
              placeholder="User Email"
            />
            { userType === 'Individual'  &&  (
            <>
            <label>Resident Type</label>{' '}
            <Field
            //search={true}
            name="residentType"
            type="text"
            component={SelectInput}
            options={residentTypeOption()}
            placeholder="Resident Type"
            />
            </>
            )
            }
            {renderShopNameField()}

            {renderAddressField()}

            <label>Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>{' '}
            <Popup trigger={<span>hint?</span>} wide='very'>
            Password Must have Minimum eight and maximum 16 characters, at least one uppercase letter, one lowercase letter, one number and one special character:
            </Popup>{' '} {renderPasswordError()}
            <Field
            //onBlur=
              name="password"
              type={showPass}
              component={TextInput}
              placeholder="e.g. @Ab12345"
            />


            <label>Confirm Password</label>{' '}<span style={{color:'red'}}><b>*</b></span>
            <Field
              name="password2"
              type={showPass}
              //type="password"
              component={TextInput}
              placeholder="Confirm Password"
            />
            
            
              <CheckboxInput_2 
              label='Show Password'
              onChange={this.toggle}
              checked={this.state.showP}
              />
{renderMatchPassword()}



            <br />
            <div style={{marginTop:'15px',marginBottom:'15px'}}>
     <span className="font-18B">By Signup you agree to</span>
     <span
    className='format-term-a'
onClick={this.handleTermsAndConditionsModal}
>
  Terms & Conditions
</span>  
<div className="mt-10">

<CheckboxInput_2 
label='I agree'
onChange={this.toggleSubmit}
checked={this.state.activeSubmit}
/>
</div>

       </div>
             {/*  */}

             <Button
              color="olive"
              type="submit"
              disabled={!this.state.activeSubmit}
            >
              Signup
            </Button>
            <Button
              color="orange"
              type="button"
              disabled={pristine || submitting}
              onClick={reset}
            >
              Clear Values
            </Button>
            <Button
            color="teal"
            type="button"
            onClick={()=>this.backButtonHandler()}

          >
            Cancel
          </Button>
          </Form>

              <br/> <br/> <br/>
            </div>
            </div>
            </div>
            {/* end 1st div */}
            </div>
  );
}
}
const mapStateToProps = state => {
  return {
    state,
  };
};
export default connect(
  mapStateToProps,
  {createUser, openModal}
)(
  reduxForm({
    form: "CreateUserForm",   // a unique identifier for this form
    destroyOnUnmount: false,
     validate
  })(withRouter(UserCreate))
);
