import React, { Component } from "react";
import { connect } from "react-redux";
import { Container  } from "semantic-ui-react";
import { Switch, Route,Link  } from "react-router-dom";

import { showBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
import ScrollTopButton from "../scroll/ScrollTopButton";
import ScrollBottomButton from "../scroll/ScrollBottomButton";

import UserSearchList from "./UserSearchList";

import MyGroup from "./MyGroup";
import AddImage from "./AddImage";
import MyDetail from "./MyDetail";

import UserEdit from "./UserEdit";


import Layout from "../layout/Layout";



  class CompanyDashboard extends Component {
    state = {
      openPop: false,
    };
    componentDidMount() {
      if(this.props.history.location.pathname !=='/'){
        this.props.showBackButton()
      }
    }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  //****************************** */
  handleOpenPop = () => {this.setState({ openPop: true }); window.scroll(0,0)}
    handleClosePop = () => this.setState({ openPop: false })
    //********************** */
  render(){
    const { isAuthenticated,  } = this.props.auth;
    const renderSearchUserLink = ()=>{
      if(this.props.auth.userA !== undefined && this.props.auth.userA !== null && this.props.auth.userA.userType === 'Shop Owner'){
      return(
          
           <div className="btnnav" >
           <Link  to="/user/UserSearchList" >
       Search Buyer
     </Link>
           </div>
           
      )
      }
    }
    const authLinks = (
      <div>

                <div>
                <div className='maindiv'>



      <Layout onLogoutClick={this.onLogoutClick}>
      <Container text>
        <div className='margin-auto' >
          <div   style={{fontSize:'1.2rem',fontWeight:'bold',padding:'5px'}}  >

          {/*  */}
       <div className='display-center'>
       {renderSearchUserLink()}
       <div className="btnnav" >
       <Link to="/user/MyGroup" >
       My Group
     </Link>
       </div>
       {/*<div className="btnnav" >
       <Link to="/user/MyDetail" >
       My Detail
     </Link>
       </div>*/}


     </div>
       {/*  */}


       </div>
       </div>
       </Container>
       {/*         */}

          <Switch>
          <Route exact path="/user/addimage" component={AddImage} />
          <Route exact path="/user/MyGroup" component={MyGroup} />

          <Route exact path="/user/UserSearchList" component={UserSearchList} />
          <Route exact path="/user/MyDetail" component={MyDetail} />
          
          <Route exact path="/user/UserEdit/:id" component={UserEdit} />

          
          </Switch>
          </Layout >
          </div>
          
  <div >
  <ScrollBottomButton scrollStepInPx="11550" delayInMs="16.66"/>

  <ScrollTopButton scrollStepInPx="11000" delayInMs="16.66"/>
  </div>

  </div>
      </div>
    );

  return (
    <div className="mt-10">
      {isAuthenticated ? (
        authLinks
      ) : (
        <div></div>
      )}
    </div>
  );
}
}
const mapStateToProps = state => ({
  userA: state.auth.userA,
auth: state.auth
});

export default connect(
mapStateToProps,
  {showBackButton, getUserForAuth, logoutUser }
)(CompanyDashboard);
