import React, {Component} from 'react';

import { connect } from "react-redux";

import './css/border.css';
import './css/button.css';
import './css/card.css';
import './css/ribbon.css';
import './css/ribbon2.css';
import './css/common.css';
import './css/color.css';
import './css/container.css';
import './css/display.css';
import './css/grid.css';
import './css/home.css';
import './css/mis.css';
import './css/nav.css';
import './css/sidebar.css';
import './css/checkbox.css';
//import './css/gradientButton.css';
import './menu2/menu.css';

import './styleK.css';

 import HomeAuth from "./HomeAuth";
 import HomeGuest from "./HomeGuest";

class Home extends Component {
  
//*************** */
         
render() {
 

  const { isAuthenticated,  } = this.props.auth;
  
const renderRoute =()=>{
  if(isAuthenticated ){
    return <HomeAuth/>
  }
  if(!isAuthenticated){
    return <HomeGuest/>
  }
}
  return(
    <div
    // Disable right click
  onContextMenu={e => e.preventDefault()}
    >
      {renderRoute()}
    </div>
  );
  
}
}
const mapStateToProps = state => {

return({
  auth: state.auth,
});
}

export default connect(
  mapStateToProps,
  null
)(Home);
