import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import {
  Image,
  //Segment,
  Header,
  Button,
  Icon
} from 'semantic-ui-react';
import Resizer from 'react-image-file-resizer';
import { toastr } from "react-redux-toastr";

import { fetchAppParameter } from "../../actions/commonAction";

 
class AddImage extends React.Component {
  state = {
    files: [],
    imageName: '',
    cropResult: null,
    image: {},
    imageURL: '',
  };
  componentDidMount() {
    if (this.props.parameter === null){
      this.props.fetchAppParameter()
        }
  }

  fileChangedHandler=(event)=> {
        var fileInput = false
        this.setState({files:event.target.files})
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
          Resizer.imageFileResizer(
                event.target.files[0],
                768,
                1280,
                'JPEG',
                90,
                0,
                uri => {

                    let imageUrl = URL.createObjectURL(uri);
                    this.setState({
                      cropResult: imageUrl,
                      image: uri
                    });

                },
                'blob'
            );
        }
    }

    // ****************

  cancelImage = () => {
    this.setState({
      files: [],
      image: {},
      cropResult: null,
    });
  };



  handleUploadImage = async ev => {
    ev.preventDefault();
    const imageURL=this.props.parameter !== null && this.props.parameter.currentURL
    const data = new FormData();
    data.append('file', this.state.image);
    data.append('imageName', Date.now());
    data.append('imageURL', imageURL);
     

     try {                             
      const resImage = await axios.post(`${imageURL}/api/ImageAdd`, data);
if(resImage.data.msg ==='success'){
  const values = {
    id:this.props.id,
    moduleName:this.props.moduleName,
    imageAdded:true,
    path:resImage.data.path
  }
  
  const res= await axios.put(`/api/EditForImage`, values);
  if(res.data.msg ==='success'){
    this.props.handleRefresh()
    this.props.handleAddImageModalClose()
    toastr.success('Image Added Successfully')
  }
}

    } catch (error) {

      toastr.error("Oops", "Something went wrong.Please Try Again_addimage");
    }
  

  };

  render() {

const renderPreview =()=>{
  if(this.state.files.length >0){
    return(
      <React.Fragment>
      <div
      //style={{padding:'15px'}}
      >
      <br/>
      <Header  color="teal" content="Preview and Upload" />
        <br/>
        <Image
        src={this.state.cropResult}/>

        </div>


        <br /><br />
  <div className='disflex-paginator'>
        <Button.Group>
          <Button
            //loading={loading}
            onClick={this.handleUploadImage}
            // style={{ width: '120px' }}
            positive
            icon="check"
            content="upload"
          />
          <Button
            size='mini'
            onClick={this.cancelImage}
            // style={{ width: '100px' }}
            icon="close"
            content="cancell"
          />
        </Button.Group>

    </div>
    </React.Fragment>
    )
  }
}
    return (
      <div className='main-div' style={{backgroundColor:'white'}}>
              <div style={{padding:'15px'}}>
{/**/}

{/**/}
<div className='margin-auto-H smalldiv border-radius-3'  >
<div   style={{fontSize:'1.3rem',fontWeight:'bold',padding:'5px'}}  >
{/*  */}
Add Image
{/*  */}
</div>
</div>
{/**/}

  <br/>
{/**/}
<div className='disflex-paginator'>
              <div>
  <label size="tiny" for="hidden-new-file" class="ui icon button">

    <span className="icon-size">
    <Icon  name="plus circle"/>
</span><span className="icon-size">Add Image</span>

  </label>
  <input
  type="file" id="hidden-new-file" accept="image/*"
  style={{display: 'none'}}
  onChange={this.fileChangedHandler}
  />
</div>
<Button
color="teal"
//size="mini"
type="button"
onClick={this.props.handleAddImageModalClose}
>
Close
</Button>
</div>
{/**/}

{renderPreview()}
              {/*<div
              //style={{padding:'15px'}}
              >
              <br/>
              <Header  color="teal" content="Preview and Upload" />
                <br/>
                <Image
                src={this.state.cropResult}/>

                </div>


                <br /><br />
          <div className='disflex-paginator'>
                <Button.Group>
                  <Button
                    //loading={loading}
                    onClick={this.handleUploadImage}
                    // style={{ width: '120px' }}
                    positive
                    icon="check"
                    content="upload"
                  />
                  <Button
                    size='mini'
                    onClick={this.cancelImage}
                    // style={{ width: '100px' }}
                    icon="close"
                    content="cancell"
                  />
                </Button.Group>

            </div>*/}


</div>
      </div>
    );
  }
}


const mapStateToProps = (state) => {

  return {
    parameter:state.commons.parameter,
  };
};

export default connect(
  mapStateToProps,
{  fetchAppParameter}
)(AddImage);
