import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import {handleSetRemoveAuth} from "./functions/Functions"

import AuthDashboard from "./auth/AuthDashboard";
import ReqForSQA from "../components/auth/ReqForSQA";
import PassReset from "../components/auth/PassReset";
//*********************

import Navbar from "./layout/Navbar";

import Landing from "../components/landing/Home";
import About from "../components/landing/About";
//modal imports
import ModalManager from "./modals/ModalManager";
//popup imports
import PopupManager from "./popup/PopupManager";


import ImageDashboard from "./image/ImageDashboard";

import OptionDashboard from "./options/OptionDashboard";

//users imports
import UserDashboard from "./companys/UserDashboard";
import UserCreate from "./companys/UserCreate";
//
import CashBookCashDashboard from "./finances/CashBookCashDashboard";
//import CashBookBankDashboard from "./finances/CashBookBankDashboard";

import FinanceDashboard from "./finances/FinanceDashboard";

import SaleBookDashboard from "./finances/SaleBookDashboard";
import PurchaseBookDashboard from "./finances/PurchaseBookDashboard";
import AllFinanceDashboard from "./finances/AllFinanceDashboard";


// ******************************************************
handleSetRemoveAuth() 
//***********************************************************


class App extends Component {



  render() {


const commonModuleRouteHandler = ()=>{

    return ( <Switch>
      <Route exact path="/" component={Landing} />
      <Route exact path="/about" component={About} />

      <Route exact path="/register" component={UserCreate} />
      <Route exact path="/formpassreset" component={ReqForSQA} />
      <Route exact path="/passreset" component={PassReset} />

      <Route  path="/auth" component={AuthDashboard} />

    </Switch>
  )

}
const commonAuthModuleRouteHandler = ()=>{
  if (this.props.auth.isAuthenticated === true ){
    return ( <Switch>
      <Route exact path="/formpassreset" component={ReqForSQA} />
      <Route exact path="/passreset" component={PassReset} />
      <Route  path="/image" component={ImageDashboard} />
      <Route path="/option" component={OptionDashboard} />
      <Route path="/user" component={UserDashboard} />

    </Switch>
  )
  }
}


const cashbookModuleRouteHandler = ()=>{
  return ( <Switch>
    <Route path="/cashBook" component={CashBookCashDashboard} />
    {/*<Route path="/cashBookB" component={CashBookBankDashboard} />*/}
    </Switch>
        )
}
const salebookModuleRouteHandler = ()=>{
  return ( <Switch>
    <Route path="/sale" component={SaleBookDashboard} />

    </Switch>
        )
}
const purchasebookModuleRouteHandler = ()=>{
  return ( <Switch>
    <Route path="/purchase" component={PurchaseBookDashboard} />

    </Switch>
        )
}
const allFinanceModuleRouteHandler = ()=>{
  return ( <Switch>
    <Route path="/allfin" component={AllFinanceDashboard} />

    </Switch>
        )
}

const financeModuleRouteHandler = ()=>{
  return ( <Switch>
    <Route path="/finance" component={FinanceDashboard} />
    </Switch>
        )
}



    return (
      <div>
        <BrowserRouter>
          <div>
            <ModalManager />
            <PopupManager />
            <div>
              <Navbar />

              {commonModuleRouteHandler()}
              {commonAuthModuleRouteHandler()}

              {cashbookModuleRouteHandler()}
              {salebookModuleRouteHandler()}
              {purchasebookModuleRouteHandler()}
              {allFinanceModuleRouteHandler()}
              {financeModuleRouteHandler()}
            
            </div>

          </div>
        </BrowserRouter>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.auth,
});
// export default App;

export default connect(
  mapStateToProps,
 null
)(App);
